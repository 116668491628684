import { useEffect, useState } from "react";

import moment from "moment";
import { useLocation } from "react-router-dom";

import useSearchParams from "hooks/useSearchParams";

const MODE_YEAR_MONTH_DATE_PATH =
  /^(week|month|year)\/(\d{4})\/(\d{2})\/(\d{2})$/;
const YEAR_MONTH_DATE_PATH = /^(\d{4})\/(\d{2})\/(\d{2})$/;

type TDateRange = "week" | "month" | "year";
export type CalendarRange = {
  mode: TDateRange;
  date: string;
};

export const useCalendarRange = (): CalendarRange => {
  const [calendarRange, setCalendarRange] = useState<CalendarRange>({
    mode: "week",
    date: moment().toString(),
  });

  const location = useLocation();
  const search = useSearchParams();
  const searchObject = search.reduce((acc, obj) => {
    return { ...acc, ...obj };
  }, {});

  const urlDate: string | undefined = searchObject["calendar"];

  useEffect(() => {
    setCalendarRange(getCalendarRangeFromPath(urlDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return calendarRange;
};

export function getCalendarRangeFromPath(
  urlDate: string | undefined,
  mode: TDateRange = "week"
) {
  let date = moment().toString();

  if (urlDate) {
    const modeYearMonthDateMatch = MODE_YEAR_MONTH_DATE_PATH.exec(urlDate);
    const yearMonthDateMatch = YEAR_MONTH_DATE_PATH.exec(urlDate);
    if (modeYearMonthDateMatch) {
      const [, matchedMode, year, month, day] = modeYearMonthDateMatch;
      mode = matchedMode as TDateRange;
      date = moment(`${year}/${month}/${day}`).toString();
    }
    if (yearMonthDateMatch) {
      const [, year, month, day] = yearMonthDateMatch;
      date = moment(`${year}/${month}/${day}`).toString();
    }
  }

  return { mode, date };
}
export function getCalendarPathFromRange(
  range: CalendarRange,
  format: "YYYY/MM/DD" | "MODE/YYYY/MM/DD"
) {
  const { mode, date } = range;
  const path = format === "MODE/YYYY/MM/DD" ? `${mode}/` : "";
  return `${path}${moment(date).format("YYYY/MM/DD")}`;
}
