import React, { useState } from "react";

import { Box, makeStyles, MenuItem, MenuList } from "@material-ui/core";
import _ from "lodash";

import InputSearch from "components/Input/InputSearch";
import { IOption } from "model/application/components";

const styles = {
  SearchDropdown: {
    padding: "16px",
  },
  SearchDropdownItems: {
    margin: "0 -16px",
    maxHeight: "264px",
    minWidth: "10rem",
    overflow: "auto",
  },
};

const useStyles = makeStyles(styles as any);

export interface ISearchDropdownProps {
  options?: IOption[];
  onSelectOption: (e: any, option: IOption) => any;
}

export const SearchDropdown: React.FunctionComponent<ISearchDropdownProps> = ({
  options,
  onSelectOption,
}: ISearchDropdownProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = _.filter(options, (o) => {
    return o.label?.toLowerCase().search(searchValue.toLowerCase()) !== -1;
  });

  return (
    <div className={classes.SearchDropdown}>
      <InputSearch onChange={(value) => setSearchValue(value)} />

      <Box marginBottom="8px" />

      <MenuList className={classes.SearchDropdownItems}>
        {_.map(filteredOptions, (option) => {
          return (
            <MenuItem
              key={option.key}
              onClick={(e: any) => onSelectOption(e, option)}
              style={{ fontSize: 16 }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </div>
  );
};

export default SearchDropdown;
