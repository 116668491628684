import _ from "lodash";

import {
  ICreateActivityReportActionFunc,
  ICreateWorkflowReportActionFunc,
  IUpdateWorkflowReportActionFunc,
  IUploadActivityReportFileActionFunc,
} from "containers/workflows/redux/actions";
import {
  buildReportBody,
  buildWorkflowReportBody,
  formatUploadFields,
} from "containers/workflows/utils";
import { IFileInputData } from "hooks/useFormState";
import { ISignedInUser } from "model/entities/User";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
  IWorkflowReportWithStepOverview,
  STEP_STATUS,
} from "model/entities/Workflow";
import { getNextStepId } from "utils/resolveChaining/nextStep";

import { CUSTOMER_FIELD_KEY } from "../../reports/utils";
import { uploadFormFiles } from "./handleEditActivityReport";

interface IHandleCreateActivityReport {
  selectedWorkflow: IWorkflow;
  selectedActivity: IActivity;
  selectedWorkflowReport?: IWorkflowReportWithStepOverview;
  updateWorkflowReport: IUpdateWorkflowReportActionFunc;
  createWorkflowReport: ICreateWorkflowReportActionFunc;
  createActivityReport: ICreateActivityReportActionFunc;
  uploadActivityReportFile: IUploadActivityReportFileActionFunc;
  signedInUser: ISignedInUser;
  activities: IActivity[];
  // TODO: we should only need one of the 2 "reports" between the reportInTable and mobileReport.
  // Also, we should use the same report format in payload for Create and Edit
  // (And ideally, Fetch ! i.e the "augmented report" format everywhere ?)
  reportInTable: any;
  mobileReport: IActivityReportInTable;
  stepId?: string;
  filesToUpload?: IFileInputData[];
}

export const handleConfirmCreateActivityReport = async ({
  updateWorkflowReport,
  createWorkflowReport,
  createActivityReport,
  uploadActivityReportFile,
  signedInUser,
  activities,
  selectedActivity,
  selectedWorkflow,
  selectedWorkflowReport,
  reportInTable,
  mobileReport,
  stepId,
  filesToUpload,
}: IHandleCreateActivityReport) => {
  const scope_result = reportInTable["_owners"];
  const customer_id = reportInTable[CUSTOMER_FIELD_KEY];

  // change the status of the step linked to the activity report to "DONE"
  if (selectedWorkflowReport?.step_overview) {
    selectedWorkflowReport.step_overview =
      selectedWorkflowReport.step_overview.map((so) => {
        if (so.step_id === stepId) {
          so.step_status = STEP_STATUS.DONE;
        }
        return so;
      });
  }

  const nextStepId = getNextStepId(
    selectedWorkflowReport,
    selectedWorkflow as IWorkflow
  );

  const wfReport = await buildWorkflowReportBody(
    selectedWorkflow as IWorkflow,
    selectedWorkflowReport,
    selectedActivity,
    reportInTable,
    scope_result ?? [],
    activities,
    signedInUser
  );

  const data: any = await (selectedWorkflowReport
    ? updateWorkflowReport(wfReport)
    : createWorkflowReport(wfReport));

  const stepIdOrNextStepId = stepId ?? nextStepId;
  if (!stepIdOrNextStepId) {
    console.error(`Invalid stepId: "${stepId}"; nextStepId: "${nextStepId}"`);
    return;
  }

  const activityReportBody = buildReportBody(
    selectedActivity,
    selectedWorkflow,
    data.workflow_report,
    stepIdOrNextStepId
  );

  if (customer_id) {
    // NOTE: for the POST the fields don't contain the _ prefix (it's customer_id instead of _customer_id)
    activityReportBody["customer_id"] = customer_id;
  }

  // UPLOAD FILES
  const fileData = await uploadFormFiles({
    filesToUpload: filesToUpload ?? [],
    workflow: selectedWorkflow,
    workflowReport: data.workflow_report,
    activity: selectedActivity,
    activityReportId: activityReportBody.id,
    uploadFile: uploadActivityReportFile,
  });

  const custom_field_values = _(reportInTable)
    .keys()
    .reject("_id")
    // Remove fields with empty values (at creation it is OK)
    .filter((key) => mobileReport[key])
    .map((key) => ({
      key,
      // This is the formatted value
      value: mobileReport[key],
    }))
    .value();

  if (fileData) {
    try {
      formatUploadFields(fileData, custom_field_values);
      await createActivityReport({
        ...activityReportBody,
        custom_field_values,
      });
    } catch (e) {
      // don't block the execution
      console.error(e);
    }
  } else {
    await createActivityReport({
      ...activityReportBody,
      custom_field_values,
    });
  }
};
