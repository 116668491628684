import _ from "lodash";

import ITeam from "model/entities/Team";

interface IPrepareHierarchyQuery {
  query: Record<string, any>;
  teams: ITeam[];
  teamQueryTag?: string;
  maxHierarchyLevels?: number;
}

/**
 * This function is used to replace hierarchy levels with the ids of the teams that are in the hierarchy level.
 * @param query the query object
 * @param teams all the teams of the client
 * @param teamQueryTag the tag of the query that will receive the team ids
 * @returns
 */
export function prepareHierarchyQuery({
  query,
  teams,
  teamQueryTag = "teams",
  maxHierarchyLevels = 10,
}: IPrepareHierarchyQuery) {
  const queryCopy = _.clone(query);
  const levels = _.times(maxHierarchyLevels, (num) => `level_${num}`);

  const teamIds = _.flatMap(levels, (level) => {
    const levelValues = queryCopy[level];
    const result = _.flatMap(teams, (team) => {
      if (_.includes(levelValues, team[level])) {
        return team.id;
      }
    });
    delete queryCopy[level];
    return _.compact(result);
  });

  const originalQueryTeamsQueryValue = queryCopy[teamQueryTag] || [];
  const allTeamsJoined = _.uniq(
    _.compact(_.concat(originalQueryTeamsQueryValue, teamIds))
  );

  if (!_.isEmpty(allTeamsJoined)) {
    queryCopy[teamQueryTag] = allTeamsJoined;
  }

  return queryCopy;
}
