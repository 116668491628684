import { IVisitEvent } from "fieldpro-tools";
import moment from "moment";

interface IEventIsWithinAllowedHours {
  event: IVisitEvent;
  minEventHour: number;
  maxEventHour: number;
}
export function eventIsWithinAllowedHours({
  event,
  maxEventHour,
  minEventHour,
}: IEventIsWithinAllowedHours) {
  if (!event.start_time || !event.end_time) return false;
  const start_time = moment(event.start_time);
  const end_time = moment(event.end_time);
  const timeSlotsStartDate = moment(start_time).set({
    hour: minEventHour,
    minute: 0,
  });
  const timeSlotsEndDate = moment(end_time).set({
    hour: Math.min(maxEventHour > 23 ? 23 : maxEventHour + 1, 23),
    minute: 0,
  });

  return (
    start_time.isBetween(
      timeSlotsStartDate,
      timeSlotsEndDate,
      "minute",
      "[]"
    ) &&
    end_time.isBetween(timeSlotsStartDate, timeSlotsEndDate, "minute", "[]")
  );
}
