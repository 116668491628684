import _ from "lodash";

import { CUSTOM_FIELD_TYPE, IList } from "model/entities/List";

interface IPrepareListQueryForBackend {
  list: IList;
  query: Object;
}
export function prepareListQueryForBackend({
  list,
  query,
}: IPrepareListQueryForBackend) {
  const preparedQuery: typeof query = {};
  const scolTags = _.filter(list?.schema, (si) =>
    [
      CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
      CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST,
    ].includes(si.type)
  ).map((si) => si.column_tag);
  _.map(scolTags, (tag) => {
    if (query[tag]) {
      preparedQuery[tag] = flattenChoiceFilterValues(query[tag]);
    }
  });
  return {
    ...query,
    ...preparedQuery,
  };
}

function flattenChoiceFilterValues(values: any) {
  if (_.isArray(values)) {
    return _.compact(
      _.map(values, (value: Object | string) => {
        if (_.isObject(value) && value.hasOwnProperty("key")) {
          return value["key"] as string;
        }
        return value;
      })
    );
  }
  return values;
}
