import { Box } from "@material-ui/core";

import { GreyDark } from "assets/colors";

// a simple component to display an icon, title and description in a 1x2 grid layout
// +-------------------+-------------------+
// |                   |      title        |
// |       icon        +-------------------+
// |                   |   description     |
// +-------------------+-------------------+

interface IIconTitleDescriptionBox {
  icon?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  titleProps?: React.ComponentProps<typeof Box>;
  descriptionProps?: React.ComponentProps<typeof Box>;
  containerProps?: React.ComponentProps<typeof Box>;
  iconContainerProps?: React.ComponentProps<typeof Box>;
}
export function IconTitleDescriptionBox({
  icon,
  title,
  description,
  titleProps,
  descriptionProps,
  containerProps,
  iconContainerProps,
}: IIconTitleDescriptionBox) {
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"auto 1fr"}
      gridColumnGap={"8px"}
      color={GreyDark}
      {...(containerProps || {})}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        {...(iconContainerProps || {})}
      >
        {icon}
      </Box>
      <Box display={"grid"} gridTemplateRows={"auto 1fr"} alignItems={"start"}>
        <Box>
          <Box fontSize={"16px"} fontWeight={"bold"} {...(titleProps || {})}>
            {title}
          </Box>
        </Box>
        <Box>
          <Box
            fontSize={"16px"}
            width={"250px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            {...(descriptionProps || {})}
          >
            {description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
