import { Black, White } from "assets/colors";
import { IStyles } from "model/application/styles";

export const styles: IStyles = {
  root: {
    display: "grid",
    gridRowGap: "16px",
  },
  header: {
    display: "grid",
    gridAutoFlow: "column",
    alignItems: "center",
    gridTemplateColumns: "2fr 1fr",
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    color: Black,
  },
  icon: {
    paddingRight: "8px",
  },
  addVisitDialogWrapper: {
    background: White,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    right: 0,
    zIndex: 6,
    overflowY: "scroll",
  },
};
