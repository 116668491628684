import moment from "moment";

import { IClient } from "model/entities/Client";

const EVENT_CALENDAR_START_HOUR = 6;
const EVENT_CALENDAR_END_HOUR = 18;
export function getTimesOfDay(client: IClient) {
  const { minEventStartHour, maxEventStartHour } =
    getCalendarWorkingHours(client);
  const times = [];
  const start = moment.utc().startOf("day").hour(minEventStartHour);

  do {
    times.push(start.format("hA"));
    start.add(1, "hour");
  } while (
    start.isSameOrBefore(moment.utc().endOf("day").hour(maxEventStartHour))
  );

  return times;
}

export function getCalendarWorkingHours(client: IClient) {
  const { time_slots_start, time_slots_stop } = client || {};
  return {
    minEventStartHour: time_slots_start
      ? moment(time_slots_start).hour()
      : EVENT_CALENDAR_START_HOUR,
    maxEventStartHour: time_slots_stop
      ? moment(time_slots_stop).hour()
      : EVENT_CALENDAR_END_HOUR,
  };
}
