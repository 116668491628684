export const getAbsoluteDateFromDate = (date: Date) => {
  return [
    {
      period: "YEAR",
      value: date.getFullYear(),
    },
    {
      period: "MONTH",
      value: date.getMonth(),
    },
    {
      period: "DAY",
      value: date.getDay(),
    },
    {
      period: "HOUR",
      value: date.getHours(),
    },
  ];
};

export const computeTerminaisonTime = (
  now: Date,
  absolute: Array<{ value: number; period: string }>,
  relative: Array<{ value: number; period: string }>
): string => {
  relative.forEach((e) => {
    switch (e.period) {
      case "MINUTE": {
        now.setTime(now.getTime() + 1000 * 60 * e.value);
        break;
      }
      case "HOUR": {
        now.setTime(now.getTime() + 1000 * 60 * 60 * e.value);
        break;
      }
      case "DAY": {
        now.setTime(now.getTime() + 1000 * 60 * 60 * 24 * e.value);
        break;
      }
      case "MONTH": {
        let newMonth = now.getMonth() + e.value;
        while (newMonth > 11) {
          now.setFullYear(now.getFullYear() + 1);
          newMonth -= 11;
        }
        now.setMonth(newMonth);
        break;
      }
      case "YEAR": {
        now.setFullYear(now.getFullYear() + e.value);
        break;
      }
      case "WEEK": {
        now.setTime(now.getTime() + e.value * 7 * 24 * 60 * 60 * 1000);
        break;
      }
    }
  });
  absolute.forEach((e) => {
    switch (e.period) {
      case "MINUTE": {
        now.setMinutes(e.value);
        break;
      }
      case "HOUR": {
        now.setHours(e.value);
        break;
      }
      case "DAY_OF_MONTH": {
        now.setDate(e.value);
        break;
      }
      case "MONTH": {
        now.setMonth(e.value - 1);
        break;
      }
      case "YEAR": {
        now.setFullYear(e.value);
        break;
      }
      case "DAY_OF_WEEK": {
        const weekDay = now.getUTCDay();
        const daysUntilTarget = (e.value + 7 - weekDay) % 7; // Calculate days until the next occurrence of targetWeekDay
        now.setTime(now.getTime() + daysUntilTarget * 24 * 60 * 60 * 1000);
        break;
      }
      case "WEEK": {
        const weekNb = getWeekNb(now);
        now.setTime(
          now.getTime() + (e.value - weekNb) * 7 * 24 * 60 * 60 * 1000
        );
        break;
      }
    }
  });
  const transposedDate = now;
  const localDate = transposedDate.toLocaleString();
  return localDate;
};

export const getWeekNb = (date: Date) => {
  //find the year of the entered date
  const oneJan = new Date(date.getFullYear(), 0, 1);
  // calculating number of days in given year before the given date
  const numberOfDays = Math.floor(
    (date.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)
  );
  // adding 1 since to current date and returns value starting from 0
  const result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
  return result;
};
