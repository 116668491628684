import moment from "moment";

export type DateDirection = "forward" | "backward";

function dateStepper(
  startingDate: Date,
  numDays: number,
  direction: DateDirection
): string[] {
  const currentDate = moment.utc(startingDate);

  const resultDates: string[] = [];

  for (let i = 0; i < numDays; i++) {
    const nextDate =
      direction === "forward"
        ? currentDate.clone().add(i, "days")
        : currentDate.clone().subtract(i, "days");
    resultDates.push(nextDate.format("YYYY-MM-DD"));
  }

  return resultDates;
}

export default dateStepper;

export function generateWorkingDates(
  startingDate: Date,
  numDays: number = 7
): string[] {
  const currentDate = moment.utc(startingDate);
  const resultDates: string[] = [];

  // Find the previous Monday
  const previousMonday = currentDate.clone().startOf("isoWeek");

  // Add dates from Monday to Sunday
  for (let i = 0; i < numDays; i++) {
    const nextDate = previousMonday.clone().add(i, "days");
    resultDates.push(nextDate.format("YYYY-MM-DD"));
  }

  return resultDates;
}
