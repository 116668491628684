import App from "./App";

const env = process.env.REACT_APP_ENV;

if (!["local", "develop", "staging", "production", "preprod"].includes(env)) {
  alert(
    `No env found. Did you setup the .env file correctly? (REACT_APP_ENV = ${env})`
  );
}

const Root = () => {
  return <App />;
};

export default Root;
