import { useEffect } from "react";

import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import { fetchMobileUsersAction } from "containers/users/redux/actions/mobileUserActions";
import { getIsFetchingAllMobileUsers } from "containers/users/redux/selectors/mobileUsersSelector";
import { useActions, useTranslations } from "hooks";

import CalendarErrorBoundary from "./CalendarErrorBoundary";
import {
  getIsCreatingEvent,
  getIsDeletingEvent,
  getIsFetchingEvents,
  getIsUpdatingEvent,
} from "./redux/selectors";

function SubcategoryManager({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const [fetchMobileUsers] = useActions([fetchMobileUsersAction]);
  const lang = useTranslations();
  const isFetchingEvents = useSelector(getIsFetchingEvents);
  const isUpdatingEvents = useSelector(getIsUpdatingEvent);
  const isDeletingEvents = useSelector(getIsDeletingEvent);
  const isCreatingEvents = useSelector(getIsCreatingEvent);
  const isFetchingMobileUsers = useSelector(getIsFetchingAllMobileUsers);

  useEffect(() => {
    //dont await, use selectors to track this
    fetchMobileUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSnackbar =
    isUpdatingEvents ||
    isFetchingEvents ||
    isFetchingMobileUsers ||
    isDeletingEvents ||
    isCreatingEvents;
  return (
    <CalendarErrorBoundary>
      <Box>
        {children}
        <SnackbarMessage
          key={String(showSnackbar)}
          isFetchActionOngoing={showSnackbar}
          lang={lang}
        />
      </Box>
    </CalendarErrorBoundary>
  );
}

export default SubcategoryManager;
