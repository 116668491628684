import { IVisitEvent, TCalendarEvent } from "fieldpro-tools";

import BoardItem from "../components/Calendar/BoardItem";

interface GetEventPlaceHoldersParams {
  newEventObject: Partial<IVisitEvent> | undefined;
  dialogViewMode?: string;
  allowCreation?: boolean;
  openDialog?: boolean;
}

export function getEventPlaceHolders({
  newEventObject,
  dialogViewMode = "",
  allowCreation = false,
  openDialog = false,
}: GetEventPlaceHoldersParams) {
  const { start_time, end_time, repetition } = newEventObject || {};
  if (dialogViewMode === "EDIT") return [];
  if (allowCreation && openDialog && start_time && end_time) {
    return [
      {
        start_time,
        end_time,
        repetition,
        component: (
          <BoardItem
            event={newEventObject as TCalendarEvent}
            onClickDelete={() => {}}
            onEditEvent={() => {}}
            boxProps={{
              style: {
                opacity: "0.7",
              },
            }}
          />
        ),
      },
    ];
  }
  return [];
}
