import { useContext, useEffect, useRef, useState } from "react";

import { Box, CircularProgress } from "@material-ui/core";
import DeselectIcon from "@mui/icons-material/Deselect";
import PageviewIcon from "@mui/icons-material/Pageview";
import _ from "lodash";
import { useSelector } from "react-redux";

import BasicPlaceholder from "components/BasicPlaceholder/BasicPlaceholder";
import CustomCardTable from "components/Card/CustomCardTable";
import InputSelect from "components/Input/InputSelect";
import { TColumnType } from "components/Table/model";
import { LoggerContext } from "containers/app/AppProviders";
import { allListsSelector } from "containers/lists/redux/selectors";
import {
  fetchAugmentedActivityReportsApiCall,
  IAugmentedActivityReport,
} from "containers/workflows/redux/api";
import {
  allActivitiesSelector,
  allWorkflowsSelector,
} from "containers/workflows/redux/selectors";
import ActivityReportDialog from "containers/workflows/subcategories/activities/ActivityReportDialog";
import { getActivityColumnTypes } from "containers/workflows/subcategories/activities/utils/activityReports";
import { formatAugmentedActivityReport } from "containers/workflows/subcategories/activities/utils/activityReports/formatAugmentedActivityReport";
import { flattenAugmentedActivityReport } from "containers/workflows/subcategories/activities/utils/flattenActivityReport";
import useTranslations from "hooks/useTranslations";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { IActivityReportInTable, IWorkflow } from "model/entities/Workflow";

import { getActivitiesAsOptions } from "./utils/getActivitiesAsOptions";

interface ICustomerActivityReports {
  customerId: string;
}

function CustomerActivityReports({ customerId }: ICustomerActivityReports) {
  const lang = useTranslations();
  const lists = useSelector(allListsSelector);
  const activities = useSelector(allActivitiesSelector);
  const workflows = useSelector(allWorkflowsSelector);
  const logger = useContext(LoggerContext);

  const [columnTypes, setColumnTypes] = useState<TColumnType[]>([]);

  const langKey =
    lang.containers.lists.subCategories.items.createEditModal
      .customerActivityReportsTab;

  const [selectedActivityId, setSelectedActivityId] = useState<
    string | undefined
  >();

  const reportsMap = useRef<
    Map<string, IActivityReportInTable[]> | undefined
  >();

  const currentActivity = _.find(activities, {
    id: selectedActivityId,
  });

  const [currentReportID, setCurrentReportID] = useState<string | undefined>();
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [isLoadingReports, setIsLoadingReports] = useState(true);

  const selectedReport =
    reportsMap.current &&
    selectedActivityId &&
    _.find(reportsMap.current.get(selectedActivityId), {
      _id: currentReportID,
    });

  const reportsOfSelectedActivity =
    !isLoadingReports && reportsMap.current && selectedActivityId
      ? reportsMap.current.get(selectedActivityId)
      : undefined;

  const handleChangeColumnTypes = (
    shownColumns: TColumnType[],
    hiddenColumns: TColumnType[]
  ) => {
    setColumnTypes(_.concat(shownColumns, hiddenColumns));
  };

  const actions: ITableAction[] = [
    {
      action: openReportDetailDialog,
      actionType: ACTION_TYPE.DETAIL,
      label: "VIEW",
    },
  ];

  function prepareRawReports(rawReports: IAugmentedActivityReport[]) {
    if (!currentActivity) {
      logger.logError(
        `prepareRawReports: missing activity ${selectedActivityId}`
      );
      return;
    }

    const flattenedReports = _.map(rawReports, flattenAugmentedActivityReport);
    const formattedActivityReports = _.map(
      _.compact(flattenedReports),
      (report) => {
        return formatAugmentedActivityReport(
          report,
          false,
          columnTypes,
          currentActivity?.questionnaire?.questions,
          lists,
          lang
        );
      }
    );
    return _.compact(formattedActivityReports);
  }

  function handleChangeActivity(activityId: string) {
    setSelectedActivityId(activityId);
  }

  function openReportDetailDialog(report: IActivityReportInTable) {
    setCurrentReportID(report._id);
    setOpenReportDialog(true);
  }

  useEffect(() => {
    if (!reportsMap.current) {
      reportsMap.current = new Map<string, IActivityReportInTable[]>();
    }

    if (!currentActivity || !selectedActivityId) {
      return;
    }

    const newColumnTypes = _.filter(
      getActivityColumnTypes(currentActivity, lang),
      (col) => col.name != "customer"
    );

    setColumnTypes(newColumnTypes);

    (async () => {
      setIsLoadingReports(true);

      if (reportsMap.current?.has(selectedActivityId)) {
        setIsLoadingReports(false);
        return reportsMap.current.get(selectedActivityId);
      }

      const { data } = await fetchAugmentedActivityReportsApiCall(
        selectedActivityId,
        {
          _customer_id: [customerId],
        }
      );

      const rawReport = data.data["reports"];

      reportsMap.current?.set(
        selectedActivityId,
        prepareRawReports(rawReport) ?? []
      );

      setIsLoadingReports(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityId]);

  return (
    <Box>
      <Box data-testid="activity-selector">
        <InputSelect
          name="activity_selector"
          options={getActivitiesAsOptions(
            _.filter(activities, { active: true })
          )}
          onChange={handleChangeActivity}
          value={selectedActivityId || ""}
          lang={{
            title: langKey.customMessage.selectAnActivity,
            tooltip: "",
          }}
        />
      </Box>

      {reportsOfSelectedActivity && (
        <>
          {_.size(reportsOfSelectedActivity) > 0 ? (
            <Box data-testid="activity-reports-table">
              <CustomCardTable
                dataCount={_.size(reportsOfSelectedActivity)}
                showColumnMenu
                columnTypes={columnTypes}
                data={reportsOfSelectedActivity}
                onChangeColumnTypes={handleChangeColumnTypes}
                actions={actions}
              />
            </Box>
          ) : (
            <BasicPlaceholder
              icon={<PageviewIcon />}
              title={langKey.customMessage.noActivitiesFound}
              description={langKey.customMessage.noActivitiesFoundDescription}
            />
          )}
        </>
      )}
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        {isLoadingReports && selectedActivityId && (
          <Box height={"100px"} data-testid="loader">
            <CircularProgress />
          </Box>
        )}
        {!selectedActivityId && (
          <Box width={"100%"} justifyContent={"flex-start"}>
            <BasicPlaceholder
              icon={<DeselectIcon />}
              title={langKey.customMessage.noActivitySelected}
              description={langKey.customMessage.noActivitySelectedDescription}
            />
          </Box>
        )}
      </Box>

      {selectedReport && currentActivity && (
        <ActivityReportDialog
          open={openReportDialog}
          initViewMode={"VIEW"}
          activity={currentActivity}
          report={selectedReport as any}
          workflow={
            (_.find(workflows, (w) => w.id === selectedReport._workflow_id) ??
              {}) as IWorkflow
          }
          onClose={() => {
            setOpenReportDialog(false);
          }}
          disableEdition
        />
      )}
    </Box>
  );
}

export default CustomerActivityReports;
