import { Box, Button } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import { useHistory } from "react-router";

import { primaryColor } from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import { useCalendarRange, useTranslations } from "hooks";
import { getCalendarPathFromRange } from "hooks/useCalendarRange";

import { getNextDate } from "./utils/getNextDate";

function CalendarDateRange({
  onDateChange,
}: {
  onDateChange?: (date: moment.Moment) => void;
}) {
  const lang = useTranslations();
  const history = useHistory();
  const { date, mode } = useCalendarRange();
  const formattedDate = moment.utc(date);

  async function onClickLeft() {
    const newDate = getNextDate(moment(date).toDate(), mode, "backward");
    const newUrl = `?calendar=${getCalendarPathFromRange(
      {
        date: newDate,
        mode,
      },
      "MODE/YYYY/MM/DD"
    )}`;
    history.push(newUrl);

    onDateChange?.(moment(newDate));
  }

  async function onClickRight() {
    const newDate = getNextDate(moment(date).toDate(), mode, "forward");
    const newUrl = `?calendar=${getCalendarPathFromRange(
      {
        date: newDate,
        mode,
      },
      "MODE/YYYY/MM/DD"
    )}`;
    history.push(newUrl);

    onDateChange?.(moment(newDate));
  }

  async function onClickToday() {
    const newDate = moment();
    const newUrl = `?calendar=${getCalendarPathFromRange(
      {
        date: newDate.toString(),
        mode,
      },
      "MODE/YYYY/MM/DD"
    )}`;
    history.push(newUrl);

    onDateChange?.(moment(newDate));
  }

  return (
    <Box
      display={"grid"}
      alignItems={"center"}
      gridColumnGap={16}
      gridTemplateColumns={"130px repeat(3,auto)"}
    >
      <Box fontWeight={"600"}>
        {formattedDate.format("MMMM")} {formattedDate.format("YYYY")}
      </Box>
      <SmallIconButton onClick={onClickLeft}>
        <ChevronLeftIcon htmlColor={primaryColor} data-testid="left" />
      </SmallIconButton>

      <SmallIconButton onClick={onClickRight}>
        <ChevronRightIcon htmlColor={primaryColor} data-testid="right" />
      </SmallIconButton>

      <Button
        data-testid="today"
        variant="outlined"
        color="secondary"
        style={{
          width: "100px",
          borderWidth: "2px",
        }}
        onClick={onClickToday}
      >
        {
          lang.containers.jobs.subCategories.notifications.createEditModal
            .inputDateRange.options.TODAY
        }
      </Button>
    </Box>
  );
}

export default CalendarDateRange;
