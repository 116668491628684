import { useEffect, useState } from "react";

import { Box, Button, makeStyles, Paper } from "@material-ui/core";
import _ from "lodash";

import InputSearch from "components/Input/InputSearch";
import CustomSpinner from "components/Progress/CustomSpinner";
import {
  fetchItemsForListAction,
  fetchListOptionsAction,
} from "containers/lists/redux/actions";
import { useActions, useTranslations } from "hooks";
import { IOption } from "model/application/components";

import InputCheckList from "./InputCheckList";

const MAX_ITEMS_TO_FETCH = 20;
const useStyles = makeStyles({
  paper: {},
  container: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    padding: "8px",
    minHeight: 150,
  },
  title: {
    fontWeight: 500,
  },
  searchBox: {},
  optionsContainer: {
    maxHeight: 300,
    overflow: "auto",
  },
  buttonContainer: {
    display: "grid",
    justifyItems: "end",
    gridRowGap: "8px",
  },
  buttonRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "16px",
  },
});

export interface IMultipleOptionsOnListSelectorProps {
  selectedOptions: IOption[];
  listId: string;
  onAddOptions: (options: Array<IOption>) => void;
  onRemoveOptions: (options: Array<IOption>) => void;
  handleClose: () => void;
  handleSave: () => void;
  handleCancel: () => void;
  multipleSelection?: boolean;
  searchDebounceTime?: number;
}

function MultipleOptionsOnListSelector({
  listId,
  selectedOptions,
  onAddOptions,
  onRemoveOptions,
  handleSave,
  handleCancel,
  multipleSelection,
  searchDebounceTime = 500,
}: Readonly<IMultipleOptionsOnListSelectorProps>) {
  const [initalOptionsCache, setInitialOptionsCache] = useState<
    IOption[] | undefined
  >(undefined);
  const [options, setOptions] = useState<IOption[] | undefined>(undefined);
  const [fetchListOptions, fetchItemsForList] = useActions([
    fetchListOptionsAction,
    fetchItemsForListAction,
  ]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const classes = useStyles();
  const lang = useTranslations();

  async function handleSearch(searchTerm: string) {
    const searchQuery = searchTerm === "" ? " " : searchTerm;
    setIsSearching(true);
    if (!listId) {
      throw new Error(
        "ListId is required for MultipleOptionsOnListSelector's search"
      );
    }
    const newOptions: any = await fetchListOptions(
      listId,
      searchQuery,
      MAX_ITEMS_TO_FETCH
    );
    setOptions(formatOptions(newOptions));
    setIsSearching(false);
  }

  function onInputSearchTerm(value: string) {
    setSearchTerm(value);
  }
  const debouncedOnInputSearchTerm = _.debounce(
    onInputSearchTerm,
    searchDebounceTime
  );

  function getFilterOptions() {
    const withoutSelected = _.filter(options, (option) => {
      return !_.find(selectedOptions, (opt) => opt.key === option.key);
    });
    return withoutSelected;
  }

  async function loadInitialOptions() {
    setIsSearching(true);
    if (!initalOptionsCache) {
      const result: any = await fetchItemsForList(listId, {
        limit: 20,
        skipStoreUpdate: true,
        include_linked_options: true,
        order: "asc",
        orderBy: "_displayed_name",
      });
      setOptions(formatOptions(result.items));
      setInitialOptionsCache(formatOptions(result.items));
    } else {
      setOptions(initalOptionsCache);
    }
    setIsSearching(false);
  }

  useEffect(() => {
    if (_.isEmpty(searchTerm)) {
      loadInitialOptions();
    } else {
      handleSearch(searchTerm as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <Paper
      className={classes.paper}
      data-testid="MultipleOptionsOnListSelector"
    >
      <Box className={classes.container}>
        <Box className={classes.title}>
          <InputSearch
            onChange={debouncedOnInputSearchTerm}
            about={lang.components.search}
            className={classes.searchBox}
          />
        </Box>
        <Box className={classes.optionsContainer}>
          {!isSearching && (
            <InputCheckList
              onAddOption={(option) => onAddOptions([option])}
              onRemoveOption={(option) => onRemoveOptions([option])}
              options={getFilterOptions()}
              selectedOptions={selectedOptions}
            />
          )}
          {/* TODO: Implement infinite scroll here to paginate search results
          If we can make the backend return the total number of items matched
          we should be able to implement this easily with the the ScrollEdgeListener */}

          {isSearching && (
            <Box
              width={"100%"}
              height={150}
              display={"grid"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <CustomSpinner />
            </Box>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          {multipleSelection && (
            <Box className={classes.buttonRow}>
              <Box>
                <Button
                  onClick={() => {
                    onRemoveOptions(
                      _.concat(getFilterOptions(), selectedOptions)
                    );
                  }}
                >
                  {lang.modal.deselectAll}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    onAddOptions(_.concat(getFilterOptions(), selectedOptions));
                  }}
                >
                  {lang.genericTerms.selectAll}
                </Button>
              </Box>
            </Box>
          )}

          <Box className={classes.buttonRow}>
            <Box>
              <Button onClick={handleCancel}>{lang.genericTerms.cancel}</Button>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                onClick={handleSave}
              >
                {lang.genericTerms.save}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default MultipleOptionsOnListSelector;

function formatOptions(options: any): IOption[] {
  return _.compact(
    _.map(options, (opt) => {
      // the two endpoints have different naming conventions for the name/id of the option
      const key = opt["id"] || opt["key"] || opt["_id"];
      const label = opt["name"] || opt["label"] || opt["_displayed_name"];
      return { key, label };
    })
  );
}
