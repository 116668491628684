import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { prepareFilters } from "components/Card/utils";
import { getQuery } from "components/Filter/Filter.utils";
import FilterDropdownManager from "components/Filter/FilterDropdownManager";
import { TColumnType } from "components/Table/model";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import { handleColumnsSearch } from "utils/filterUtils";

import CustomCardTable from "../../Card/CustomCardTable";
import styles from "../styles";

const useStyles = makeStyles(styles as any);

interface ITableChartProps {
  data?: any[];
  chart?: any;
  columns: any[];
  columnTypes: TColumnType[];
  rows: any[];
  fullWidth: boolean;
  displayTotalRow?: boolean;
  dbTotalRows?: number;
  onDownloadCSV?: (columns: TColumnType[], data: any[], query?: any) => void;
  onDownloadExcel?: () => void;
}

export const KPI_TABLE_LIMIT = 1_000;
export const TableChart: React.FunctionComponent<ITableChartProps> = ({
  data,
  columns,
  columnTypes,
  rows,
  fullWidth,
  displayTotalRow,
  dbTotalRows,
  onDownloadCSV,
  onDownloadExcel,
}) => {
  const classes = useStyles();
  const lang = useTranslations();

  const [filterParams, setFilterParams] = useState({});

  const onChangeFilters = (filters: any) => {
    const filterParams = getQuery(filters, undefined);
    setFilterParams(filterParams);
  };

  const formatColumnTitle = (columnName: string): string => {
    return _.split(columnName, "_").join(" ");
  };

  const formatColumn = (): TColumnType[] => {
    return _.map(columnTypes, (ct) => ({
      ...ct,
      label: formatColumnTitle(ct.label),
    }));
  };

  const formattedColumnTypes = formatColumn();

  let formattedData = data;
  if (!formattedData) {
    formattedData = _.map(rows, (r) => {
      return columns.reduce((acc, curr, idx) => {
        acc[curr] = r[idx];
        return acc;
      }, {});
    });
  }

  const columnFilters = prepareFilters(
    columnTypes,
    formattedData,
    filterParams
  );

  const finalFilteredData = handleColumnsSearch({
    filterParams,
    items: formattedData,
    columns: columnTypes,
  });

  return (
    <div
      className={fullWidth ? classes.TableChartFullWidth : classes.TableChart}
    >
      <CustomCardTable
        isTableChart
        columnTypes={formattedColumnTypes}
        data={finalFilteredData}
        searchLabel={"Search"}
        actions={[]}
        noCardAround={true}
        bigSize={fullWidth}
        showSyntheticRow={displayTotalRow}
        onDownloadCSV={() =>
          onDownloadCSV?.(formattedColumnTypes, finalFilteredData, filterParams)
        }
        onDownloadExcel={onDownloadExcel}
        paginationDescription={
          dbTotalRows &&
          finalFilteredData.length < dbTotalRows &&
          dbTotalRows > KPI_TABLE_LIMIT
            ? {
                description: formatString(
                  lang.components.table.pagination.description,
                  [finalFilteredData.length, dbTotalRows]
                ),
                tooltip: lang.components.table.pagination.tooltip,
              }
            : undefined
        }
        filterDropdownManager={
          <FilterDropdownManager
            filters={columnFilters}
            onChangeFilters={onChangeFilters}
            noBottomMargin
          />
        }
      />
    </div>
  );
};

export default TableChart;
