import TimeIcon from "@material-ui/icons/AccessTime";
import BarchartIcon from "@material-ui/icons/BarChart";
import MatrixIcon from "@material-ui/icons/BorderAll";
import MultipleChoiceIcon from "@material-ui/icons/CheckBox";
import UploadFileIcon from "@material-ui/icons/CloudUpload";
import CodeIcon from "@material-ui/icons/Code";
import ComputeIcon from "@material-ui/icons/Computer";
import DateIcon from "@material-ui/icons/DateRange";
import NumberIcon from "@material-ui/icons/Dialpad";
import SignatureIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import UndefinedIcon from "@material-ui/icons/Help";
import HttpIcon from "@material-ui/icons/HttpOutlined";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import SelectOnListIcon from "@material-ui/icons/List";
import GPSIcon from "@material-ui/icons/LocationOn";
import LoopIcon from "@material-ui/icons/Loop";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureIcon from "@material-ui/icons/PhotoCamera";
import PiechartIcon from "@material-ui/icons/PieChart";
import SingleChoiceIcon from "@material-ui/icons/RadioButtonChecked";
import ScorecardIcon from "@material-ui/icons/Score";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import BarcodeScannerIcon from "@material-ui/icons/SettingsOverscan";
import ShareIcon from "@material-ui/icons/Share";
import LineChartIcon from "@material-ui/icons/ShowChart";
import SpeedIcon from "@material-ui/icons/Speed";
import RatingIcon from "@material-ui/icons/Stars";
import MatrixChartIcon from "@material-ui/icons/TableChart";
import TextIcon from "@material-ui/icons/TextFields";
import PlainTextIcon from "@material-ui/icons/TextFormat";
import TimelineIcon from "@material-ui/icons/Timeline";
import TimerIcon from "@material-ui/icons/Timer";
import ToggleOnOutlinedIcon from "@material-ui/icons/ToggleOnOutlined";
import TransformIcon from "@material-ui/icons/Transform";
import CarouselIcon from "@material-ui/icons/ViewCarousel";
import HeatmapIcon from "@material-ui/icons/ViewModule";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PolylineIcon from "@mui/icons-material/Polyline";
import RttIcon from "@mui/icons-material/Rtt";
import TagIcon from "@mui/icons-material/Tag";

import { KPI_TYPE } from "model/entities/Dashboard";
import { STEP_TYPE } from "model/entities/Job";
import { CUSTOM_FIELD_TYPE } from "model/entities/Workflow";

export const getIconFromType = (type: string) => {
  let Icon = null;
  switch (type) {
    case KPI_TYPE.BAR_CHART:
    case KPI_TYPE.BAR_CHART_HORIZONTAL:
    case KPI_TYPE.HISTOGRAM:
    case KPI_TYPE.STACKED_BAR_CHART:
      Icon = BarchartIcon;
      break;
    case KPI_TYPE.PIE_CHART:
      Icon = PiechartIcon;
      break;
    case KPI_TYPE.CAROUSEL:
      Icon = CarouselIcon;
      break;
    case KPI_TYPE.GAUGE:
      Icon = SpeedIcon;
      break;
    case KPI_TYPE.HEATMAP_CHART:
      Icon = HeatmapIcon;
      break;
    case KPI_TYPE.SCORECARD:
      Icon = ScorecardIcon;
      break;
    case KPI_TYPE.LINE_CHART:
    case KPI_TYPE.MULTIPLE_LINE_CHART:
      Icon = LineChartIcon;
      break;
    case KPI_TYPE.MATRIX:
    case KPI_TYPE.TABLE:
      Icon = MatrixChartIcon;
      break;
    case KPI_TYPE.TIMELINE:
      Icon = TimelineIcon;
      break;
    case CUSTOM_FIELD_TYPE.TEXT:
      Icon = TextIcon;
      break;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_TEXT:
      Icon = RttIcon;
      break;
    case STEP_TYPE.START_WORKFLOW:
      Icon = LoopIcon;
      break;
    case STEP_TYPE.SHARE_QUERY_RESULTS:
      Icon = ShareIcon;
      break;
    case STEP_TYPE.CREATE_PREDICTION:
    case STEP_TYPE.USE_PREDICTION:
      Icon = VisibilityIcon;
      break;
    case "ACTIVITY":
      Icon = LibraryAddCheckIcon;
      break;
    case STEP_TYPE.CREATE_REPORT: // not sure this one is used anymore...
      Icon = NoteAddIcon;
      break;
    case STEP_TYPE.UPDATE_QUERY_ATTRIBUTES:
      Icon = ComputeIcon;
      break;
    case STEP_TYPE.TRANSFORMATION:
      Icon = TransformIcon;
      break;
    case STEP_TYPE.TIMER:
      Icon = TimerIcon;
      break;
    case STEP_TYPE.NOTIFICATION:
      Icon = EmailIcon;
      break;
    case STEP_TYPE.HTTP_REQUEST:
      Icon = HttpIcon;
      break;
    case STEP_TYPE.STREAM:
      Icon = SettingsEthernetIcon;
      break;
    case STEP_TYPE.SCRIPT:
    case STEP_TYPE.INTEGRATION:
      Icon = CodeIcon;
      break;
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
      Icon = TagIcon;
      break;
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
      Icon = NumberIcon;
      break;
    case CUSTOM_FIELD_TYPE.PICTURE:
      Icon = PictureIcon;
      break;
    case CUSTOM_FIELD_TYPE.SIGNATURE:
      Icon = SignatureIcon;
      break;
    case CUSTOM_FIELD_TYPE.BARCODE_SCANNER:
      Icon = BarcodeScannerIcon;
      break;
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      Icon = PhoneIcon;
      break;
    case CUSTOM_FIELD_TYPE.PLAIN_TEXT:
      Icon = PlainTextIcon;
      break;
    case CUSTOM_FIELD_TYPE.GPS:
      Icon = GPSIcon;
      break;
    case CUSTOM_FIELD_TYPE.DATE_PICKER:
    case KPI_TYPE.CALENDAR_CHART:
      Icon = DateIcon;
      break;
    case CUSTOM_FIELD_TYPE.TIME_PICKER:
      Icon = TimeIcon;
      break;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
      Icon = SingleChoiceIcon;
      break;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
      Icon = SelectOnListIcon;
      break;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      Icon = MultipleChoiceIcon;
      break;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST:
      Icon = SelectOnListIcon;
      break;
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
      Icon = MatrixIcon;
      break;
    case CUSTOM_FIELD_TYPE.COMPUTE:
      Icon = ComputeIcon;
      break;
    case CUSTOM_FIELD_TYPE.RATING:
      Icon = RatingIcon;
      break;
    case CUSTOM_FIELD_TYPE.UPLOAD_FILE:
      Icon = UploadFileIcon;
      break;
    case CUSTOM_FIELD_TYPE.BOOLEAN:
      Icon = ToggleOnOutlinedIcon;
      break;
    case "NOTIFICATION_EMAIL":
      Icon = UndefinedIcon;
      break;
    case CUSTOM_FIELD_TYPE.GEO_DELIMITATION:
      Icon = PolylineIcon;
      break;
    default:
      if (Object.keys(STEP_TYPE).includes(type)) {
        Icon = UndefinedIcon;
      }
  }
  return Icon;
};
