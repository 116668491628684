import React, { FormEvent } from "react";

import { Box, Button, FormControl } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import InputSearch from "components/Input/InputSearch";
import { useTranslations } from "hooks/useTranslations";

import { IFilter } from "../../../../model/application/Filter";
import FilterValidateButton from "./FilterValidateButton";
import { useHooks } from "./MultipleChoiceFilter.hooks";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface IMultipleChoiceFilterProps {
  filter: IFilter;
  onChangeFilter: (name: string, value: any) => any;
}

export const MultipleChoiceFilter: React.FunctionComponent<
  IMultipleChoiceFilterProps
> = (props: IMultipleChoiceFilterProps) => {
  const lang = useTranslations();
  const classes = useStyles();

  const { filter, onChangeFilter } = props;

  const {
    checkedOptions,
    onChangeSearch,
    handleCheckboxChange,
    options,
    setCheckedOptions,
  } = useHooks(filter);

  const onClearSelection = () => {
    setCheckedOptions([]);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newValue = _.map(checkedOptions, (option) => {
      return _.has(option, "value") ? option.value : option.key;
    });
    onChangeFilter(filter.tag, newValue);
  };

  const isChecked = (key: string) => !!_.find(checkedOptions, { key });

  //https://github.com/stackworx/formik-material-ui/issues/87
  return (
    <div className={classes.DropdownItem}>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" className={classes.Bottom}>
          <InputSearch onChange={onChangeSearch} />

          <Box
            margin="12px 0"
            display="flex"
            flexDirection="column"
            maxHeight="300px"
            overflow="auto"
          >
            {_.map(
              _.orderBy(
                _.filter(options, (_opt, idx) => idx <= 100),
                [(opt) => opt.label.toLowerCase()],
                ["asc"]
              ),
              (t, idx) => {
                if (idx === 100) return "...";
                return (
                  <FormControlLabel
                    key={t.key}
                    label={t.label}
                    control={
                      <Checkbox
                        className={classes.RadioCustom}
                        checked={isChecked(t.key)}
                        value={t.key}
                        onChange={(event, checked) => {
                          return handleCheckboxChange(
                            event.target.value,
                            checked
                          );
                        }}
                      />
                    }
                    className={classes.RadioButtonsCustom}
                  />
                );
              }
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Button
              className={classes.ClearSelectionButton}
              onClick={onClearSelection}
            >
              {lang.components.filters.clearSelection}
            </Button>
            <FilterValidateButton classes={classes} />
          </Box>
        </FormControl>
      </form>
    </div>
  );
};

export default MultipleChoiceFilter;
