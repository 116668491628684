import { IAction } from "redux/store/model";

import * as types from "./actionTypes";

export interface IChangeSubcategorySuccessActionCreator extends IAction {
  subcategory: any;
}

/**
 * @param {Object} subcategory Can be either "map" or "list"
 * @returns {Object} Object with Type of action and the payload information from APi
 * */
export function changeSubcategorySuccessActionCreator(
  subcategory: any
): IChangeSubcategorySuccessActionCreator {
  return {
    type: types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS,
    subcategory,
  };
}
