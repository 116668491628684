import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";
import _ from "lodash";

import { GreyLight } from "assets/colors";
import { fetchAugmentedActivityReportsWithoutStoring } from "containers/workflows/redux/actions";
import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import useActions from "hooks/useActions";
import useTranslations from "hooks/useTranslations";
import { IKPI, TTimelineData } from "model/entities/Dashboard";

import TimelineCardTitle from "./TimelinCardTitle";
import TimelineCard from "./TimelineCard";
import TimelineReportViewer from "./TimelineReportViewer";
import TimelineSkeleton from "./TimelineSkeleton";
import { getOrderedTimeLineData } from "./utils/getOrderedTimeLineData";
import { getTimelineDuration } from "./utils/getTimelineDuration";
import { removeStepsWithMissingReports } from "./utils/removeStepsWithMissingReports";

export interface ITimelineViewerProps {
  timelineChart: IKPI<TTimelineData[]>;
  onOpenCustomerDialog: (id: string) => void;
}

function TimelineViewer({
  timelineChart,
  onOpenCustomerDialog,
}: ITimelineViewerProps) {
  const lang = useTranslations();

  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [reports, setReports] = useState<IAugmentedActivityReport[]>([]);
  const [selectedReport, setSelectedReport] =
    useState<IAugmentedActivityReport>();

  const fetchActivityReports = useActions(
    fetchAugmentedActivityReportsWithoutStoring
  );

  const onClickReport = (id: string) => {
    const report = _.find(reports, (report) => report.metadata._id === id);
    setSelectedReport(report);
  };

  const onClose = () => {
    setSelectedReport(undefined);
  };

  useEffect(() => {
    // All the report IDs to load, grouped by activityId
    const reportIdsByActivityId: Record<string, string[]> = {};
    _.forEach(timelineChart.data, (event) => {
      if (event.activity_id && event.activity_report_id) {
        if (!reportIdsByActivityId[event.activity_id]) {
          reportIdsByActivityId[event.activity_id] = [];
        }
        reportIdsByActivityId[event.activity_id].push(event.activity_report_id);
      }
    });

    setIsLoadingReports(true);

    _.forEach(reportIdsByActivityId, async (reportIds, activityId) => {
      const uniqueIds = _.uniq(reportIds);
      if (_.isEmpty(uniqueIds)) {
        return;
      }

      const response = await fetchActivityReports(activityId, {
        ids: uniqueIds,
      });

      const reportsForActivity = response?.reports ?? [];
      setReports((prevReports) => [...prevReports, ...reportsForActivity]);
    });

    setIsLoadingReports(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeLineEvents = getOrderedTimeLineData(timelineChart.data || []);

  const timelineCards = _.map(timeLineEvents, (event, index) => {
    const firstEvent = _.first(event);
    if (!firstEvent) return null;
    const customerId = firstEvent.customer_id;
    const customerName = firstEvent.customer_name;

    return (
      <TimelineCard
        key={`${customerId}-${index}`}
        title={
          <TimelineCardTitle
            title={customerName}
            duration={getTimelineDuration(event, lang)}
            onClickIcon={() => onOpenCustomerDialog(customerId)}
          />
        }
        data={removeStepsWithMissingReports({
          data: event,
          reports,
        })}
        reports={reports}
        onClickReport={onClickReport}
      />
    );
  });

  return (
    <>
      <Box
        data-testid="timeline-viewer"
        display={"flex"}
        flexDirection={"row"}
        style={{ columnGap: "8px" }}
      >
        <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
          {isLoadingReports ? (
            <TimelineSkeleton />
          ) : _.isEmpty(timelineCards) ? (
            <EmptyTimeline />
          ) : (
            <>{timelineCards}</>
          )}
        </Box>
      </Box>

      {selectedReport && (
        <TimelineReportViewer report={selectedReport} onClose={onClose} />
      )}
    </>
  );
}

const EmptyTimeline = () => {
  const lang = useTranslations();
  const dashboardsLang = lang.containers.dashboards.subCategories.dashboards;
  const noLocationHistoryLang =
    dashboardsLang.createEditModal.timeline.customErrorMessages
      .noLocationHistoryForUser;

  return (
    <Box display={"flex"} flexDirection={"column"} color={GreyLight}>
      <Box fontSize={"70px"}>
        <LocationOffOutlinedIcon fontSize="inherit" htmlColor={GreyLight} />
      </Box>
      <Box color={"rgba(108, 108, 108, 1)"}>{noLocationHistoryLang}</Box>
    </Box>
  );
};

export default TimelineViewer;
