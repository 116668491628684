import {
  AlreadyExistsBusinessError,
  AlreadyLoggedInBusinessError,
  AuthenticationBusinessError,
  AuthenticationError,
  FreeTrialLimitReachedError,
  HasBeenDeletedBusinessError,
  InvalidOTPError,
  LicenseNotValidError,
  NotAllowedBusinessError,
  NotFoundBusinessError,
  NotUpToDateBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  QuotaExceededBusinessError,
  RunInBackgroundBusinessError,
  TeamChangedBusinessError,
  TimeoutBusinessError,
  ValidationBusinessError,
} from "fieldpro-tools/dist/src/tools/error/error";

const IGNORED_ERROR_CLASSES = [
  NotFoundBusinessError,
  // Quotas
  FreeTrialLimitReachedError,
  QuotaExceededBusinessError,
  // Login & License
  LicenseNotValidError,
  InvalidOTPError,
  AuthenticationError,
  AlreadyLoggedInBusinessError,
  // Background operations
  RunInBackgroundBusinessError,
  // Other business errors
  NotUpToDateBusinessError,
  TeamChangedBusinessError,
  NotAllowedBusinessError,
  TimeoutBusinessError,
  ValidationBusinessError,
  AlreadyExistsBusinessError,
  HasBeenDeletedBusinessError,
  AuthenticationBusinessError,
  PartialFailureBusinessError,
  PayloadTooLargeBusinessError,
  AlreadyLoggedInBusinessError,
];

/**
 * Returns true if we should skip capturing error with Sentry, false otherwise
 *
 * @param e Error-like object
 * @returns boolean
 */
const ignoreError = (e: unknown) => {
  // ignore error by class
  for (const errorClass of IGNORED_ERROR_CLASSES) {
    if (e instanceof errorClass) {
      return true;
    }
  }

  if (e instanceof Error) {
    return ignoreErrorByMessage(e);
  }

  return false;
};

const IGNORE_ERROR_MESSAGE = [
  "Network Error",
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications",
  "Element type is invalid: expected a string (for built-in components) or a class/function (for composite components)",
  "Non-Error promise rejection captured with value",
  "timeout of",
  "out of memory",
  "Request aborted",
];

const ignoreErrorByMessage = (e: Error) => {
  for (const errorMessage of IGNORE_ERROR_MESSAGE) {
    if (new RegExp(errorMessage, "i").test(e.message)) {
      return true;
    }
  }
  return false;
};

export default ignoreError;
