import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import _ from "lodash";

import { IOption } from "model/application/components";

export interface IInputCheckListProps {
  options?: IOption[];
  selectedOptions: IOption[];
  onAddOption: (option: IOption) => void;
  onRemoveOption: (option: IOption) => void;
}
function InputCheckList({
  onAddOption,
  onRemoveOption,
  options = [],
  selectedOptions,
}: Readonly<IInputCheckListProps>) {
  const optionsWOSelected = _.filter(options, (option) => {
    return !_.find(selectedOptions, (opt) => opt.key === option.key);
  });

  return (
    <List>
      {_.map(selectedOptions, (option) => {
        return (
          <ListItem
            dense
            onClick={() => onRemoveOption(option)}
            style={{
              cursor: "pointer",
            }}
            button
            key={option.key}
          >
            <ListItemIcon style={{ minWidth: "35px" }}>
              <Checkbox
                onClick={() => onRemoveOption(option)}
                checked={true}
                tabIndex={-1}
                edge="start"
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontSize: "16px",
                },
              }}
              primary={option.label}
            />
          </ListItem>
        );
      })}

      {_.map(optionsWOSelected, (option) => {
        return (
          <ListItem
            onClick={() => onAddOption(option)}
            style={{
              cursor: "pointer",
            }}
            button
            key={option.key}
          >
            <ListItemIcon style={{ minWidth: "35px" }}>
              <Checkbox
                onClick={() => onAddOption(option)}
                checked={false}
                tabIndex={-1}
                edge="start"
              />
            </ListItemIcon>
            <ListItemText
              primary={option.label}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px",
                },
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default InputCheckList;
