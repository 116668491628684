import { makeStyles } from "@material-ui/core";
import _ from "lodash";

import CustomButton from "components/Buttons/CustomButton";
import CustomMoreButton from "components/Buttons/CustomMoreButton";
import { ACTION_TYPE } from "model/application/UIActionTypes";

const MAX_ACTIONS_IN_DISPLAY = 3;

const ACTION_IN_OVERFLOW = [
  ACTION_TYPE.EDIT_MULTIPLE,
  ACTION_TYPE.CREATE_MULTIPLE,
  ACTION_TYPE.ARCHIVE_MULTIPLE,
  ACTION_TYPE.RESTORE_MULTIPLE,
  ACTION_TYPE.DELETE_MULTIPLE,
  ACTION_TYPE.OTHER,
  ACTION_TYPE.DOWNLOAD,
];

const ACTION_ORDER = [
  ACTION_TYPE.CREATE,
  ACTION_TYPE.SAVE,
  ACTION_TYPE.RUN,
  ACTION_TYPE.COLUMN_VISIBILITY,
  ACTION_TYPE.DOWNLOAD,
];

interface ITableMenu {
  actions: any[];
  selectedItems: any[]; // TODO: replace with a type template T
  onBulkAction: (action: any) => void;
}

const useStyles = makeStyles({
  ActionBarButtons: {
    display: "flex",
    background: "white",
    margin: "4px",
  },
  ActionButton: {
    fontSize: "16px",
  },
});

const TableMenu = ({ actions, selectedItems, onBulkAction }: ITableMenu) => {
  const classes = useStyles();
  const { actionsInOverflow, actionsOutsideOverflow } = sortForOverflow({
    actions,
  });

  if (_.isEmpty(actions)) {
    return null;
  }

  return (
    <div className={classes.ActionBarButtons}>
      {actionsOutsideOverflow.map((action, idx) => {
        return (
          <div key={idx}>
            <CustomButton
              className={classes.ActionButton}
              type={action.actionType}
              onClick={runAction(action.action, selectedItems)}
              disabled={action.disabled!(
                selectedItems,
                action.additionnalInfos
              )}
            />
          </div>
        );
      })}
      {actionsInOverflow.length > 0 ? (
        <div>
          <CustomMoreButton
            options={actionsInOverflow}
            onAction={onBulkAction}
            selectedItems={selectedItems}
          />
        </div>
      ) : null}
    </div>
  );
};

const runAction = (action: any, target: any) => () => {
  action(target);
};

interface ISortForOverflow {
  actions: any[];
}

const sortForOverflow = ({ actions }: ISortForOverflow) => {
  const actionsOutsideOverflow = [];
  const actionsInOverflow = [];
  let count = 0;
  const otherActions = actions.filter((action) =>
    ACTION_IN_OVERFLOW.includes(action.actionType)
  );
  otherActions.forEach((action) => {
    if (action.label && action.label.length > 0) actionsInOverflow.push(action);
  });
  for (const actionType of ACTION_ORDER) {
    const action = actions.filter((act) => act.actionType === actionType)[0];
    if (action) {
      if (count >= MAX_ACTIONS_IN_DISPLAY) {
        // the max number has already been reached. We add the action in the overflow
        actionsInOverflow.push(action);
      } else {
        // the max number has not been reach yet. We put the action outside from the overflow
        count++;
        actionsOutsideOverflow.push(action);
      }
    }
  }
  // actionsInOverflow = actionsInOverflow.filter((el) => el.label.length > 0);
  return { actionsInOverflow, actionsOutsideOverflow };
};

export default TableMenu;
