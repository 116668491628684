import _ from "lodash";

import { LangContext } from "containers/app/AppProviders";
import TLang, {
  ENTITIES,
  LANG_ACTIONS,
  SUB_CATEGORIES,
} from "model/application/Lang";

import * as langs from "../lang";

/**
 * Function to format the string by inserting the values of the different variables.
 * Returns a String.
 * @param {String} str Template message.
 * @param {any} vars Array of vars to insert in the template.
 */
const formatString = (str?: string, vars?: any[]) => {
  if (!str) {
    return "";
  }
  let result = str;
  _.forEach(vars, (elem, i) => {
    if (elem || elem === 0) result = result.split(`{%${i}}`).join(elem);
    else result = result.split(`{%${i}}`).join("");
  });
  return result;
};

const getActionName = (
  lang: TLang,
  action: LANG_ACTIONS,
  subject: SUB_CATEGORIES,
  plural: boolean = false,
  csvAction: boolean = false
) => {
  let template = lang.actions.singleGenericAction;
  if (plural) {
    template = lang.actions.bulkGenericAction;
  }
  if (csvAction) {
    template = lang.actions.bulkGenericActionFromCSV;
  }
  return formatString(template, [
    action,
    lang.containers[getEntityFromSubCategory(subject)].subCategories[subject]
      .subject,
  ]);
};

const getSuccessNotificationMessage = (
  lang: TLang,
  action: LANG_ACTIONS,
  subject: SUB_CATEGORIES,
  complement?: number | string,
  plural: boolean = false
): string => {
  if (!complement && complement !== 0) complement = "";
  if (plural) {
    return formatString(lang.notifications.successNotifications.defaultPlural, [
      lang.actions[action],
      lang.containers[getEntityFromSubCategory(subject)].subCategories[subject]
        .subjects,
      complement,
    ]);
  } else {
    return formatString(lang.notifications.successNotifications.default, [
      action,
      lang.containers[getEntityFromSubCategory(subject)].subCategories[subject]
        .subject,
      complement,
    ]);
  }
};

const getEntityFromSubCategory = (subcategory: SUB_CATEGORIES): ENTITIES => {
  switch (subcategory) {
    case SUB_CATEGORIES.CLIENT:
    case SUB_CATEGORIES.CLIENT_BILLING:
    case SUB_CATEGORIES.CLIENT_SUBSCRIPTION:
    case SUB_CATEGORIES.CLIENT_PLAN:
    case SUB_CATEGORIES.CLIENT_PACK:
      return ENTITIES.CLIENT;
    case SUB_CATEGORIES.FOLDER:
      return ENTITIES.CLIENT;
    case SUB_CATEGORIES.DASHBOARD:
      return ENTITIES.DASHBOARD;
    case SUB_CATEGORIES.ITEM:
      return ENTITIES.LIST;
    case SUB_CATEGORIES.TERRITORY:
      return ENTITIES.LIST;
    case SUB_CATEGORIES.LIST:
      return ENTITIES.LIST;
    case SUB_CATEGORIES.MOBILE_USER:
      return ENTITIES.USER;
    case SUB_CATEGORIES.PICTURE:
      return ENTITIES.PICTURE;
    case SUB_CATEGORIES.ACTIVITY_REPORT:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.ACTIVITY:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.TEAM:
      return ENTITIES.TEAM;
    case SUB_CATEGORIES.WEB_USER:
      return ENTITIES.USER;
    case SUB_CATEGORIES.WORKFLOW:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.DOCUMENT:
      return ENTITIES.DOCUMENT;
    case SUB_CATEGORIES.GROUP:
      return ENTITIES.TEAM;
    case SUB_CATEGORIES.SCRIPT:
    case SUB_CATEGORIES.STREAM:
    case SUB_CATEGORIES.JOB:
    case SUB_CATEGORIES.TRANSFORMATION:
    case SUB_CATEGORIES.NOTIFICATION:
    case SUB_CATEGORIES.INTEGRATION:
    case SUB_CATEGORIES.HTTP_REQUEST:
      return ENTITIES.JOB;
    case SUB_CATEGORIES.JOB_HISTORY:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.META_EXPRESSION:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.TABLEOPTIMIZATION:
      return ENTITIES.ENVIRONMENT;
    case SUB_CATEGORIES.WORKFLOW_REPORT:
      return ENTITIES.WORKFLOW;

    case SUB_CATEGORIES.TRIGGER:
      return ENTITIES.TRIGGER;
    case SUB_CATEGORIES.WEBHOOK:
      return ENTITIES.WORKFLOW;
    case SUB_CATEGORIES.DATABASE_SCHEMA:
      return ENTITIES.ENVIRONMENT;
    case SUB_CATEGORIES.CALENDAR_EVENT:
      return ENTITIES.CALENDAR;
    default:
      throw new Error(`Unexpected subcategory: ${subcategory}`);
  }
};

const getEntityNameFromSubcategory = (
  lang: TLang,
  subcategory: SUB_CATEGORIES,
  isPlural?: boolean
): string => {
  const entity = getEntityFromSubCategory(subcategory);

  return lang.containers[entity].subCategories[subcategory][
    isPlural ? "subjects" : "subject"
  ];
};

const getLangObject = (langStr?: string) => {
  let lang = LangContext["_currentValue"] as TLang;
  if (!lang || JSON.stringify(lang) === JSON.stringify({})) {
    lang = langs[langStr || "en"] as TLang;
  }
  return lang;
};

export {
  formatString,
  getActionName,
  getEntityFromSubCategory,
  getEntityNameFromSubcategory,
  getLangObject,
  getSuccessNotificationMessage,
};
