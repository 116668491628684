import { Component, createRef } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";

import { AppBar, Box, Toolbar, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { array, arrayOf, func, object, string } from "prop-types";
import { NavLink } from "react-router-dom";

import * as colors from "assets/colors";
import { isClientStatusExpired } from "containers/authentication/utils/clientStatus";
import { HOME_ROUTE } from "model/constants/routes";

import optimetriks_logo from "../../assets/images/png/optimetriks_logo.png";
import { getRoutes } from "../../utils/routes/getRoutes";
import ClientMenu from "./ClientMenu";
import ClientStatusBanner from "./ClientStatusBanner";
import NavbarLinks from "./NavbarLinks";
import styles from "./styles";

/**
 * Navbar stateless component
 */
export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarExists: true,
      color: "transparent",
      menuOpen: false,
      modalToggle: false,
      clientId: this.props.selectedClient.id,
      searchTerm: "",
    };

    this.sidebarToggle = createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }

  componentDidUpdate(prevProps) {
    const { location, history } = prevProps;
    if (
      window.innerWidth < 993 &&
      history.location.pathname !== location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current &&
        this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  /**
   * Gets the active route from the Location object passed in
   * */
  getActiveRoute() {
    let activeRoute = "";
    const { location, actionsAvailable, lang } = this.props;
    const callback = (route) => {
      if (route.path === location.pathname) {
        activeRoute = route.title.toUpperCase();
      }
    };
    getRoutes(actionsAvailable, lang).forEach(callback);
    return activeRoute;
  }

  /**
   * Toggles the Navigation dropdown menu
   * */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      color: isOpen ? "transparent" : "white",
    });
    this.setState({
      isOpen: !isOpen,
    });
  };

  dropdownToggle = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        dropDownOpen: !prevState.dropDownOpen,
      };
    });
  };

  /**Handles the opening and closing of sidebar */
  handleOpenSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    // referencing from ref
    this.sidebarToggle.current &&
      this.sidebarToggle.current.classList.toggle("toggled");
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    const { isOpen } = this.state;
    this.setState({
      color: window.innerWidth < 993 && isOpen ? "white" : "transparent",
    });
  };

  handleClickLabel = () => {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen,
    });
  };
  handleCloseMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  render() {
    const {
      classes,
      onLogoutClick,
      onOpenChangePasswordClick,
      onGetUserDBClick,
      notificationItems,
      onClientChange,
      clientOptions,
      selectedClient,
      refreshClient,
      privilege,
      appNotifications,
      showAppNotification,
      lang,
      signedInUser,
    } = this.props;

    const { menuOpen } = this.state;
    return (
      <Box width={isClientStatusExpired(selectedClient) ? "100vw" : "initial"}>
        <ClientStatusBanner client={selectedClient} />

        <AppBar
          position="static"
          color="inherit"
          style={{ backgroundColor: "#fefefe", minWidth: "100%" }}
        >
          <Toolbar
            style={{
              padding: "0",
              margin: "0",
              paddingLeft: "8px",
              backgroundColor: colors.TealLight,
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
              zIndex: "1300",
            }}
          >
            <Box className={classes.ClientLogoAndName}>
              <div
                onClick={this.handleClickLabel}
                style={{ display: "flex", cursor: "pointer" }}
              >
                <NavLink to={HOME_ROUTE}>
                  <Box className={classes.ImageContainer}>
                    <img
                      key={selectedClient.id}
                      className={classes.NavLinkImage}
                      src={selectedClient?.logo || optimetriks_logo}
                      alt={"ald"}
                      aria-label="HomeRouteNavLink"
                      style={{
                        filter: `grayscale(${
                          selectedClient?.status === "pilot" ? 1 : 0
                        })`,
                      }}
                    />
                  </Box>
                </NavLink>

                <Box display="flex" alignItems="center" flexDirection="row">
                  <Box className={classes.ClientName}>
                    {selectedClient?.name}
                  </Box>
                  <SettingsIcon />
                </Box>
              </div>

              {menuOpen && (
                <ClientMenu
                  onCloseMenu={this.handleCloseMenu}
                  selectedClient={selectedClient}
                  onOpenModal={this.handleModalOpen}
                  onCloseModal={this.handleModalClose}
                  clientOptions={clientOptions}
                  onClientChange={onClientChange}
                  onSearchChange={this.handleSearchChange}
                  lang={lang}
                  onClickLabel={this.handleClickLabel}
                />
              )}
            </Box>

            <Box display={{ xs: "block", sm: "none" }}>
              <MenuIcon onClick={this.handleOpenSidebar} />
            </Box>

            <NavbarLinks
              privilege={privilege}
              notificationItems={notificationItems}
              handleLogout={onLogoutClick}
              handleOpenChangePassword={onOpenChangePasswordClick}
              handleGetUserDB={onGetUserDBClick}
              handleClientChange={onClientChange}
              clientOptions={clientOptions}
              client={selectedClient}
              refreshClient={refreshClient}
              appNotifications={appNotifications}
              showAppNotification={showAppNotification}
              lang={lang}
              signedInUser={signedInUser}
            />
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

const SettingsIcon = () => (
  <span
    className="material-icons"
    style={{
      marginLeft: "6px",
      marginTop: "4px",
    }}
  >
    settings
  </span>
);

/**
 * Prop validation
 */
Navbar.propTypes = {
  classes: object,
  privilege: string,
  location: object,
  history: object,
  notificationItems: arrayOf(object),
  clientOptions: arrayOf(object),
  onLogoutClick: func.isRequired,
  onOpenChangePasswordClick: func.isRequired,
  onGetUserDBClick: func,
  onClientChange: func,
  selectedClient: object,
  actionsAvailable: object,
  refreshClient: func,
  appNotifications: array,
  showAppNotification: func,
  lang: object,
  drawerItems: func,
  signedInUser: object,
};

export default withStyles(styles)(Navbar);
