import { IVisitEvent } from "fieldpro-tools";
import moment from "moment";

export function getEventRowPosition(
  event: IVisitEvent,
  calendarstartTime: moment.Moment | Date
) {
  const startOffSet = Math.floor(moment.utc(event.start_time).minutes() / 30);
  const startDiffFromStartTime = moment
    .utc(event.start_time)
    .diff(calendarstartTime, "hours");

  const duration =
    Math.ceil(
      moment.utc(event.end_time).diff(event.start_time, "minutes") / 30
    ) * 30;
  const durationNormalized = Math.floor(duration / 30);

  //prettier-ignore
  return `${(startDiffFromStartTime * 2 + 1) +  startOffSet} / span ${durationNormalized}`;
}

export function calculateMarginTop(event: any) {
  const minuteOffset = moment.utc(event.start_time).minutes() % 30;
  const pixelsPerMinute = 0.5;
  return minuteOffset * pixelsPerMinute;
}
