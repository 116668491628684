import _ from "lodash";
import { useSelector } from "react-redux";

import CustomDialogForm from "components/Dialog/CustomDialogForm";
import { IInputOption } from "components/Input/BaseOptionsContainer";
import InputMultipleOptions from "components/Input/InputMultipleOptions";
import { getPrivilege } from "containers/authentication/redux/selector";
import UserProfileForm from "containers/clients/components/ClientForm/Tabs/UsersTab/UserProfileForm";
import { getErrorMessagesForProfile } from "containers/clients/components/modals/clientBuilderUtils";
import useOptionsHandlers from "hooks/useOptionsHandlers";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IAccessRightProfile } from "model/entities/Client";
import { IWebUser } from "model/entities/User";

interface IInputProfiles {
  viewMode: TViewMode;
  profiles?: IAccessRightProfile[];
  selectedClientWebUsers: IWebUser[]; // TODO: always empty ? useSelector instead of prop ?
  onChangeProfiles: (profiles: IAccessRightProfile[]) => void;
  placeholder?: string;
}

type TOption = IInputOption<IAccessRightProfile>;

const InputAccessRightProfiles = ({
  viewMode,
  profiles,
  selectedClientWebUsers,
  onChangeProfiles,
  placeholder,
}: IInputProfiles) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.clients.subCategories.clients.createEditModal.inputProfile;

  const onChangeOptions = (options: TOption[]) => {
    const profiles = _.map(options, "value");
    onChangeProfiles(_.compact(profiles));
  };

  const profilesWithLock = _.map(profiles, (profile) => {
    const res: IAccessRightProfile & { locked?: boolean } = {
      ...profile,
    };

    // WARNING: dangerous because missing property is equivalent to "true" instead of "false"
    if (!res.hasOwnProperty("access_all_resources")) {
      res.access_all_resources = true;
    }

    const roles = _.map(selectedClientWebUsers, (user: IWebUser) => user.role);
    if (_.includes(roles, res.name)) {
      res.locked = true;
    }
    return res;
  });

  const options = getOptionsFromProfiles(profilesWithLock);

  const {
    optionToCreate,
    optionToEdit,
    onDeleteOption,
    onOpenAddOption,
    setOptionToEdit,
    setOptionToCreate,
    onChangeValueToCreate,
    onChangeValueToEdit,
    onConfirmOptionToCreate,
    onConfirmOptionToEdit,
  } = useOptionsHandlers<IAccessRightProfile>({
    options,
    onChangeOptions,
    defaultValue: {
      name: "",
      access_all_resources: false,
      actions: {},
    },
  });

  // Validation
  const additionnalProps = { profiles: profilesWithLock };

  const createOptionErrors =
    optionToCreate &&
    getErrorMessagesForProfile({
      attributes: optionToCreate.value,
      additionnalProps,
      viewMode: "CREATE",
      lang,
    });

  const editOptionErrors =
    optionToEdit &&
    getErrorMessagesForProfile({
      attributes: optionToEdit.value,
      additionnalProps,
      viewMode: "EDIT",
      lang,
    });
  const userRole = useSelector(getPrivilege);
  return (
    <>
      <InputMultipleOptions
        viewMode={viewMode}
        label={langKey.title}
        tooltip={langKey.tooltip}
        options={options}
        onChangeOptions={onChangeOptions}
        placeholder={placeholder}
        onOpenAddOption={onOpenAddOption}
        onClickOption={setOptionToEdit}
        onDeleteOption={onDeleteOption}
        draggable
      />

      {optionToCreate && (
        <CustomDialogForm
          isOpen
          title={langKey.createEditModal.createTitle}
          onClose={() => setOptionToCreate(null)}
          onConfirmAction={onConfirmOptionToCreate}
          confirmBtnText={lang.modal.add}
          isDisabled={!_.isEmpty(createOptionErrors)}
          lang={lang}
        >
          <UserProfileForm
            onChange={onChangeValueToCreate}
            element={optionToCreate.value}
            lang={lang}
            role={userRole}
          />
        </CustomDialogForm>
      )}

      {optionToEdit && (
        <CustomDialogForm
          isOpen
          onClose={() => setOptionToEdit(null)}
          title={langKey.createEditModal.editTitle}
          onConfirmAction={onConfirmOptionToEdit}
          confirmBtnText={lang.modal.save}
          isDisabled={!_.isEmpty(editOptionErrors)}
          lang={lang}
        >
          <UserProfileForm
            onChange={onChangeValueToEdit}
            element={optionToEdit.value}
            lang={lang}
            role={userRole}
          />
        </CustomDialogForm>
      )}
    </>
  );
};

const getOptionsFromProfiles = (profiles: IAccessRightProfile[]): TOption[] => {
  return _.map(profiles, (profile) => ({
    key: profile.name,
    label: profile.name,
    value: profile,
  }));
};

export default InputAccessRightProfiles;
