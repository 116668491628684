import { IAction } from "redux/store/model";

import { IChangeSubcategorySuccessActionCreator } from "./actionCreator";
import * as types from "./actionTypes";
import initialState from "./initialState";

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }
    default:
      return state;
  }
}
