import _ from "lodash";

import { ITeamSelector } from "model/entities/Team";

const applyFilterDependenciesOnUsers = (
  options: {
    label: string;
    key: string;
  }[],
  teamsFilter?: ITeamSelector[]
) => {
  let userOptions = options;
  if (teamsFilter) {
    userOptions = userOptions.filter((u) => {
      const teamParents = _.filter(teamsFilter, (team: ITeamSelector) =>
        _.includes(
          _.map([...team.web_users, ...team.mobile_users], "key"),
          u.key
        )
      );
      return teamParents.length > 0;
    });
  }

  return userOptions;
};

const applyUsersFilterDependancyOnTeams = (
  teams: ITeamSelector[],
  userIds: string[] = []
) => {
  return _.filter(teams, (team) => {
    const t = _.intersection(
      _.map([...team.web_users, ...team.mobile_users], "key"),
      userIds
    );
    return t.length > 0;
  });
};

const applyHierarchiesFilterDependendyOnTeams = (
  teams: ITeamSelector[],
  hierarchyKeys: string[] = []
) => {
  return _.filter(teams, (team) => {
    const part = _.pickBy(team, (value, key) => {
      return key.startsWith("level_") && value !== null;
    });
    const value = _.uniq(_.flatMap(_.values(part)));
    return _.intersection(value, hierarchyKeys).length > 0;
  });
};

export {
  applyFilterDependenciesOnUsers,
  applyHierarchiesFilterDependendyOnTeams,
  applyUsersFilterDependancyOnTeams,
};
