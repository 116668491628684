import { createStyles } from "@material-ui/core";

const legendHeigth = 50;
const titleHeightPx = 50;

const chartContainerHeight = (headerHeigth: number) =>
  "calc(100% - " + headerHeigth + "px)";

export default (theme: any) =>
  createStyles({
    titleContainer: {
      overflow: "hidden",
      height: titleHeightPx + "px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    title: {
      fontWeight: "bold",
      fontSize: "1.2em",
      float: "left",
      display: "inline",
    },
    chartCard: {
      display: "flex",
      position: "relative",
    },
    chartCardLeft: {
      width: "90%",
    },
    chartCardRight: {
      width: "10%",
    },
    download: {
      "font-weight": "bold",
      fontSize: "0.8em",
      color: theme.palette.primary.main,
      float: "right",
      display: "inline-flex",
      marginLeft: "30px",
      marginBottom: "3px",
    },
    downloadText: {
      marginTop: "5px",
    },
    downloadIcon: {
      display: "inline-flex",
    },
    container: {
      padding: "20px",
    },
    chartContainerWithVerticalOverflow: {
      height: chartContainerHeight(titleHeightPx),
    },
    chartContainerWithOverflow: {
      height: chartContainerHeight(titleHeightPx),
      overflow: "auto",
    },
    chartContainer: {
      height: chartContainerHeight(titleHeightPx),
    },
    withLegend: {
      height: chartContainerHeight(titleHeightPx + legendHeigth),
    },
    slice: {
      padding: "10px",
      "& p": {
        marginBottom: "3px",
      },
    },
    sliceTitle: {
      "font-weight": "bold",
    },
  });
