import { Box, Fade, Toolbar } from "@material-ui/core";
import moment from "moment";

import { whiteCoffee } from "assets/colors";
import {
  isAboutToCancelSubscription,
  isClientBillingType,
  isClientPaymentOverdue,
  isClientStatusExpired,
} from "containers/authentication/utils/clientStatus";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { BILLING_TYPE, IClient } from "model/entities/Client";

import ResumeSubscriptionButton from "./ResumeSubscriptionButton";
import StripePortalButton from "./StripePortalButton";
import UpdatePaymentDetailsButton from "./UpdatePaymentDetailsButton";

// TODO: put in fieldpro-tools (shared with back-end)
export const DAYS_BEFORE_DELETION = 30;

export function calculateDaysUntilDeleted(client: IClient) {
  if (!client.expired_at) {
    return 0;
  }

  const daysSinceExpiry = moment()
    .startOf("day")
    .diff(client.expired_at, "days");
  return DAYS_BEFORE_DELETION - daysSinceExpiry;
}

interface IClientStatusBanner {
  client: IClient;
}

function ClientStatusBanner({ client }: IClientStatusBanner) {
  const lang = useTranslations();

  if (!isClientBillingType(client, BILLING_TYPE.AUTOMATED)) {
    return null;
  }

  const clientIsExpired = isClientStatusExpired(client);
  const clientSubscriptionIsOverdue = isClientPaymentOverdue(client);
  const clientIsAboutToCancelSubscription = isAboutToCancelSubscription(client);

  const clientsLang = lang.containers.clients.subCategories.clients;
  const inputLang = clientsLang.clientForm.clientStatusBanner;

  if (
    !clientIsExpired &&
    !clientSubscriptionIsOverdue &&
    !clientIsAboutToCancelSubscription
  ) {
    return null;
  }

  return (
    <Fade in>
      <Toolbar
        style={{
          padding: "0",
          margin: "0",
          zIndex: 1300,
          width: "100%",
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          columnGap: "8px",
          background: whiteCoffee,
          height: "50px",
          minHeight: "50px",
        }}
        data-testid="client-status-banner"
      >
        {/* if the client is expired, meaning his account will be deleted, show him this bar */}
        {clientIsExpired && (
          <>
            <Box>
              {formatString(
                lang.containers.clients.subCategories.clients.createEditModal
                  .trial.customMessage.daysLeftToDeletion,
                [calculateDaysUntilDeleted(client)]
              )}
            </Box>

            <StripePortalButton clientId={client.id} />
          </>
        )}

        {clientSubscriptionIsOverdue && (
          <>
            <Box>{inputLang.unableToProcessPayment}</Box>

            <UpdatePaymentDetailsButton />
          </>
        )}

        {clientIsAboutToCancelSubscription && (
          <>
            <Box marginRight={"24px"}>
              {formatString(inputLang.subscriptionCancelDate, [
                moment(client?.billing?.license_plan?.cancels_at).format(
                  "MMM D, YYYY"
                ),
              ])}
            </Box>

            <ResumeSubscriptionButton />
          </>
        )}
      </Toolbar>
    </Fade>
  );
}

export default ClientStatusBanner;
