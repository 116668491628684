import { IActivity as IBEActivity, IList } from "fieldpro-tools/dist/src/types";
import { IActivityReport as IBEActivityReport } from "fieldpro-tools/src/types/activities";
import { CUSTOM_FIELD_TYPE } from "fieldpro-tools/src/types/customFields";
import _ from "lodash";

import apiService from "api/apiService";
import { getBackendQuery } from "components/Filter/Filter.utils";
import { IChangeTagParams } from "containers/lists/redux/actions";
import { IActivity, IWorkflow } from "model/entities/Workflow";
import { IServerResponse } from "redux/actions/appActions";

import { prepareActivityForBackend, prepareWorkflowForBackend } from "./utils";

export const WORKFLOWS_ENDPOINT = "/workflows";
export const ACTIVITIES_ENDPOINT = "/activities";
export const ACTIVITIES_REPORTS_ENDPOINT = "/activity_reports";
export const WORKFLOW_REPORTS_ENDPOINT = "/reports";
export const STEPS_OVERVIEW_ENDPOINT = "/steps_overview";

interface ICreateWorkflowResponse {
  id: string;
  task_list: IList;
}

export const createWorkflowApiCall = (
  workflow: IWorkflow
): Promise<IServerResponse<ICreateWorkflowResponse>> => {
  return apiService.post(
    `${WORKFLOWS_ENDPOINT}`,
    prepareWorkflowForBackend(workflow)
  );
};

interface IFetchAllWorkflowsResponse {
  workflows: IWorkflow[];
}

/**
 * Gets all workflows from api call
 * @returns {Promise}
 */
export const fetchAllWorkflowsApiCall = (): Promise<
  IServerResponse<IFetchAllWorkflowsResponse>
> => {
  return apiService.get(`${WORKFLOWS_ENDPOINT}`);
};

interface IDeleteActivityReportResponse {}

export const deleteActivityReportApiCall = (
  activityId: string,
  reportId: string
): Promise<IServerResponse<IDeleteActivityReportResponse>> => {
  return apiService.delete(
    `${ACTIVITIES_ENDPOINT}/${activityId}/reports/${reportId}`,
    {}
  );
};
interface IFetchDownloadReportRawDataResponse {
  data: any[];
}

// TODO: put in fieldpro-tools
export interface IAugmentedField {
  key: string;
  type: CUSTOM_FIELD_TYPE;
  list_id?: string;
  value: any; // TODO: type based on type
}

export interface IAugmentedActivityReport {
  customFieldValues: IAugmentedField[];
  metadata: IBEActivityReport;
}

export interface IReportsFilters {
  [key: string]: any;
}

// /**
//  * @legacy use fetchAugmentedActivityReportsApiCall to have the full reports (with option labels in MCOL, SCOL, MATRIX)
//  */
// export const fetchActivityReportsApiCall = (
//   activityId: string,
//   filters: IReportsFilters
// ): Promise<
//   IServerResponse<{ reports: IActivityReport[]; totalCount: number }>
// > => {
//   const backendQuery = formatBackendQuery(getBackendQuery(filters));
//   const query = JSON.stringify(backendQuery);

//   return apiService.get(`${ACTIVITIES_ENDPOINT}/${activityId}/reports`, {
//     query,
//   });
// };

export const fetchAugmentedActivityReportsApiCall = (
  activityId: string,
  filters: IReportsFilters
): Promise<
  IServerResponse<{ reports: IAugmentedActivityReport[]; totalCount: number }>
> => {
  const backendQuery = formatBackendQuery(getBackendQuery(filters));
  const query = JSON.stringify(backendQuery);

  return apiService.get(
    `${ACTIVITIES_ENDPOINT}/${activityId}/augmented_reports`,
    {
      query,
    }
  );
};

export const fetchDownloadReportRawDataApiCall = (
  activityId: string,
  filters: any
): Promise<IServerResponse<IFetchDownloadReportRawDataResponse>> => {
  const backendQuery = formatBackendQuery(getBackendQuery(filters));
  const query = JSON.stringify(backendQuery);

  return apiService.get(
    `${ACTIVITIES_ENDPOINT}/${activityId}/raw_data`,
    {
      activity_id: activityId.startsWith("a_") ? activityId : `a_${activityId}`,
      query,
    },
    undefined,
    true
  );
};

export const formatBackendQuery = (backendQuery: any) => {
  return _.keys(backendQuery).reduce((memo, currentKey) => {
    const filterValue = backendQuery[currentKey];
    if (isDatePickerFilter(filterValue)) {
      // expected format: { datetag: {start:"2020-...", end:"2020-..."} }
      memo[currentKey] = {
        start: backendQuery[currentKey].startDate,
        end: backendQuery[currentKey].endDate,
      };
    } else {
      memo[currentKey] = backendQuery[currentKey];
    }
    return memo;
  }, {});
};

export const getActivityReportPdfUrlApiCall = ({
  activityId,
  reportId,
}: {
  activityId: string;
  reportId: string;
}): Promise<IServerResponse<IGetActivityReportPdfUrlResponse>> => {
  return apiService.get(
    `${ACTIVITIES_ENDPOINT}/${activityId}/reports/${reportId}/pdf_url`
  );
};

interface IGetActivityReportPdfUrlResponse {
  pdf_url: string;
}

// NOTE: heuristic date filter detection
const isDatePickerFilter = (filterValue: any) => {
  return _.every(["dateType", "startDate", "endDate"], (key) =>
    _.has(filterValue, key)
  );
};

interface IUpdateWorkflowResponse {
  task_list: IList;
  id: string;
}

export const updateWorkflowApiCall = (
  workflow: IWorkflow
): Promise<IServerResponse<IUpdateWorkflowResponse>> => {
  const body = prepareWorkflowForBackend(workflow);
  return apiService.patch(`${WORKFLOWS_ENDPOINT}/${body.id}`, body);
};

export const reorderWorkflowApiCall = (
  workflowsIds: string[]
): Promise<IServerResponse<IUpdateWorkflowResponse>> => {
  return apiService.patch(`${WORKFLOWS_ENDPOINT}`, { workflowsIds });
};

interface IStopWorkflowReportExecutionResponse {
  workflow_report: any;
}

interface IResumeWorkflowReportExecutionResponse {
  workflow_report: any;
}

export const stopWorkflowReportExecutionApiCall = (
  workflowReportId: string,
  workflowId: string
): Promise<IServerResponse<IStopWorkflowReportExecutionResponse>> => {
  return apiService.post(
    `${WORKFLOWS_ENDPOINT}/${workflowId}${WORKFLOW_REPORTS_ENDPOINT}/${workflowReportId}/stop`,
    {}
  );
};

export const resumeWorkflowReportExecutionApiCall = (
  workflowReportId: string,
  workflowId: string
): Promise<IServerResponse<IResumeWorkflowReportExecutionResponse>> => {
  return apiService.post(
    `${WORKFLOWS_ENDPOINT}/${workflowId}${WORKFLOW_REPORTS_ENDPOINT}/${workflowReportId}/resume`,
    {}
  );
};

interface IArchiveWorkflowResponse {}

export const archiveWorkflowApiCall = (
  workflowId: string
): Promise<IServerResponse<IArchiveWorkflowResponse>> => {
  return apiService.patch(`${WORKFLOWS_ENDPOINT}/${workflowId}/archive`, {});
};

interface IArchiveActivityResponse {}
export const archiveActivityApiCall = (
  activityId: string
): Promise<IServerResponse<IArchiveActivityResponse>> => {
  return apiService.patch(`${ACTIVITIES_ENDPOINT}/${activityId}/archive`, {});
};

interface IRestoreWorkflowResponse {}

export const restoreWorkflowApiCall = (
  workflowId: string
): Promise<IServerResponse<IRestoreWorkflowResponse>> => {
  return apiService.patch(`${WORKFLOWS_ENDPOINT}/${workflowId}/restore`, {});
};

interface IRestoreActivityResponse {}

export const restoreActivityApiCall = (
  workflowId: string
): Promise<IServerResponse<IRestoreActivityResponse>> => {
  return apiService.patch(`${ACTIVITIES_ENDPOINT}/${workflowId}/restore`, {});
};

interface IDeleteWorkflowResponse {}

export const deleteWorkflowApiCall = (
  workflowId: string
): Promise<IServerResponse<IDeleteWorkflowResponse>> => {
  return apiService.delete(`${WORKFLOWS_ENDPOINT}/${workflowId}`);
};
interface IDeleteWorkflowReportResponse {}

export const deleteWorkflowReportApiCall = (
  workflowId: string,
  workflowReportId: string
): Promise<IServerResponse<IDeleteWorkflowReportResponse>> => {
  return apiService.delete(
    `${WORKFLOWS_ENDPOINT}/${workflowId}/reports/${workflowReportId}`
  );
};
export interface IUploadFileForReportResponse {
  uploaded: boolean;
  urls: string[];
}
export type TUploadFileFunc = (
  actionName: string,
  files: any,
  file_metas: any
) => Promise<IServerResponse<IUploadFileForReportResponse>>;
export const uploadFileApiCall: TUploadFileFunc = (
  actionName: string,
  files,
  file_metas
) => {
  return apiService.multipartUpdate(
    `/upload/activity_reports`,
    files,
    { file_metas },
    actionName
  );
};
export const uploadFileForWorkflowApiCall: TUploadFileFunc = (
  actionName: string,
  files,
  file_metas
) => {
  return apiService.multipartUpdate(
    `/upload/workflows`,
    files,
    { file_metas },
    actionName
  );
};

interface ICreateActivityResponse {
  id: string;
}

export const createActivityApiCall = (
  activity: IActivity
): Promise<IServerResponse<ICreateActivityResponse>> => {
  return apiService.post(
    `${ACTIVITIES_ENDPOINT}`,
    prepareActivityForBackend(activity)
  );
};

interface IFetchAllActivitiesResponse {
  activities: IBEActivity[];
}

/**
 * Gets all activities from api call
 * @returns {Promise}
 */
export const fetchAllActivitiesApiCall = (): Promise<
  IServerResponse<IFetchAllActivitiesResponse>
> => {
  return apiService.get(`${ACTIVITIES_ENDPOINT}`);
};

interface IUpdateActivityResponse {
  task_list: IList;
  new_activity: IBEActivity;
}

export const updateActivityApiCall = (
  activity: IActivity
): Promise<IServerResponse<IUpdateActivityResponse>> => {
  const body = prepareActivityForBackend(activity);
  return apiService.patch(`${ACTIVITIES_ENDPOINT}/${body.id}`, body);
};

interface IDeleteActivityResponse {}

export const deleteActivityApiCall = (
  activityId: string
): Promise<IServerResponse<IDeleteActivityResponse>> => {
  return apiService.delete(`${ACTIVITIES_ENDPOINT}/${activityId}`);
};
interface IFetchWorkflowReportsResponse {
  workflow_reports: any[];
}

export const fetchWorkflowReportsApiCall = (
  workflowId: string | undefined,
  filters: any
): Promise<IServerResponse<IFetchWorkflowReportsResponse>> => {
  const finalFilter = {};
  const backendQuery = getBackendQuery(filters);
  Object.keys(backendQuery).forEach((att) => {
    if (["created_at", "updated_at"].includes(att)) {
      finalFilter[att] = JSON.stringify({
        startDate: backendQuery[att]["startDate"],
        endDate: backendQuery[att]["endDate"],
      });
    } else {
      finalFilter[att] = backendQuery[att];
    }
  });
  if (workflowId) {
    finalFilter["workflow_id"] = workflowId;
  }
  return apiService.get(
    `${WORKFLOWS_ENDPOINT}${WORKFLOW_REPORTS_ENDPOINT}`,
    finalFilter
  );
};
interface IFetchJobLogsResponse {
  steps_overview: any[];
}

export const fetchJobLogsApiCall = (
  workflowId: string
): Promise<IServerResponse<IFetchJobLogsResponse>> => {
  return apiService.get(
    `${WORKFLOWS_ENDPOINT}/${workflowId}${STEPS_OVERVIEW_ENDPOINT}`
  );
};

interface ICreateActivityReportResponse {
  id: string;
}

export const createActivityReportApiCall = (
  activity_report: any
): Promise<IServerResponse<ICreateActivityReportResponse>> => {
  return apiService.post(`${ACTIVITIES_REPORTS_ENDPOINT}`, activity_report);
};
interface IUpdateActivityReportResponse {
  activity_report: any;
}

export const updateActivityReportApiCall = (
  activity_report: any
): Promise<IServerResponse<IUpdateActivityReportResponse>> => {
  return apiService.patch(
    `${ACTIVITIES_REPORTS_ENDPOINT}/${activity_report.id}`,
    activity_report
  );
};

/* Api call for workflow reports */

interface ICreateWorkflowReportResponse {
  workflow_report: string;
}

export const createWorkflowReportApiCall = (
  workflow_report: any
): Promise<IServerResponse<ICreateWorkflowReportResponse>> => {
  return apiService.post(
    `${WORKFLOWS_ENDPOINT}/${workflow_report.workflow_id}/reports`,
    workflow_report
  );
};
interface IUpdateWorkflowReportResponse {
  workflow_report: string;
}
export const updateWorkflowReportApiCall = (
  workflow_report: any
): Promise<IServerResponse<IUpdateWorkflowReportResponse>> => {
  return apiService.patch(
    `${WORKFLOWS_ENDPOINT}/${workflow_report.workflow_id}/reports/${workflow_report.id}`,
    workflow_report
  );
};

export type TEditQuestionTagFunc = (
  activityId: string,
  params: IChangeTagParams
) => Promise<IServerResponse<{}>>;
export const editQuestionTagApiCall: TEditQuestionTagFunc = (
  activityId,
  params
) => {
  return apiService.patch(`${ACTIVITIES_ENDPOINT}/${activityId}/changetag`, {
    ...params,
  });
};
