import { Dispatch } from "redux";

import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IMobileUser } from "model/entities/User";
import IRootState, { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../../lang";
import { getSuccessNotificationMessage } from "../../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../../redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../../redux/actions/appActions";
import { getLang } from "../../../authentication/redux/selector";
import { showNotificationActionCreator } from "../../../notifications/actionCreator";
import * as levels from "../../../notifications/actionLevels";
import * as notificationTypes from "../../../notifications/actionTypes";
import {
  bulkLicenseMobileUsersBeginActionCreator,
  bulkLicenseMobileUsersFailureActionCreator,
  bulkLicenseMobileUsersSuccessActionCreator,
  bulkUnlicenseMobileUsersBeginActionCreator,
  bulkUnlicenseMobileUsersFailureActionCreator,
  bulkUnlicenseMobileUsersSuccessActionCreator,
  createMobileUsersBeginActionCreator,
  createMobileUsersFailureActionCreator,
  createMobileUsersSuccessActionCreator,
  deleteMobileUserBeginActionCreator,
  deleteMobileUserFailureActionCreator,
  deleteMobileUsersBeginActionCreator,
  deleteMobileUsersSuccessActionCreator,
  deleteMobileUserSuccessActionCreator,
  fetchMobileUsersBeginActionCreator,
  fetchMobileUsersFailureActionCreator,
  fetchMobileUsersSuccessActionCreator,
  licenseMobileUserBeginActionCreator,
  licenseMobileUserFailureActionCreator,
  licenseMobileUserSuccessActionCreator,
  unlicenseMobileUserBeginActionCreator,
  unlicenseMobileUserFailureActionCreator,
  unlicenseMobileUserSuccessActionCreator,
  unlockLoginFailureActionCreator,
  updateMobileUserBeginActionCreator,
  updateMobileUserFailureActionCreator,
  updateMobileUserSuccessActionCreator,
  uploadFileBeginAction,
  uploadFileFailureAction,
  uploadFileSuccessAction,
} from "../actionCreator/mobileUserActionCreator";
import {
  bulkLicenseMobileUsersApiCall,
  bulkUnlicenseMobileUsersApiCall,
  createMobileUsersApiCall,
  fetchMobileUsersApiCall,
  licenseMobileUserApiCall,
  removeMobileUserFromClientApiCall,
  removeMobileUsersFromClientApiCall,
  unlicenseMobileUserApiCall,
  unlockLoginApiCall,
  unlockUniqueLoginApiCall,
  updateMobileUserApiCall,
  updateMobileUsersApiCall,
  uploadFileApiCall,
} from "../api/mobileUserApi";

export interface MobileUsersActions {
  createMobileUsersAction: ICreateMobileUserActionFunc;
  fetchMobileUsersAction: IFetchMobileUserActionFunc;
  uploadFileMobileUserAction: IUploadFileActionFunc;
  updateMobileUserAction: IUpdateMobileUserActionFunc;
  updateMobileUsersAction: IUpdateMobileUsersActionFunc;
  deleteMobileUserAction: IDeleteMobileUserActionFunc;
  deleteMobileUsersAction: IDeleteMobileUsersActionFunc;
  bulkLicenseMobileUsersAction: IBulkLicenseMobileUsersActionFunc;
  bulkUnlicenseMobileUsersAction: IBulkUnlicenseMobileUsersActionFunc;
  licenseMobileUserAction: ILicenseMobileUserActionFunc;
  unlicenseMobileUserAction: IUnlicenseMobileUserActionFunc;
  unlockLoginAction: IUnlockLoginFunc;
  UnlockUniqueLoginAction: IUnlockUniqueLoginFunc;
}

interface IMobileUserWithIID extends IMobileUser {
  iid: string;
}

export type ICreateMobileUserActionFunc = (
  actionName: string,
  users: IMobileUserWithIID[]
) => any;
export const createMobileUsersAction: ICreateMobileUserActionFunc = (
  actionName,
  users
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    // get the id of the user who is creating this user. this won't be passed in to the given POST request
    // this will instead be passed to the redux store
    // also get the created_at date time

    dispatch(ajaxRequestAction());
    dispatch(createMobileUsersBeginActionCreator());

    return new Promise((resolve, reject) => {
      createMobileUsersApiCall(actionName, users)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          // create list of users with id
          const usersWithId = users.map((u) => {
            return {
              ...u,
              id: data.filter((d) => d.iid === u.iid)[0].id,
              licensed: false,
            };
          });
          dispatch(createMobileUsersSuccessActionCreator(usersWithId));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.MOBILE_USER,
                users.length,
                true
              )
            )
          );
          resolve(data);
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "CreateMobileUsersError",
            err,
            createMobileUsersFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};

export type IFetchMobileUserActionFunc = () => IDispatchAndGetState<any>;
export const fetchMobileUsersAction: IFetchMobileUserActionFunc = () => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchMobileUsersBeginActionCreator());

    return fetchMobileUsersApiCall()
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { users, mobile_teammates } = data;
        dispatch(ajaxSuccessAction());
        dispatch(fetchMobileUsersSuccessActionCreator(users, mobile_teammates));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchMobileUsersError",
          error,
          fetchMobileUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUploadFileActionFunc = (
  actionName: string,
  files: any[]
) => IDispatchAndGetState<{
  uploaded: boolean;
  files: {
    filename: string;
    url: string;
  }[];
}>;
export const uploadFileMobileUserAction: IUploadFileActionFunc = (
  actionName: string,
  files
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginAction());
    const fileMetas = { filetype: "MOBILE_USER" };

    return new Promise(function (resolve, reject) {
      uploadFileApiCall(actionName, files, fileMetas)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessAction());
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.MOBILE_USER,
                ""
              )
            )
          );
          resolve(data);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureAction,
            currLang
          );
          reject("Something went wrong");
        });
    });
  };
};

export type IUpdateMobileUserActionFunc = (
  actionName: string,
  mobileUser: IMobileUser
) => any;
export const updateMobileUserAction: IUpdateMobileUserActionFunc = (
  actionName: string,
  mobileUser
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateMobileUserBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateMobileUserApiCall(actionName, mobileUser)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateMobileUserSuccessActionCreator([mobileUser]));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.MOBILE_USER,
                `${mobileUser.first_name} ${mobileUser.last_name}`
              )
            )
          );
          resolve("");
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateMobileUserError",
            error,
            updateMobileUserFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IUpdateMobileUsersActionFunc = (
  actionName: string,
  mobileUsers: IMobileUser[]
) => any;
export const updateMobileUsersAction: IUpdateMobileUsersActionFunc = (
  actionName: string,
  mobileUsers
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateMobileUserBeginActionCreator());

    return new Promise((resolve, reject) => {
      updateMobileUsersApiCall(actionName, mobileUsers)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(updateMobileUserSuccessActionCreator(mobileUsers));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.MOBILE_USER,
                mobileUsers.length,
                true
              )
            )
          );
          resolve("");
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateMobileUserError",
            error,
            updateMobileUserFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
};

export type IDeleteMobileUserActionFunc = (
  actionName: string,
  id: string
) => IDispatchAndGetState<void>;
export const deleteMobileUserAction: IDeleteMobileUserActionFunc = (
  actionName: string,
  id
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteMobileUserBeginActionCreator());

    return removeMobileUserFromClientApiCall(actionName, id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteMobileUserSuccessActionCreator(id));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.MOBILE_USER
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteMobileUserError",
          error,
          deleteMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IDeleteMobileUsersActionFunc = (
  actionName: string,
  ids: string[]
) => IDispatchAndGetState<void>;
export const deleteMobileUsersAction: IDeleteMobileUsersActionFunc = (
  actionName: string,
  ids
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteMobileUsersBeginActionCreator());

    return removeMobileUsersFromClientApiCall(actionName, ids)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteMobileUsersSuccessActionCreator(ids));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.MOBILE_USER,
              ids.length,
              true
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteMobileUsersError",
          error,
          deleteMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IBulkLicenseMobileUsersActionFunc = (
  actionName: string,
  userIds: string[]
) => any;

export const bulkLicenseMobileUsersAction: IBulkLicenseMobileUsersActionFunc = (
  actionName,
  userIds
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];

    if (userIds.length === 0) return;
    dispatch(ajaxRequestAction());
    dispatch(bulkLicenseMobileUsersBeginActionCreator());

    // api call to link app user
    return bulkLicenseMobileUsersApiCall(actionName, userIds)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(bulkLicenseMobileUsersSuccessActionCreator(userIds));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.LICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              userIds.length,
              true
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "BulkLicenseMobileUsersError",
          err,
          bulkLicenseMobileUsersFailureActionCreator,
          currLang
        );
      });
  };
};

export type IBulkUnlicenseMobileUsersActionFunc = (
  actionName: string,
  userIds: string[]
) => any;
export const bulkUnlicenseMobileUsersAction: IBulkUnlicenseMobileUsersActionFunc =
  (actionName, userIds) => {
    return (dispatch: Dispatch, getState: () => IRootState) => {
      const currLang = lang[getLang(getState())];

      if (userIds.length === 0) return;
      dispatch(ajaxRequestAction());
      dispatch(bulkUnlicenseMobileUsersBeginActionCreator());

      // api call to link app user
      return bulkUnlicenseMobileUsersApiCall(actionName, userIds)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(bulkUnlicenseMobileUsersSuccessActionCreator(userIds));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UNLICENSE,
                SUB_CATEGORIES.MOBILE_USER,
                userIds.length,
                true
              )
            )
          );
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "BulkUnlicenseMobileUsersError",
            err,
            bulkUnlicenseMobileUsersFailureActionCreator,
            currLang
          );
        });
    };
  };

export type ILicenseMobileUserActionFunc = (
  actionName: string,
  user: IMobileUser
) => IDispatchAndGetState<void>;
export const licenseMobileUserAction: ILicenseMobileUserActionFunc = (
  actionName: string,
  user
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    // eslint-disable-next-line no-unused-vars
    dispatch(ajaxRequestAction());
    dispatch(licenseMobileUserBeginActionCreator());

    // api call to link app user
    return licenseMobileUserApiCall(actionName, user.id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(licenseMobileUserSuccessActionCreator(user));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.LICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              `${user.first_name} ${user.last_name}`
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "LicenseMobileUserError",
          err,
          licenseMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUnlicenseMobileUserActionFunc = (
  actionName: string,
  user: IMobileUser
) => IDispatchAndGetState<void>;
export const unlicenseMobileUserAction: IUnlicenseMobileUserActionFunc = (
  actionName: string,
  user
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(unlicenseMobileUserBeginActionCreator());

    // api call to link app user
    return unlicenseMobileUserApiCall(actionName, user.id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(unlicenseMobileUserSuccessActionCreator(user));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.UNLICENSE,
              SUB_CATEGORIES.MOBILE_USER,
              `${user.first_name} ${user.last_name}`
            )
          )
        );
      })
      .catch((err) => {
        treatErrorNotification(
          dispatch,
          "UnlicenseMobileUserError",
          err,
          unlicenseMobileUserFailureActionCreator,
          currLang
        );
      });
  };
};

export type IUnlockLoginFunc = (
  actionName: string,
  mobileUserId: string
) => any;
export const unlockLoginAction: IUnlockLoginFunc = (
  actionName: string,
  mobileUserId
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return new Promise((resolve, reject) => {
      unlockLoginApiCall(actionName, mobileUserId)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          resolve("");
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "UnlockMobileUserTokenError",
            err,
            unlockLoginFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};

export type IUnlockUniqueLoginFunc = (mobileUserId: string) => any;
export const UnlockUniqueLoginAction: IUnlockUniqueLoginFunc = (
  mobileUserId
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return new Promise((resolve, reject) => {
      unlockUniqueLoginApiCall(mobileUserId)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          resolve("");
        })
        .catch((err) => {
          treatErrorNotification(
            dispatch,
            "UnlockMobileUserTokenError",
            err,
            unlockLoginFailureActionCreator,
            currLang
          );
          reject(err);
        });
    });
  };
};
