import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import InputText from "components/Input/InputText/InputText";
import * as clientActions from "containers/clients/redux/actions";
import { useActionsLegacy } from "hooks/useActions";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

interface IDeveloperTab {
  id: string;
  dbname: string;
  viewMode: TViewMode;
}

const DeveloperTab: React.FunctionComponent<IDeveloperTab> = ({
  id,
  dbname,
  viewMode,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const clientActs = useActionsLegacy(clientActions);
  const [loading, setloading] = useState(true);
  const [dbCredential, setDBCredential] = useState({
    id: id,
    username: "",
    password: "",
  });

  useEffect(() => {
    setloading(true);
  }, []);

  if (loading) {
    clientActs.getDBAccessAction(id).then((client: any) => {
      setDBCredential({
        ...dbCredential,
        username: client.username,
        password: client.password,
      });
      setloading(false);
    });
  }

  return (
    <>
      {!loading ? (
        <Box>
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.id}
            name="id"
            key={`id${id}`}
            lang={label.inputDeveloperId}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
          {id ? (
            <InputText
              disabled
              defaultValue={dbname}
              name="dbname"
              key={`${dbname}`}
              lang={label.inputDBName}
              onChange={() => {}}
              viewMode={"VIEW"}
              replaceSpaces
              lowercase
              debounceDuration={400}
            />
          ) : null}
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.username}
            name="username"
            key={`username${dbCredential.username}`}
            lang={label.inputDeveloperUsername}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.password}
            name="password"
            lang={label.inputDeveloperPassword}
            key={`password${dbCredential.password}`}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
        </Box>
      ) : (
        <Skeleton animation="wave" style={{ height: "30px" }} />
      )}
    </>
  );
};

export default DeveloperTab;
