import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import CircularLoaderComponent from "../Progress/CustomCircularProgress";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export type TSnackbarType =
  | "success"
  | "warning"
  | "error"
  | "info"
  | "loading";

interface IMessageIconProps {
  type: TSnackbarType;
  message: string;
}

const MessageIcon: React.FunctionComponent<IMessageIconProps> = ({
  type,
  message,
}) => {
  const classes = useStyles();

  const Icon = variantIcon[type];
  return (
    <span id="client-snackbar" className={classes.message}>
      {type === "loading" ? (
        <CircularLoaderComponent
          color={"secondary"}
          small={true} /*className={classes.dotLoaderComponent}*/
        />
      ) : (
        <Icon className={classes.icon} />
      )}
      <div style={{ padding: "10px" }}>{message}</div>
    </span>
  );
};

interface ICustomSnackbarProps {
  message: string;
  type: TSnackbarType;
  hideDuration?: number;
  onClose: () => void;
}

export const CustomSnackbar: React.FunctionComponent<ICustomSnackbarProps> = ({
  message: messageProps,
  type: typeProps,
  hideDuration = 6000,
  onClose = () => true,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [message] = useState(messageProps);
  const [type] = useState(typeProps);

  const handleClose = (_event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    onClose();
  };

  if (!type) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={hideDuration}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
        className: classes[type],
      }}
      message={<MessageIcon type={type} message={message} />}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose as any}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

export default CustomSnackbar;
