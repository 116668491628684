import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Tab, Tabs } from "components/Tab";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useTranslations from "hooks/useTranslations";
import IPicture from "model/entities/Picture";

import { IImageDetectionDataFE } from "../types";
import PictureDetails from "./PictureDetails";
import ScanResultsTab from "./ScanResultsTab";

const useStyles = makeStyles(() => ({
  root: {
    height: "88vh",
    background: "white",
    paddingLeft: "8px",
    overflowY: "scroll",
    paddingBottom: "16px",
  },
  scrollBox: {
    height: "80vh",
    overflowY: "scroll",
  },
}));

export interface IPictureSideBarProps {
  selectedBoxId?: string;
  picture: IPicture;
  detections: IImageDetectionDataFE[];
  toggleDisplayDetections?: (value: boolean) => void;
  onClickScanResult?: (boxId: string, additionalBoxIds?: string[]) => void;
  onHoverScanResult?: (boxId: string, additionalBoxIds?: string[]) => void;
  isLoadingScanResults?: boolean;
}

function PictureSideBar({
  selectedBoxId,
  onHoverScanResult,
  onClickScanResult,
  picture,
  detections,
  toggleDisplayDetections,
  isLoadingScanResults,
}: IPictureSideBarProps) {
  const client = useSelector(getSelectedClient);
  const showScanResultsTab = !_.isEmpty(client.detectable_lists);
  const lang = useTranslations();
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const classes = useStyles();

  function enableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(true);
    }
  }
  function disableDisplayDetections() {
    if (toggleDisplayDetections) {
      toggleDisplayDetections(false);
    }
  }

  function handleClickScanResults(id: string, ids: string[] | undefined) {
    if (onClickScanResult) {
      onClickScanResult(id, ids);
    }
  }
  function handleHoverScanResults(id: string, ids: string[] | undefined) {
    if (onHoverScanResult) {
      onHoverScanResult(id, ids);
    }
  }

  return (
    <Box className={classes.root}>
      <Tabs animateTabSwitch>
        <Tab
          id="1"
          label={
            lang.containers.workflows.subCategories.activityReports.detailDialog
              .details
          }
        >
          <PictureDetails picture={picture} />
        </Tab>
        <Tab
          id="2"
          label={langKey.detectionsSideBar.options.scanResults}
          noOverflow
          hidden={!showScanResultsTab}
        >
          <Box className={classes.scrollBox}>
            <ScanResultsTab
              isLoadingScanResults={isLoadingScanResults}
              selectedBoxId={selectedBoxId}
              detections={detections}
              onMount={enableDisplayDetections}
              onUnmount={disableDisplayDetections}
              onClickScanResult={handleClickScanResults}
              onHoverScanResult={handleHoverScanResults}
            />
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
}

export default PictureSideBar;
