import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import { TWorkflowStepType } from "containers/workflows/models";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { ITransformation } from "model/entities/Job";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createTransformationBeginActionCreator,
  createTransformationFailureActionCreator,
  createTransformationSuccessActionCreator,
  deleteTransformationBeginActionCreator,
  deleteTransformationFailureActionCreator,
  deleteTransformationSuccessActionCreator,
  fetchAllTransformationsBeginActionCreator,
  fetchAllTransformationsFailureActionCreator,
  fetchAllTransformationsSuccessActionCreator,
  fetchJobHistoryBeginActionCreator,
  fetchJobHistoryFailureActionCreator,
  fetchJobHistorySuccessActionCreator,
  initTransformationJobBeginActionCreator,
  initTransformationJobFailureActionCreator,
  initTransformationJobSuccessActionCreator,
  runTransformationJobBeginActionCreator,
  runTransformationJobFailureActionCreator,
  runTransformationJobSuccessActionCreator,
  updateTransformationBeginActionCreator,
  updateTransformationFailureActionCreator,
  updateTransformationSuccessActionCreator,
} from "./actionCreators";
import {
  createTransformationApiCall,
  deleteTransformationApiCall,
  fetchJobHistoryApiCall,
  fetchTransformationsApiCall,
  initTransformationJobApiCall,
  runTransformationJobApiCall,
  updateTransformationApiCall,
} from "./api";
import { prepareTransformationsForFrontend } from "./utils";

// TRANSFORMATIONS

/**
 * Create Transformation action which creates a transformation for embedding
 *
 * @param {string} actionName the action name to display in the log
 * @param {ITransformation} transformation The transformation to create
 * @returns {Function}
 */
export const createTransformationAction: ICreateTransformationActionFunc = (
  actionName: string,
  transformation: ITransformation
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createTransformationBeginActionCreator());

    return createTransformationApiCall(actionName, transformation)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { id } = data;
        const transformationWithId = {
          ...transformation,
          id: id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(
          createTransformationSuccessActionCreator(transformationWithId)
        );
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.TRANSFORMATION
            )
          )
        );

        return { success: true };
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateTransformation",
          error,
          createTransformationFailureActionCreator,
          currLang
        );

        return { success: false, error };
      });
  };
};

/**
 * Update Transformation action which creates a transformation for embedding
 * @param {Object} transformation Transformation object to edit
 * @returns {Function}
 */
export const updateTransformationAction: TUpdateTransformationActionFunc = (
  actionName: string,
  transformation
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateTransformationBeginActionCreator());

    return updateTransformationApiCall(actionName, { ...transformation })
      .then(() => {
        dispatch(ajaxSuccessAction());
        dispatch(
          updateTransformationSuccessActionCreator({
            ...transformation,
          })
        );

        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.TRANSFORMATION,
              transformation.title
            )
          )
        );

        return { success: true };
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateTransformation",
          error,
          updateTransformationFailureActionCreator,
          currLang
        );

        return { success: false, error };
      });
  };
};

/**
 * Action to fetch allTransformations
 * @returns {Function}
 */
export const fetchAllTransformationsAction: TFetchAllTransformationsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllTransformationsBeginActionCreator());

      return fetchTransformationsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { transformations } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchAllTransformationsSuccessActionCreator(
              prepareTransformationsForFrontend(transformations)
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllTransformationsError",
            error,
            fetchAllTransformationsFailureActionCreator,
            currLang
          );
        });
    };
  };

/**
 * Delete Transformation Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {String} transformation_id Transformation id to delete
 * @param {String} transformation_name Transformation name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteTransformationAction: TDeleteTransformationActionFunc = (
  actionName,
  transformationId,
  transformationName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteTransformationBeginActionCreator());

    return deleteTransformationApiCall(actionName, transformationId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteTransformationSuccessActionCreator(transformationId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.TRANSFORMATION,
              transformationName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteTransformationError",
          error,
          deleteTransformationFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Run transformation job action which creates a transformation job
 */
export const runTransformationJobAction: IRunTransformationJobActionFunc = (
  actionName: string,
  transformation: ITransformation
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(runTransformationJobBeginActionCreator());

    return runTransformationJobApiCall(actionName, transformation)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(runTransformationJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.TRANSFORMATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RunTransformationJob",
          error,
          runTransformationJobFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Init transformation job action which initiates a transformation job
 */
export const initTransformationJobAction: IInitTransformationJobActionFunc = (
  actionName: string,
  transformationId: string,
  startDate: string,
  endDate: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(initTransformationJobBeginActionCreator());

    return initTransformationJobApiCall(
      actionName,
      transformationId,
      startDate,
      endDate
    )
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { success } = data;
        dispatch(ajaxSuccessAction());
        dispatch(initTransformationJobSuccessActionCreator(success));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RUN,
              SUB_CATEGORIES.TRANSFORMATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "InitTransformationJob",
          error,
          initTransformationJobFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch job history
 * @returns {Function}
 */
export const fetchJobHistoryAction: TFetchJobHistoryActionFunc = (
  stepId,
  stepType
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(fetchJobHistoryBeginActionCreator());

    return new Promise((res, rej) => {
      fetchJobHistoryApiCall(stepId, stepType)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(fetchJobHistorySuccessActionCreator(data.steps_overview));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              levels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DISPLAY,
                SUB_CATEGORIES.JOB_HISTORY,
                data.steps_overview.length,
                true
              )
            )
          );
          res(data.steps_overview);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchJobHistoryError",
            error,
            fetchJobHistoryFailureActionCreator,
            currLang
          );
          rej(error);
        });
    });
  };
};

export type ICreateTransformationActionFunc = (
  actionName: string,
  transformation: ITransformation
) => IDispatchAndGetState<{ success: boolean; error?: Error }>;

export type TUpdateTransformationActionFunc = (
  actionName: string,
  transformation: ITransformation
) => IDispatchAndGetState<{ success: boolean; error?: Error }>;

export type IRunTransformationJobActionFunc = (
  actionName: string,
  transformation: ITransformation
) => IDispatchAndGetState<void>;

export type IInitTransformationJobActionFunc = (
  actionName: string,
  transformationId: string,
  startDate: string,
  endDate: string
) => IDispatchAndGetState<void>;

export type TFetchAllTransformationsActionFunc =
  () => IDispatchAndGetState<void>;

export type TDeleteTransformationActionFunc = (
  actionName: string,
  transformationId: string,
  transformationName: string
) => IDispatchAndGetState<void>;

export type TFetchJobHistoryActionFunc = (
  stepId: string,
  stepType: TWorkflowStepType
) => IDispatchAndGetState<any>;

export interface ITransformationActions {
  createTransformationAction: ICreateTransformationActionFunc;
  updateTransformationAction: TUpdateTransformationActionFunc;
  fetchAllTransformationsAction: TFetchAllTransformationsActionFunc;
  deleteTransformationAction: TDeleteTransformationActionFunc;
  runTransformationJobAction: IRunTransformationJobActionFunc;
  initTransformationJobAction: IInitTransformationJobActionFunc;
  fetchJobHistoryAction: TFetchJobHistoryActionFunc;
}

const actions: ITransformationActions = {
  createTransformationAction: createTransformationAction,
  updateTransformationAction: updateTransformationAction,
  fetchAllTransformationsAction: fetchAllTransformationsAction,
  deleteTransformationAction: deleteTransformationAction,
  runTransformationJobAction: runTransformationJobAction,
  initTransformationJobAction: initTransformationJobAction,
  fetchJobHistoryAction: fetchJobHistoryAction,
};

export default actions;
