import { default as React } from "react";

import { Box, Grid } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import ChartContainer from "components/Dashboard/Chart/ChartContainer";
import { GaugeContainer } from "components/Dashboard/Gauge/GaugeContainer";
import ScorecardContainer from "components/Dashboard/ScoreCard/ScorecardContainer";
import { getQuery } from "components/Filter/Filter.utils";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import ContentLoader from "components/Progress/ContentLoader";
import { getSelectedClient } from "containers/clients/redux/selectors";
import GeoTrackingChartContainer from "containers/dashboards/components/GeoTrackingChart/GeoTrackingChartContainer";
import { customerComposedSelector } from "containers/lists/redux/selectors";
import { activeTeamsComposedSelector } from "containers/teams/redux/selectors";
import { allMobileUsersComposedSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import useTranslations from "hooks/useTranslations";
import { IFilter } from "model/application/Filter";
import { IDashboard, IKPI, KPI_TYPE } from "model/entities/Dashboard";

const sortDashboards = (kpis: IKPI[]) => {
  return kpis.sort((a, b) => a.index - b.index);
};

type TDashboardProps = {
  classes: any;
  filters: IFilter[];
  dashboard: IDashboard;
  selectedUser?: string;
  changeSelectedUser?: (idUser?: string) => void;
  onPreview?: boolean;
  onRunQuery?: (
    dashboard: IDashboard,
    query: any,
    callback?: any,
    degradedModeKpis?: string[]
  ) => Promise<any>;
};

const Dashboard: React.FunctionComponent<TDashboardProps> = (props) => {
  const {
    filters,
    dashboard,
    onRunQuery,
    selectedUser,
    changeSelectedUser,
    onPreview,
  } = props;

  let startDate: Date | undefined = undefined;
  let endDate: Date | undefined = undefined;

  const users = useSelector(allMobileUsersComposedSelector);
  const teams = useSelector(activeTeamsComposedSelector);
  const customerList = useSelector(customerComposedSelector);
  const selectedClient = useSelector(getSelectedClient);
  const lang = useTranslations();

  const userFilter = filters.find((f) => f.tag === "users");
  const dateFilter = filters.find((f) => f.tag === FILTER_TAG.DATE);

  if (dateFilter) {
    startDate = dateFilter.value.startDate;
    endDate = dateFilter.value.endDate;
    // special case of time change!
    if (endDate?.getHours && endDate.getHours() === 22)
      endDate = new Date(endDate.getTime() + 60 * 60 * 1000);
  }

  if (!dashboard.kpis) dashboard.kpis = [];

  if (
    dashboard.kpis.length === 1 &&
    dashboard.kpis[0].type === KPI_TYPE.TABLE
  ) {
    // if there is only 1 chart and if this chart is a TABLE, display it in full size
    return (
      <ChartContainer
        hierarchy={[]}
        meta_hierarchy={{}}
        key={"tablechart"}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        index={0}
        chart={dashboard.kpis[0]}
        users={users}
        lang={lang}
        downloadDashboard={() =>
          onRunQuery?.(dashboard, getQuery(filters, dashboard), undefined, [
            dashboard.kpis[0].tag,
          ])
        }
        dashboard={dashboard}
        onPreview={onPreview}
      />
    );
  }

  const sortedScorecardsAndGauges = sortDashboards(
    dashboard.kpis.filter(
      (k) => k.type === KPI_TYPE.SCORECARD || k.type === KPI_TYPE.GAUGE
    )
  ).sort((a, b) => a.index - b.index);

  const sortedCharts = sortDashboards(
    dashboard.kpis.filter(
      (k) => !(k.type === KPI_TYPE.SCORECARD || k.type === KPI_TYPE.GAUGE)
    )
  ).sort((a, b) => a.index - b.index);

  const indexPerType: any = sortedCharts.reduce((acc: any, curr: IKPI) => {
    if (!acc.hasOwnProperty(curr.type)) {
      acc[curr.type] = 0;
    } else {
      acc[curr.type]++;
    }
    return acc;
  }, {});

  function getSelectedUser() {
    if (selectedUser) return selectedUser;
    if (
      userFilter &&
      _.isArray(userFilter.value) &&
      userFilter.value.length === 1
    ) {
      return userFilter.value.at(0);
    }
  }

  return (
    <Grid container spacing={3}>
      {sortedScorecardsAndGauges.map((scorecardOrGauge, index) => {
        if (scorecardOrGauge.type === KPI_TYPE.SCORECARD)
          return (
            <ScorecardContainer
              key={"scorecard" + index}
              scorecard={scorecardOrGauge}
              index={index}
              downloadDashboard={() =>
                onRunQuery?.(
                  dashboard,
                  getQuery(filters, dashboard),
                  undefined,
                  [dashboard.kpis[0].tag]
                )
              }
            />
          );
        else
          return (
            <GaugeContainer
              key={"gauge" + index}
              gauge={{
                ...scorecardOrGauge,
                target: scorecardOrGauge.target
                  ? Number.parseFloat(scorecardOrGauge.target + "")
                  : undefined,
                max_value: scorecardOrGauge.max_value
                  ? Number.parseFloat(scorecardOrGauge.max_value + "")
                  : undefined,
              }}
              index={index}
              downloadDashboard={() =>
                onRunQuery?.(
                  dashboard,
                  getQuery(filters, dashboard),
                  undefined,
                  [dashboard.kpis[0].tag]
                )
              }
            />
          );
      })}

      {_.map(sortedCharts, (chart, index) => {
        switch (chart.type) {
          case "MAP": {
            // if (isFetchingItems || !chart.data)
            if (!chart.data) {
              return <ContentLoader key={`loader-${index}`} />;
            }

            if (!customerList) {
              return;
            }

            return (
              <Box
                key={`chart-${index}`}
                data-printable-type={`${KPI_TYPE.MAP}`}
                width={"100%"}
              >
                <GeoTrackingChartContainer
                  index={index}
                  chart={chart}
                  selectedUser={getSelectedUser()}
                  userFilter={_.map(userFilter?.options, (o) => ({
                    key: o.key,
                  }))}
                  customerList={customerList}
                  users={users}
                  changeSelectedUser={changeSelectedUser || (() => {})}
                  onRunQuery={onRunQuery}
                  queryFilters={getQuery(filters, dashboard)}
                />
              </Box>
            );
          }
          default: {
            return (
              <ChartContainer
                key={`chart-${index}`}
                startDate={startDate}
                endDate={endDate}
                index={indexPerType[chart.tag]}
                chart={chart}
                users={users}
                teams={teams}
                hierarchy={selectedClient.hierarchy_dependencies}
                meta_hierarchy={selectedClient.meta_hierarchy_dependencies}
                downloadDashboard={() =>
                  onRunQuery?.(
                    dashboard,
                    getQuery(filters, dashboard),
                    undefined,
                    [chart.tag]
                  )
                }
                lang={lang}
                dashboard={dashboard}
                onPreview={onPreview}
              />
            );
          }
        }
      })}
    </Grid>
  );
};

export default Dashboard;
