import React, { useEffect, useState } from "react";

import { Box, Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";

import { ErrorColor, White, YellowSecondary } from "assets/colors";
import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import InputTime from "components/Input/InputTime";
import { isClientBillingPlan } from "containers/authentication/utils/clientStatus";
import { getClientAdditionalInfosAction } from "containers/clients/redux/actions";
import { useActions } from "hooks/useActions";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  CLIENT_CATEGORY,
  CLIENT_STATUS,
  IClient,
  INVOICED_BY,
} from "model/entities/Client";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";
import { isEmptyValue } from "utils/isEmptyValue";

import { useTranslations } from "./../../../../../hooks/useTranslations";
import { ClientFormWrapper } from "./ClientFormWrapper";

export interface IClientManagementTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  viewOnly?: boolean;
  isEnterprise?: boolean;
  shouldCreateStripeCustomer?: boolean;
  userRole: string;
  onInputChange: any;
  onChangeAvailableJobs: any;
  onSelectChange: (optSelected: any, variableName: string) => void;
  shouldMarkError: any;
  onChangeDailyMaintenance: any;
  onChangeGoBackDays: any;
  onMultipleSelectChange: any;
  onSave?: () => void;
  onDiscard?: () => void;
  onCreate?: () => void;
  onForceMaintenance: (clientId: string, runAll?: boolean) => void;
  onSetClientLive: any;
  client?: IClient;
}

export const ClientManagementTab: React.FunctionComponent<
  IClientManagementTabProps
> = ({
  id,
  client,
  dbname,
  viewMode = "CREATE",
  go_back_days_filter,
  max_downloads,
  hour_daily_maintenance,
  minute_daily_maintenance,
  category,
  invoiced_by,
  onInputChange,
  onSelectChange,
  shouldMarkError,
  sales_owner,
  operations_owner,
  userRole,
  onChangeDailyMaintenance,
  onChangeGoBackDays,
  onMultipleSelectChange,
  onDiscard,
  onCreate,
  onForceMaintenance,
  status,
  onSetClientLive,
  isEnterprise,
  data_vis_owner,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const getClientAdditionalInfos = useActions(getClientAdditionalInfosAction);

  const [loading, setloading] = useState(true);
  const [ownersOptions, setOwners] = useState([] as any[]);
  const getMaintenanceTime = (): Date | undefined => {
    return !isEmptyValue(hour_daily_maintenance)
      ? new Date(
          new Date().setUTCHours(
            hour_daily_maintenance as number,
            minute_daily_maintenance
          )
        )
      : undefined;
  };

  useEffect(() => {
    (async () => {
      setloading(true);

      if (id) {
        const owners = await getClientAdditionalInfos(id);
        const internalOwnersOptions = _.filter(owners, (owner) =>
          isOptimetriksRole(owner.role)
        );

        setOwners(internalOwnersOptions);
      }

      setloading(false);
    })();
  }, [id, getClientAdditionalInfos]);

  if (loading) {
    return (
      <>
        <Skeleton animation="wave" style={{ height: "60px" }} />
        <Skeleton animation="wave" style={{ height: "60px" }} />
        <Skeleton animation="wave" style={{ height: "60px" }} />
        <Skeleton animation="wave" style={{ height: "60px" }} />
        <Skeleton animation="wave" style={{ height: "60px" }} />
        <Skeleton animation="wave" style={{ height: "60px" }} />
      </>
    );
  }

  const isCreationMode = viewMode === "CREATE";
  return (
    <ClientFormWrapper
      viewMode={viewMode}
      onCreate={onCreate}
      onDiscard={onDiscard}
      label={label.inputClientManagement.title}
    >
      <InputMultipleSelect
        viewMode={viewMode}
        name={"sales_owner"}
        multipleSelection={false}
        onChange={onMultipleSelectChange}
        options={_.map(ownersOptions, (u) => ({
          key: u.email,
          label: u.name,
        }))}
        defaultValue={ownersOptions
          .filter((u) => u.email === sales_owner)
          .map((u) => ({
            key: u.email,
            label: u.name,
          }))}
        lang={lang}
        langlabel={label.inputClientManagementSalesOwner}
      />

      <FormFieldBackground viewMode={viewMode}>
        <InputMultipleSelect
          viewMode={viewMode}
          name={"operations_owner"}
          multipleSelection={false}
          onChange={onMultipleSelectChange}
          options={_.map(ownersOptions, (u) => ({
            key: u.email,
            label: u.name,
          }))}
          defaultValue={ownersOptions
            .filter((u) => u.email === operations_owner)
            .map((u) => ({
              key: u.email,
              label: u.name,
            }))}
          lang={lang}
          langlabel={label.inputClientManagementOperationsOwner}
        />
      </FormFieldBackground>
      <FormFieldBackground viewMode={viewMode}>
        <InputMultipleSelect
          viewMode={viewMode}
          name={"data_vis_owner"}
          multipleSelection={false}
          onChange={onMultipleSelectChange}
          options={_.map(ownersOptions, (u) => ({
            key: u.email,
            label: u.name,
          }))}
          defaultValue={ownersOptions
            .filter((u: any) => u.email === data_vis_owner)
            .map((u: any) => ({
              key: u.email,
              label: u.name,
            }))}
          lang={lang}
          langlabel={label.inputClientManagementDataVis}
        />
      </FormFieldBackground>
      <InputSelect
        viewMode={viewMode}
        lang={label.inputClientManagementInvoicedBy}
        name={"invoiced_by"}
        value={invoiced_by}
        options={getOptionsInputSelect({
          listKeys: Object.keys(INVOICED_BY),
          lang: label.inputClientManagementInvoicedBy.options,
        })}
        onChange={onSelectChange}
      />
      <FormFieldBackground viewMode={viewMode}>
        <InputSelect
          viewMode={viewMode}
          lang={label.inputClientManagementCategory}
          name={"category"}
          error={shouldMarkError("category")}
          value={category}
          options={getOptionsInputSelect({
            listKeys: Object.keys(CLIENT_CATEGORY),
            lang: label.inputClientManagementClientCategory.options,
          })}
          onChange={onSelectChange}
          required={true}
        />
      </FormFieldBackground>
      <InputText
        lang={label.inputClientManagementHistoricalDaysOnReports}
        onChange={onChangeGoBackDays}
        defaultValue={go_back_days_filter}
        name="go_back_days_filter"
        required={false}
        autoComplete={"off"}
        disabled={!(isOptimetriksRole(userRole) || isCreationMode)}
        viewMode={viewMode}
      />

      <FormFieldBackground viewMode={viewMode}>
        <InputText
          defaultValue={max_downloads}
          error={shouldMarkError("max_downloads")}
          name="max_downloads"
          key={`max_downloads`}
          type={INPUT_TEXT_TYPE.INTEGER}
          lang={label.inputClientManagementMaxDownload}
          onChange={onInputChange}
          required={false}
          viewMode={viewMode}
        />
      </FormFieldBackground>

      {isOptimetriksRole(userRole) && (
        <InputBoolean
          viewMode={viewMode}
          name="is_enterprise"
          onChange={onInputChange}
          defaultValue={isEnterprise}
          lang={{
            title: label.clientManagement.options.isEntreprise,
            ...(!isClientBillingPlan(client, "pro") && {
              tooltip: label.inputClientIsEnterprise.tooltip,
            }),
          }}
          error={shouldMarkError("is_enterprise")}
          disabled={!isClientBillingPlan(client, "pro")}
        />
      )}

      <InputTime
        viewMode={viewMode}
        defaultValue={getMaintenanceTime()}
        name="time_daily_maintenance"
        lang={label.inputClientManagementHourDailyMaintenance}
        onChange={onChangeDailyMaintenance}
        required={false}
        clearable
        inLocalTime={false}
      />

      {viewMode !== "CREATE" && !!id && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gridGap="16px"
          margin="60px 0 -72px 0"
        >
          {isOptimetriksRole(userRole) &&
          _.includes([CLIENT_STATUS.PILOT], status) ? (
            <CustomButton
              type={BUTTON_TYPES_OUTSIDE_TABLE.ACTION}
              onClick={() => onSetClientLive({ id })}
              disabled={viewMode === "VIEW"}
              data-testid="go-live-button"
            >
              {label.inputGoLive.title}
            </CustomButton>
          ) : null}

          <Button
            style={{ background: YellowSecondary, color: White }}
            onClick={() => onForceMaintenance(id)}
          >
            {label.inputForceMaintenance.title}
          </Button>

          {dbname === "admin" ? (
            <Button
              style={{ background: ErrorColor, color: White }}
              onClick={() => onForceMaintenance(id, true)}
            >
              {`Force maintenance to all clients`}
            </Button>
          ) : null}
        </Box>
      )}
    </ClientFormWrapper>
  );
};

export default ClientManagementTab;
