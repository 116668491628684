import React from "react";

import { Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import _ from "lodash";

import { black, GreyDark, GreyLight } from "assets/colors";
import MenuButton, { AddIcon, ImportIcon } from "components/Buttons/MenuButton";
import CustomDialogForm from "components/Dialog/CustomDialogForm";
import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { IOption } from "model/application/components";
import { TInputAttributeLang, TLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { clone } from "utils/utils";

import CustomDialogWarning from "../../Dialog/CustomDialogWarning";
import BaseOptionsContainer, { IInputOption } from "../BaseOptionsContainer";
import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputEmptyContainer from "../InputEmptyContainer";
import styles from "../styles";

type TOption = IInputOption<any>;

export interface IInputMultipleCreatePropsBase {
  name: string;
  error?: string;
  defaultValue: any[];
  onChange: (elements: any[], name: string) => void;
  getErrorMessages?: TValidateFunction;
  getWarningMessages?: TValidateFunction;
  createModalTitleFunction?: (element: any) => string;
  editModalTitleFunction?: (element: any) => string;
  restoreModalTitleFunction?: (element: any) => string;
  classes?: any;
  chipTitleTemplate: string;
  CreateEditModal: any;
  isCreation?: boolean;
  editOnly?: boolean;
  lockedItems?: object[];
  additionnalProps?: object;
  lang: TLang;
  langlabel: TInputAttributeLang;
  draggable?: boolean;
  direction?: "vertical" | "horizontal";
  defaultValueForNewElement?: any;
  duplicateFunc?: (e?: any) => any;
  attributesTag?: string[];
  required?: boolean;
  viewMode?: TViewMode;
  onBulkImport?: (e: any) => void;
  BulkModal?: any;
  showDownloadButton?: boolean;
  onDownloadListOptions?: (options: IOption[]) => void;
  // NOTE: These props are not handled
  // Use legacy CustomMultipleCreate instead, or migrate functionality to this component.
  // expandableChip?: boolean;
  // deprecatedItems?: any[];
}
export interface IInputMultipleCreateProps
  extends IInputMultipleCreatePropsBase,
    Omit<IInputBaseLayout, keyof IInputMultipleCreatePropsBase> {
  disabled?: boolean;
}

export interface IInputMultipleCreateStates {
  elemIdx: number;
  elements: any[];
  touchedElement?: any;
  touchedElementIndex?: number; // when creating a new element
  isCreateEditModalOpen: boolean;
  isWarningModalOpen: boolean;
  optionToDelete: TOption | null;
  modalType: "create" | "edit" | "restore";
  isCreation: boolean;
  indexAdd: number;
  isBulkOpen: boolean;
}

export const getErrorFromAttribute = (
  element: any,
  attributeName: string
): string => {
  return element._error && element._error[attributeName]
    ? element._error[attributeName]
    : "";
};

export const getWarningFromAttribute = (
  element: any,
  attributeName: string
): string => {
  return element._warning && element._warning[attributeName].length > 0
    ? element._warning[attributeName]
    : "";
};

/**
 * NOTE: this component should be considered as LEGACY now,
 * as it is very complex and not well tested.
 *
 * Whenever possible, prefer using InputMultipleOptions + a custom modal as base components instead.
 * You can also use the useOptionsHandlers hook. (cf. InputAccessProfileRights)
 */
export class InputMultipleCreate extends React.Component<
  IInputMultipleCreateProps,
  IInputMultipleCreateStates
> {
  public static defaultProps = {
    lockedItems: [],
    draggable: false,
    isCreation: false,
    defaultValueForNewElement: {},
    showDownloadButton: false,
    required: false,
    direction: "vertical" as const,
    viewMode: "CREATE" as const,
  };

  constructor(props: IInputMultipleCreateProps) {
    super(props);

    let elemIdx = -1;
    if (props.lockedItems) elemIdx += props.lockedItems.length;
    if (props.defaultValue) elemIdx += props.defaultValue.length;

    const elements: any[] = [];
    if (props.lockedItems) props.lockedItems.forEach((e) => elements.push(e));
    if (props.defaultValue) props.defaultValue.forEach((e) => elements.push(e));

    // TODO: this is error prone
    // But where are indexes added in the first place ?
    // elements = elements.sort((a, b) => a.index - b.index);

    this.state = {
      elemIdx,
      elements,
      isCreateEditModalOpen: false,
      isWarningModalOpen: false,
      optionToDelete: null,
      modalType: "create",
      isCreation: false,
      touchedElement: undefined,
      indexAdd: 0,
      isBulkOpen: false,
    };
  }

  // TODO: causes bugs in parents calling the component with an actual "initialValue"
  // static getDerivedStateFromProps(
  //   props: IInputMultipleCreateProps,
  //   state: IInputMultipleCreateStates
  // ) {
  //   if (props.defaultValue != state.elements) {
  //     return {
  //       elements: props.defaultValue,
  //     };
  //   }
  //   return null;
  // }

  handleAddModalOpen = () => {
    const { elemIdx } = this.state;
    this.handleAddModalAtSpecificPositionOpen(elemIdx + 1);
  };

  handleAddModalAtSpecificPositionOpen = (index: number) => {
    const { defaultValueForNewElement } = this.props;

    this.setState({
      indexAdd: index,
    });

    this.setState((prevState) => {
      return {
        ...prevState,
        isCreateEditModalOpen: true,
        touchedElementIndex: index,
        touchedElement: { ...defaultValueForNewElement },
        modalType: "create",
        isCreation: true,
      };
    });
  };

  handleAddElement = () => {
    const { onChange, name } = this.props;
    const { elements, elemIdx, touchedElement, touchedElementIndex } =
      this.state;
    let newIdx = elemIdx + 1;

    if (touchedElementIndex != null) {
      // trying to insert an element in a specific place
      newIdx = touchedElementIndex;
    }
    elements.push({
      ...touchedElement,
      index: newIdx,
    });
    // remove "_error" attribute if no errors
    elements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });
    const newElements = elements
      .sort((a, b) =>
        a.hasOwnProperty("index") && b.hasOwnProperty("index")
          ? a.index - b.index
          : 1
      )
      .map((e, index) => ({ ...e, index }));
    this.setState({
      elements: newElements,
      elemIdx: newIdx,
      touchedElementIndex: undefined,
    });

    onChange(newElements, name);
    this.handleCreateEditModalClose();
  };

  handleRestoreElement = () => {
    const { onChange, name } = this.props;
    const { elements, elemIdx, touchedElement } = this.state;
    const newIdx = elemIdx + 1;
    elements.push({
      ...touchedElement,
      index: newIdx,
    });
    elements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });
    const newElements = elements
      .sort((a, b) =>
        a.hasOwnProperty("index") && b.hasOwnProperty("index")
          ? a.index - b.index
          : 1
      )
      .map((e, index) => ({ ...e, index }));

    this.setState((prevState) => {
      return {
        ...prevState,
        elements: newElements,
        elemIdx: newIdx,
        touchedElementIndex: undefined,
      };
    });

    onChange(newElements, name);
    this.handleCreateEditModalClose();
  };

  handleEditElement = () => {
    const { onChange, name } = this.props;
    const { elements, touchedElement } = this.state;
    const newElements: any[] = _.map(elements, (c) => {
      if (c.index === touchedElement.index) {
        return touchedElement;
      } else {
        return c;
      }
    }).filter((e) => e);
    // remove "_error" attribute if no errors
    newElements.forEach((e) => {
      if (_.isEmpty(e._error)) {
        delete e._error;
      }
    });
    this.setState({
      elements: newElements,
    });
    onChange(newElements, name);
    this.handleCreateEditModalClose();
  };

  handleEditModalOpen = (index: number) => {
    const { elements } = this.state;
    const touchedElement = _.find(elements, (e) => e.index === index);

    if (touchedElement) {
      if (index < _.size(this.props.lockedItems)) {
        touchedElement.isLockedElement = true;
      }
      this.setState((prev) => {
        return {
          touchedElement,
          isCreateEditModalOpen: !prev.isCreateEditModalOpen,
          modalType: "edit",
          isCreation: false,
        };
      });
    }

    this.setState({
      indexAdd: index - 1,
    });
  };

  handleDuplicateModalOpen = (element: any) => {
    const { duplicateFunc } = this.props;
    if (duplicateFunc) duplicateFunc(element);
  };

  handleCreateEditModalClose = () => {
    this.setState({
      isCreateEditModalOpen: false,
      touchedElement: undefined,
    });
  };

  onClickOption = (option: TOption) => {
    const { key: stringIndex } = option || {};
    this.handleEditModalOpen(Number(stringIndex));
  };

  onDeleteOption = (option: TOption) => {
    const { langlabel } = this.props;

    if (langlabel.warningOnRemove) {
      // a warning message is setup => we display the confirmation modal
      this.setState({ isWarningModalOpen: true, optionToDelete: option });
      return;
    }

    // no warning message is setup => we remove directly the option
    this.handleDeleteOption(option);
  };

  handleDeleteOption = (option: TOption) => {
    const { onChange, name } = this.props;
    const { elements } = this.state;

    const newElements = _.reject(
      elements,
      (element) => element.key === option.value.key
    );

    this.setState({
      elements: newElements,
      isWarningModalOpen: false,
      optionToDelete: null,
    });

    onChange(newElements, name);
  };

  onChangeOptions = (options: TOption[]) => {
    const { onChange, name } = this.props;

    const elements = _.map(options, (option) => {
      return option.value;
    });

    this.setState({ elements });
    onChange(elements, name);
  };

  prepareChipTitle = (pattern: string, element: any) => {
    let result = "";
    const splittedPattern = pattern.split("$");
    for (let i = 0; i < splittedPattern.length; i++) {
      if (i % 2 === 1) {
        // even idx: we replace the string per the value of the attribute
        result += element[splittedPattern[i]];
      } else {
        // odd idx: we display the string as it is
        result += splittedPattern[i];
      }
    }
    if (result.length > 30) {
      result = result.substring(0, 27) + "...";
    }
    return result;
  };

  handleChangeElement = (e: any) => {
    this.setState({
      touchedElement: e,
    });
  };

  prepareCreateEditModalContent = () => {
    const {
      CreateEditModal,
      additionnalProps,
      defaultValueForNewElement,
      lang,
      getWarningMessages,
      getErrorMessages,
    } = this.props;
    const { touchedElement, modalType, indexAdd } = this.state;
    const isLocked = touchedElement ? touchedElement.isLockedElement : false;
    if (touchedElement) delete touchedElement.isLockedElement;
    const elem = touchedElement ? touchedElement : defaultValueForNewElement;

    const additionalproperty = additionnalProps ? additionnalProps : {};

    const warningMessages = getWarningMessages
      ? getWarningMessages({
          attributes: elem,
          additionnalProps: additionalproperty,
          lang,
          viewMode: modalType === "create" ? "CREATE" : "EDIT",
        })
      : false;

    const errorMessages = getErrorMessages
      ? getErrorMessages({
          attributes: elem,
          additionnalProps: {
            ...additionnalProps,
            defaultValue: this.props.defaultValue,
          },
          lang,
          viewMode: modalType === "create" ? "CREATE" : "EDIT",
        })
      : false;

    // (TODO: this is fragile, we should on the contrary list the errors we want to keep and remove the others by default)
    // Remove all the "real time error status" (ex: empty field, or non-alphanumeric), keep the additionnal error status
    if (elem._error) {
      Object.keys(elem._error).forEach((k) => {
        if (
          [
            lang.components.inputErrors.empty,
            lang.components.inputErrors.tooLong,
            lang.components.inputErrors.alphanumeric,
            lang.components.inputErrors.startWithLetter,
            lang.components.inputErrors.alreadyInUse,
            lang.components.inputErrors.notPossibleToModifyBecauseTemplate,
          ].includes(elem._error[k])
        ) {
          delete elem._error[k];
        }
      });
      elem._error = { ...elem._error, ...errorMessages };
    } else {
      elem._error = errorMessages;
      elem._warning = warningMessages;
    }

    if (
      elem.hasOwnProperty("_error") &&
      (elem._error == null || Object.keys(elem._error).length === 0)
    ) {
      delete elem._error;
    }
    if (
      elem.hasOwnProperty("_warning") &&
      (elem._warning == null || Object.keys(elem._warning).length === 0)
    ) {
      delete elem._warning;
    }

    return (
      <CreateEditModal
        key={touchedElement ? touchedElement.index : "new"}
        isCreation={modalType === "create"}
        isLocked={isLocked}
        element={elem}
        modalType={modalType}
        additionnalProps={additionnalProps}
        onChange={this.handleChangeElement}
        lang={lang}
        numberIndex={indexAdd}
      />
    );
  };

  render() {
    const {
      draggable,
      createModalTitleFunction,
      editModalTitleFunction,
      restoreModalTitleFunction,
      getErrorMessages,
      error,
      langlabel,
      additionnalProps,
      editOnly,
      lang,
      required,
      viewMode,
      chipTitleTemplate,
      direction,
      showDownloadButton,
      onDownloadListOptions,
      highlightContent,
      ...rest
    } = this.props;

    const {
      elements,
      optionToDelete,
      isWarningModalOpen,
      modalType,
      isCreateEditModalOpen,
      touchedElement,
    } = this.state;

    let isDisabled = true;

    if (getErrorMessages) {
      isDisabled =
        Object.keys(
          getErrorMessages({
            attributes: touchedElement ? clone(touchedElement) : {},
            additionnalProps,
            lang,
            viewMode: modalType === "create" ? "CREATE" : "EDIT",
          })
        ).length > 0
          ? true
          : false;
    } else {
      isDisabled = false;
    }

    const options: TOption[] = _.map(elements, (element, index) => {
      const label = this.prepareChipTitle(chipTitleTemplate, element);
      return {
        key: String(index),
        label: label,
        value: element,
      };
    });

    // Texts
    const label = langlabel ? langlabel.title : "unknown label";
    const tooltip = langlabel ? langlabel.tooltip : undefined;

    const { BulkModal, onBulkImport } = this.props;

    const addOption: any = {
      label: lang.components.inputMultipleCreate.options.addNew,
      onClick: this.handleAddModalOpen,
      ariaLabel: lang.components.inputMultipleCreate.options.addNew,
      icon: <AddIcon />,
    };

    const bulkOption = {
      label: lang.actions.upload,
      onClick: () => this.setState({ isBulkOpen: true }),
      ariaLabel: lang.actions.upload,
      icon: <ImportIcon />,
    };

    const downloadOption = {
      label: lang.actions.download,
      onClick: () =>
        onDownloadListOptions ? onDownloadListOptions(options) : undefined,
      ariaLabel: lang.actions.download,
      icon: <DownloadIcon />,
    };

    const buttonOptions: any[] = [addOption];

    if (BulkModal && onBulkImport) buttonOptions.push(bulkOption);

    if (showDownloadButton) buttonOptions.push(downloadOption);

    const AddOptionButton = () => {
      return (
        <MenuButton
          options={buttonOptions}
          label={
            lang.containers.lists.subCategories.lists.createEditModal
              .inputAttribute.createEditModal.inputOption.title
          }
          color={GreyLight}
          fontColor={black}
          icon={<AddCircleOutline />}
        />
      );
    };

    const dialogTitle = touchedElement
      ? modalType === "create"
        ? (createModalTitleFunction
            ? createModalTitleFunction
            : () => langlabel.createEditModal.createTitle)(touchedElement)
        : modalType === "restore"
        ? (restoreModalTitleFunction
            ? restoreModalTitleFunction
            : () => langlabel.createEditModal.createTitle)(touchedElement)
        : (editModalTitleFunction
            ? editModalTitleFunction
            : () => langlabel.createEditModal.editTitle)(touchedElement)
      : "";

    const onConfirmAction =
      modalType === "create"
        ? this.handleAddElement
        : modalType === "restore"
        ? this.handleRestoreElement
        : this.handleEditElement;

    const confirmBtnText =
      modalType === "create"
        ? lang.modal.add
        : modalType === "restore"
        ? lang.modal.restore
        : lang.modal.save;

    const baseOptions = this.props.disabled
      ? _.map(options, (option) => {
          return { ...option, disabled: this.props.disabled };
        })
      : options;

    return (
      <>
        <InputBaseLayout
          {...rest}
          label={label}
          tooltip={tooltip}
          required={required}
          viewMode={viewMode}
          error={error}
          disabledReason={this.props.disabledReason}
        >
          {onBulkImport && (
            <BulkModal
              isOpen={this.state.isBulkOpen}
              onCloseModal={() => this.setState({ isBulkOpen: false })}
              onConfirmModal={(e: any) => {
                onBulkImport(e);
                this.setState({ isBulkOpen: false });
              }}
              lang={lang}
              langlabel={langlabel}
              labelInput={langlabel}
            />
          )}

          {viewMode === "CREATE" && _.isEmpty(options) ? (
            <InputEmptyContainer>
              <AddOptionButton />
            </InputEmptyContainer>
          ) : (
            <Box display="flex" flexGrow="1" flexDirection="column">
              <BaseOptionsContainer
                options={baseOptions}
                onChangeOptions={this.onChangeOptions}
                direction={direction}
                clickable={viewMode !== "VIEW"}
                deletable={viewMode !== "VIEW"}
                draggable={viewMode !== "VIEW" && draggable}
                onClickOption={this.onClickOption}
                onDeleteOption={editOnly ? undefined : this.onDeleteOption}
                placeholder=""
                error={error}
                highlightContent={highlightContent}
              />

              {viewMode !== "VIEW" && !editOnly && (
                <Box marginTop="12px" alignItems={"start"}>
                  {buttonOptions.map((option, index) => (
                    <Button
                      key={index}
                      onClick={option.onClick}
                      style={{ color: GreyDark, marginRight: "4px" }}
                    >
                      {option.icon}
                      {option.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </InputBaseLayout>

        {langlabel.warningOnRemove ? (
          <CustomDialogWarning
            isOpen={isWarningModalOpen}
            onClose={() =>
              this.setState({ isWarningModalOpen: false, optionToDelete: null })
            }
            onConfirmAction={
              optionToDelete
                ? () => this.handleDeleteOption(optionToDelete)
                : undefined
            }
            lang={{
              title: langlabel.warningOnRemove.title,
              description: langlabel.warningOnRemove.description,
            }}
            rootLang={lang}
          />
        ) : null}

        <CustomDialogForm
          isOpen={isCreateEditModalOpen}
          onClose={this.handleCreateEditModalClose}
          title={dialogTitle}
          onConfirmAction={onConfirmAction}
          confirmBtnText={confirmBtnText}
          isDisabled={isDisabled || this.props.disabled}
          lang={lang}
        >
          {this.prepareCreateEditModalContent()}
        </CustomDialogForm>
      </>
    );
  }
}

/**
 * @legacy: use InputMultipleOptions instead ! (Full example in InputMultipleCreateText)
 */
export default withStyles(styles as any)(InputMultipleCreate);
