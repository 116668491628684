import { makeStyles } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FlagIcon from "@material-ui/icons/Flag";
import _ from "lodash";
import { Marker } from "react-map-gl";

import { White } from "assets/colors";
import UserIcon from "components/Icon/UserIcon";
import IUser from "model/entities/User";
import getFullName from "utils/formatting/getFullName";

import { GEO_TRACKING_CHART, IPopupState } from "../GeoTrackingChart";

const styles = {
  PositionBadge: {
    "border-radius": "50%",
    width: "12px",
    height: "12px",
    border: `solid ${White} 1px`,
  },
  FlagIconStart: {
    color: green[600],
    width: "35px",
    height: "35px",
  },
  FlagIconEnd: {
    color: red[900],
    width: "35px",
    height: "35px",
  },
};

const useStyles = makeStyles(styles);

interface IUserPosititionsLayer {
  data: any;
  users: IUser[];
  userColors: { [key: string]: string };
  selectedUser?: string;
  onChangeSelectedUser: (userId?: string) => void;
  setPopupState: (state: IPopupState | undefined) => void;
}

// TODO: needs refacto (too long, mix of logic and Components, we could use Layers instead for UserBadges ?)
const UserPositionsLayer = ({
  data,
  users,
  userColors,
  selectedUser,
  onChangeSelectedUser,
  setPopupState,
}: IUserPosititionsLayer) => {
  const classes = useStyles();

  return (
    <>
      {_.map(_.keys(data), (uid) => {
        const positionMarkers = [];

        // build the dots for location
        const keysUserLocations = Object.keys(data[uid]).sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        if (keysUserLocations.length > 1 && uid === selectedUser) {
          const firstTime = keysUserLocations[0];
          const lastTime = keysUserLocations[keysUserLocations.length - 1];

          // Start of Route - "Green Flag" Icon
          if (areFiniteCoordinates(data[uid][firstTime])) {
            const { lat, lng } = data[uid][firstTime];
            positionMarkers.push(
              <Marker
                key={`marker-${uid}-start`}
                latitude={lat}
                longitude={lng}
                draggable={false}
                anchor="bottom"
                offset={[24, 16]}
              >
                <div
                  onMouseEnter={() => setPopupState({ uid, time: firstTime })}
                  onMouseLeave={() => setPopupState(undefined)}
                  style={{ pointerEvents: "auto" }}
                >
                  <FlagIcon className={classes.FlagIconStart} />
                </div>
              </Marker>
            );
          }

          // End of Route - "Red Flag" Icon
          if (areFiniteCoordinates(data[uid][lastTime])) {
            positionMarkers.push(
              <Marker
                key={`marker-${uid}-end`}
                latitude={data[uid][lastTime].lat}
                longitude={data[uid][lastTime].lng}
                draggable={false}
                anchor="bottom"
                offset={[24, 16]}
                style={{ zIndex: GEO_TRACKING_CHART.Z_INDEXES.MARKER }}
              >
                <div
                  onMouseEnter={() => setPopupState({ uid, time: lastTime })}
                  onMouseLeave={() => setPopupState(undefined)}
                  style={{ pointerEvents: "auto" }}
                >
                  <FlagIcon className={classes.FlagIconEnd} />
                </div>
              </Marker>
            );
          }
        }

        // All GPS positions have a "Badge"
        for (const time in data[uid]) {
          if (uid === selectedUser && areFiniteCoordinates(data[uid][time])) {
            positionMarkers.push(
              <Marker
                key={`marker-${uid}-${time}`}
                latitude={data[uid][time].lat}
                longitude={data[uid][time].lng}
                draggable={false}
                style={{ zIndex: GEO_TRACKING_CHART.Z_INDEXES.MARKER }}
              >
                <div
                  className={classes.PositionBadge}
                  style={{ background: userColors[uid], pointerEvents: "auto" }}
                  onMouseEnter={() => setPopupState({ uid, time })}
                  onMouseLeave={() => setPopupState(undefined)}
                />
              </Marker>
            );
          }
        }

        const coordinates = Object.values(data[uid])[0] as any;

        if (!areFiniteCoordinates(coordinates)) {
          return null;
        }

        // Once more, check positionMarkers' coordinates are safe to render (can crash the chart otherwise)
        const cleanPositionMarkers = _.compact(
          _.map(positionMarkers, (positionMarker) => {
            const coordinates = {
              lat: positionMarker.props.latitude,
              lng: positionMarker.props.longitude,
            };

            if (!areFiniteCoordinates(coordinates)) {
              return null;
            }

            return positionMarker;
          })
        );

        const fullUser = _.find(users, { id: uid });
        const shouldHighlightUser = !selectedUser || selectedUser === uid;

        const opacity = shouldHighlightUser ? 1 : 0.7;

        return (
          <>
            <div key={uid} style={{ opacity }}>
              {cleanPositionMarkers}

              {areFiniteCoordinates(coordinates) && (
                <Marker
                  latitude={coordinates.lat}
                  longitude={coordinates.lng}
                  draggable={false}
                  style={{ zIndex: GEO_TRACKING_CHART.Z_INDEXES.MARKER }}
                  // TODO: Find a better position to avoid overlapping initial position Marker / flag.
                  // We could also hide the selected user icon, but then we lose the ability to unselect it and if we close the panel we cannot reopen it easily.
                  // => to rework as part of a general UX revamp
                  // offset={[40, 0]}
                >
                  <UserIcon
                    name={getFullName(fullUser)}
                    onClick={() => {
                      onChangeSelectedUser(
                        uid === selectedUser ? undefined : uid
                      );
                    }}
                  />
                </Marker>
              )}
            </div>
          </>
        );
      })}
    </>
  );
};

const areFiniteCoordinates = (coordinates?: { lat?: number; lng?: number }) => {
  return _.isFinite(coordinates?.lat) && _.isFinite(coordinates?.lng);
};

export default UserPositionsLayer;
