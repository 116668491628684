import { useEffect, useState } from "react";

import { Box, Button, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import FileDownload from "@mui/icons-material/FileDownload";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import _ from "lodash";
import { useSelector } from "react-redux";

import IconTitleDescriptionBox from "components/Layout/IconTitleDescriptionBox";
import CustomSpinner from "components/Progress/CustomSpinner";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { allListsSelector } from "containers/lists/redux/selectors";
import { NotFound } from "containers/lists/subcategories/items/CustomerMetaData";
import IPictureInformation from "containers/pictures/model/IPictureInformation";
import {
  downloadSinglePicture,
  getPictureInformations,
} from "containers/pictures/utils";
import { allUsersComposedSelector } from "containers/users/redux/selectors";
import {
  getAllActivities,
  getAllWorkflows,
} from "containers/workflows/redux/selectors";
import useActions from "hooks/useActions";
import useTranslations from "hooks/useTranslations";
import IPicture from "model/entities/Picture";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    textTransform: "capitalize",
  },
  iconBox: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsBox: {
    display: "grid",
    gridRowGap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    paddingBottom: "50px",
  },
}));

export interface IPictureDetailsProps {
  picture: IPicture;
}

function PictureDetails({ picture }: IPictureDetailsProps) {
  const [loading, setLoading] = useState(true);
  const allActivities = useSelector(getAllActivities);
  const allLists = useSelector(allListsSelector);
  const allUsers = useSelector(allUsersComposedSelector);
  const allWorkflows = useSelector(getAllWorkflows);
  const fetchItemsForList = useActions(fetchItemsForListAction);
  const classes = useStyles();
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.lists.createEditModal.inputAttribute
      .templates.customerAttributes;

  const modalLang =
    lang.containers.pictures.subCategories.pictures.createEditModal;

  const reportsLang = lang.containers.workflows.subCategories.activityReports;

  const [pictureInformation, setPictureInformation] = useState<
    IPictureInformation | {}
  >({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const list_id = picture["list_id"];
      if (list_id) await fetchItemsForList(list_id, {});
      const information = getPictureInformations(picture, {
        allActivities,
        allLists,
        allUsers,
        allWorkflows,
      });
      setPictureInformation(information);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picture["id"]]);

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CustomSpinner size={"50px"} />
      </Box>
    );
  }

  const filename = _.last(_.split(pictureInformation["url"], "/"));

  return (
    <Fade in={!loading}>
      <Box className={classes.root} data-testid="picture-details">
        <Box className={classes.iconBox}>
          <Box className={classes.title} title={filename}>
            {filename}
          </Box>
          <Box flexGrow={1}>
            <Button
              startIcon={<FileDownload />}
              color="secondary"
              className={classes.button}
              onClick={() => downloadSinglePicture(pictureInformation["url"])}
              data-testid="download-button"
            >
              {lang.actions.download}
            </Button>
          </Box>
        </Box>

        <Box className={classes.detailsBox}>
          <IconTitleDescriptionBox
            icon={<PersonOutlineOutlinedIcon />}
            title={reportsLang.columns.userName}
            description={pictureInformation["userName"] || <NotFound />}
          />
          {pictureInformation["customerId"] && (
            <IconTitleDescriptionBox
              icon={<StorefrontIcon />}
              title={modalLang.customerId.title}
              description={pictureInformation["customerId"]}
            />
          )}
          {pictureInformation["timestamp"] && (
            <IconTitleDescriptionBox
              icon={<CalendarMonth />}
              title={lang.components.filters.date}
              description={pictureInformation["timestamp"]}
            />
          )}
          {pictureInformation["type"] &&
            pictureInformation["list_id"] === "customer" && (
              <IconTitleDescriptionBox
                icon={<RadioButtonChecked />}
                title={langKey.type}
                description={pictureInformation["type"]}
              />
            )}
          {pictureInformation["questionName"] &&
            pictureInformation["activity_name"] && (
              <IconTitleDescriptionBox
                icon={<HelpOutlineIcon />}
                title={modalLang.questionName.title}
                description={pictureInformation["questionName"]}
              />
            )}
          {pictureInformation["activity_name"] && (
            <IconTitleDescriptionBox
              icon={<AssignmentIcon />}
              title={reportsLang.assignToMe.columns.activityName}
              description={pictureInformation["activity_name"]}
            />
          )}
          {pictureInformation["_location"] &&
            pictureInformation["list_id"] === "customer" && (
              <IconTitleDescriptionBox
                icon={<LocationOn />}
                title={langKey.gps}
                description={pictureInformation["_location"]}
              />
            )}
        </Box>
      </Box>
    </Fade>
  );
}

export default PictureDetails;
