import moment from "moment";

export function getNextDate(
  date: Date,
  interval: "week" | "month" | "year",
  direction: "forward" | "backward" = "forward"
) {
  return moment
    .utc(date)
    .add(direction === "forward" ? 1 : -1, interval)
    .format("YYYY/MM/DD");
}
