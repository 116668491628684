import apiService from "api/apiService";
import { IQuery } from "containers/lists/utils";
import { IServerResponse } from "redux/actions/appActions";

import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";

const MAP_ENDPOINT = "map";

/**
 * Fetch light customer points, without a limit.
 * Optimised in the back-end for large volumes (Customer bases can go up to 250k items).
 */
export type TFetchCustomersLayer = (query?: IQuery) => Promise<
  IServerResponse<{
    customers: ICustomerLocation[];
  }>
>;

export const fetchCustomersLayerApiCall: TFetchCustomersLayer = async (
  query
) => {
  return apiService.get(`${MAP_ENDPOINT}/layers/customers`, query);
};
