import { useState } from "react";

import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import { handleClickContactUs } from "components/Dialog/utils";
import {
  getExpiredReason,
  isClientStatus,
  isClientStatusExpired,
  isLicensePlanStatusCanceled,
} from "containers/authentication/utils/clientStatus";
import useOpenStripeCheckout from "hooks/useOpenStripeCheckout";
import useTranslations from "hooks/useTranslations";
import { CLIENT_STATUS, IClient } from "model/entities/Client";

interface IClientExpiredOverlay {
  client: IClient;
}

// IMPORTANT: sidebar is at 1200
const Z_INDEX = 1201;

function ClientExpiredOverlay({ client }: IClientExpiredOverlay) {
  const lang = useTranslations();

  const [openDowngradeModal, setOpenDowngradeModal] = useState(false);

  const openStripeCheckout = useOpenStripeCheckout({ clientId: client.id });

  if (isClientStatus(client, CLIENT_STATUS.STARTER)) {
    return null;
  }

  const onConfirmDowngrade = async () => {
    /* FOR LATER: once we can safely downgrade a client, we will do so automatically.
     * Currently, this is tricky for several reasons:
     * - STARTER status is tied to FREE_TRIAL DB cluster, but the client could already be in the live DB cluster
     * - Deleting client customisations is tricky (workflows, dashboards, etc.)
     */
    // setIsDowngrading(true);
    // await downgradeToStarter({ clientId: client.id });
    // setIsDowngrading(false);
    // await refreshClientState(client.id);

    handleClickContactUs();
  };

  const trialLang =
    lang.containers.clients.subCategories.clients.createEditModal.trial
      .customMessage;

  const subscriptionLang =
    lang.containers.clients.subCategories.clients.createEditModal.subscription
      .customMessage;

  const expiredReason = getExpiredReason(client);

  const warningLang =
    isClientStatusExpired(client) && expiredReason === "TRIAL_ENDED"
      ? trialLang
      : subscriptionLang;

  if (!isClientStatusExpired(client) && !isLicensePlanStatusCanceled(client)) {
    return null;
  }

  return (
    <>
      {!openDowngradeModal && (
        <CustomDialogWarning
          isOpen
          rootLang={lang}
          hideCloseButton
          cancelBtnTxt={lang.actions.downgrade}
          confirmBtnText={lang.actions.buyLicenses}
          onCancelAction={() => setOpenDowngradeModal(true)}
          onConfirmAction={openStripeCheckout}
          lang={{
            title: warningLang.expiredTitle,
            description: warningLang.expiredDescription,
          }}
          style={{
            zIndex: Z_INDEX,
          }}
        />
      )}

      {openDowngradeModal && (
        <CustomDialogWarning
          isOpen
          rootLang={lang}
          hideCloseButton
          cancelBtnTxt={subscriptionLang.downgradeToStarter}
          confirmBtnText={subscriptionLang.keepFeatures}
          onClose={() => {}}
          onCancelAction={onConfirmDowngrade}
          onConfirmAction={openStripeCheckout}
          // disableCancel={isDowngrading}
          // disableConfirm={isDowngrading}
          lang={{
            title: subscriptionLang.downgradeTitle,
            description: warningLang.downgradeDescription,
          }}
          style={{
            zIndex: Z_INDEX + 1,
          }}
        />
      )}

      {/* <SnackbarMessage isOtherActionOngoing={isDowngrading} lang={lang} /> */}
    </>
  );
}

export default ClientExpiredOverlay;
