import { IFileInformation } from "components/Input/LegacyInput/UploadFileInput/UploadFileInput";
import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import { LANG_ACTIONS, SUB_CATEGORIES, TLang } from "model/application/Lang";
import { DOCUMENT_SCOPE } from "model/entities/Document";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import * as notifyLevels from "../../notifications/actionLevels";
import * as notifyTypes from "../../notifications/actionTypes";
import {
  changeFolderSuccessActionCreator,
  changeSubcategorySuccessActionCreator,
  fetchDocumentsBeginAction,
  fetchDocumentsFailureAction,
  fetchDocumentsSuccessAction,
  updateDocumentBeginActionCreator,
  updateDocumentFailureActionCreator,
  updateDocumentSuccessActionCreator,
  uploadDocumentsBeginAction,
  uploadDocumentsFailureAction,
  uploadDocumentsuccessAction,
} from "./actionCreators";
import {
  deleteDocumentsRequest,
  fetchDocumentsForClientApiCall,
  updateDocumentRequest,
  uploadDocumentsRequest,
} from "./api";

type IFetchDocumentsForClientActionFunc = (
  clientId: string
) => IDispatchAndGetState<void>;
export const fetchDocumentsForClientAction: IFetchDocumentsForClientActionFunc =
  (clientId) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(fetchDocumentsBeginAction());

      try {
        const documents = await fetchDocumentsForClientApiCall();
        dispatch(ajaxSuccessAction());
        dispatch(fetchDocumentsSuccessAction(clientId, documents.data.data));
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "fetchDocumentsError",
          error as any,
          fetchDocumentsFailureAction,
          currLang
        );
      }
    };
  };

type IUploadDocumentsForClientActionFunc = (
  actionName: string,
  clientId: string,
  files: Blob[],
  fileInformations: IFileInformation[],
  teams: string[],
  scope: DOCUMENT_SCOPE,
  folder: string
) => IDispatchAndGetState<void>;
export const uploadDocumentsForClientAction: IUploadDocumentsForClientActionFunc =
  (
    actionName: string,
    _clientId,
    files,
    fileInformations,
    teams,
    scope,
    folder
  ) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(uploadDocumentsBeginAction());

      try {
        for await (const [i, file] of files.entries()) {
          const fileInformation = fileInformations[i];
          const res = await uploadDocumentsRequest(
            actionName,
            file,
            fileInformation.fileName,
            teams,
            scope,
            folder
          );
          extractDataAndCheckErrorStatus(res);
        }
        dispatch(ajaxSuccessAction());
        dispatch(uploadDocumentsuccessAction());
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.DOCUMENT
            )
          )
        );
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "uploadDocumentsError",
          error as any,
          uploadDocumentsFailureAction,
          currLang
        );
      }
    };
  };
type IUpdateDocumentForClientActionFunc = (
  actionName: string,
  id: string,
  oldName: string,
  doc: {
    fileName: string;
    teams: string[];
    scope: DOCUMENT_SCOPE;
    folder: string;
  }
) => IDispatchAndGetState<void>;

export const updateDocumentForClientAction: IUpdateDocumentForClientActionFunc =
  (
    actionName: string,
    id: string,
    oldName: string,
    doc: {
      fileName: string;
      teams: string[];
      scope: DOCUMENT_SCOPE;
      folder: string;
    }
  ) => {
    return (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(updateDocumentBeginActionCreator());
      return new Promise(function (resolve, reject) {
        updateDocumentRequest(actionName, id, doc)
          .then((serverResponse) => {
            const data = extractDataAndCheckErrorStatus(serverResponse);
            const { document } = data;
            dispatch(ajaxSuccessAction());
            dispatch(updateDocumentSuccessActionCreator(document));
            dispatch(
              showNotificationActionCreator(
                notifyTypes.NOTIFICATION_SUCCESS,
                notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
                getSuccessNotificationMessage(
                  currLang,
                  LANG_ACTIONS.EDIT,
                  SUB_CATEGORIES.DOCUMENT,
                  oldName
                )
              )
            );
            resolve();
          })
          .catch((error) => {
            treatErrorNotification(
              dispatch,
              "UpdateDocumentError",
              error,
              updateDocumentFailureActionCreator,
              currLang
            );
            reject(error);
          });
      });
    };
  };
export type ChangeSubcategoryActionFunc = (
  subcategory: "test02" | "test02"
) => IDispatchAndGetState<void>;
export type IChangeSubcategoryActionFunc = (
  subcategory: string
) => IDispatchAndGetState<void>;

export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeSubcategorySuccessActionCreator(subcategory))
    );
  };
};
export type IChangeFolderActionFunc = (
  folderSelected: string
) => IDispatchAndGetState<void>;

export const changeFolderAction: IChangeFolderActionFunc = (folderSelected) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeFolderSuccessActionCreator(folderSelected))
    );
  };
};

type IDeleteDocumentsForClientActionFunc = (
  actionName: string,
  documentId: string
) => IDispatchAndGetState<void>;
export const deleteDocumentsForClientAction: IDeleteDocumentsForClientActionFunc =
  (actionName: string, documentId) => {
    return async (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];
      dispatch(ajaxRequestAction());
      dispatch(uploadDocumentsBeginAction());

      try {
        await deleteDocumentsRequest(actionName, documentId);
        dispatch(ajaxSuccessAction());
        dispatch(uploadDocumentsuccessAction());
        dispatch(
          showNotificationActionCreator(
            notifyTypes.NOTIFICATION_SUCCESS,
            notifyLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.DOCUMENT
            )
          )
        );
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "uploadDocumentsError",
          error as any,
          uploadDocumentsFailureAction,
          currLang
        );
      }
    };
  };

export interface IDocumentsActions {
  fetchDocumentsForClientAction: IFetchDocumentsForClientActionFunc;
  uploadDocumentsForClientAction: IUploadDocumentsForClientActionFunc;
  deleteDocumentsForClientAction: IDeleteDocumentsForClientActionFunc;
  updateDocumentForClientAction: IUpdateDocumentForClientActionFunc;
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
  changeFolderAction: IChangeFolderActionFunc;
}

const actions: IDocumentsActions = {
  fetchDocumentsForClientAction,
  uploadDocumentsForClientAction,
  deleteDocumentsForClientAction,
  updateDocumentForClientAction,
  changeSubcategoryAction,
  changeFolderAction,
};

export default actions;
