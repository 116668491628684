import { makeStyles } from "@material-ui/core";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WorkIcon from "@mui/icons-material/Work";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";

import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";

import styles from "./styles";

const useStyles = makeStyles(styles);

const EmptyTable = ({ searchFilter, searchTerm = "search term", onClear }) => {
  const classes = useStyles();
  const location = useLocation();
  const lang = useTranslations();

  const buildEmptyData = () => {
    const langValue = lang.components.emptyTable;

    if (searchFilter) {
      if (searchFilter === "search") {
        return (
          <>
            <SearchIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {formatString(langValue.searchFilter.title, [
                '"' + searchTerm + '"',
              ])}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.searchFilter.search.description}
            </div>
          </>
        );
      }

      if (searchFilter === "filter") {
        return (
          <>
            <FilterAltOutlinedIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {formatString(langValue.searchFilter.title, [
                "the filters you have set",
              ])}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.searchFilter.filter.description}
            </div>

            <Button
              className={classes.ClearFilterButton}
              variant="contained"
              onClick={onClear}
            >
              <span>{langValue.searchFilter.filter.clearButton.ALL} </span>
              <span className="material-icons-outlined">refresh</span>
            </Button>
          </>
        );
      }
    } else {
      if (location.pathname.includes("/documents")) {
        return (
          <>
            <AssignmentOutlinedIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {" "}
              {formatString(langValue.title, ["documents"])}{" "}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.documents.description}
            </div>
          </>
        );
      }

      if (location.pathname.includes("/dashboards")) {
        return (
          <>
            <EqualizerIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {" "}
              {formatString(langValue.title, ["dashboards"])}{" "}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.dashboards.description}
            </div>
          </>
        );
      }

      if (location.pathname.includes("/users")) {
        return (
          <>
            <PeopleAltOutlinedIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {location.pathname.includes("/webuser")
                ? formatString(langValue.title, ["web user"])
                : formatString(langValue.title, ["mobile user"])}
            </div>
            <div className={classes.EmptyDescription}>
              {location.pathname.includes("/webuser")
                ? formatString(langValue.users.description, ["web user"])
                : formatString(langValue.users.description, ["mobile user"])}
            </div>
          </>
        );
      }

      if (location.pathname.includes("/teams")) {
        return (
          <>
            <GroupWorkIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {" "}
              {formatString(langValue.title, ["teams"])}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.teams.description}
            </div>
          </>
        );
      }

      if (location.pathname.includes("/orders")) {
        return (
          <>
            <ShoppingCartOutlinedIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {" "}
              {formatString(langValue.title, ["orders"])}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.orders.description}
            </div>
          </>
        );
      }

      if (location.pathname.includes("/lists")) {
        if (/^(\/lists\/).*\w/.test(location.pathname)) {
          return (
            <>
              <span
                className="material-icons-outlined"
                style={{ fontSize: "66px" }}
              >
                playlist_add
              </span>{" "}
              <div style={{ fontWeight: 600, fontSize: "20px" }}>
                {
                  lang.containers.lists.subCategories.items.createEditModal
                    .itemsTable.customMessage.noItemsInTableTitle
                }
              </div>
              <div className={classes.EmptyDescription}>
                {langValue.lists.items.description}
              </div>
            </>
          );
        }
        return (
          <>
            <FormatListBulletedIcon sx={{ width: "66px", height: "66px" }} />
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {" "}
              {formatString(langValue.title, ["lists"])}
            </div>
            <div className={classes.EmptyDescription}>
              {langValue.lists.description}
            </div>
          </>
        );
      }
      if (location.pathname.includes("/workflows")) {
        return (
          <>
            <WorkIcon sx={{ width: "66px", height: "66px" }} />
            {location.pathname.includes("/activities") && (
              <>
                <div style={{ fontWeight: 600, fontSize: "20px" }}>
                  {" "}
                  {
                    lang.containers.workflows.subCategories.activities
                      .createEditModal.activitiesTable.customMessage
                      .noActivitiesFoundTitle
                  }
                </div>
                <div className={classes.EmptyDescription}>
                  {
                    lang.containers.workflows.subCategories.activities
                      .createEditModal.activitiesTable.customMessage
                      .noActivitiesFoundDescription
                  }
                </div>
              </>
            )}
            {location.pathname.includes("/jobs") && (
              <>
                <div style={{ fontWeight: 600, fontSize: "20px" }}>
                  {" "}
                  {formatString(langValue.title, ["jobs"])}
                </div>
                <div className={classes.EmptyDescription}>
                  {
                    lang.containers.jobs.subCategories.jobs.createEditModal
                      .notFound
                  }
                </div>
              </>
            )}

            {location.pathname.includes("/triggers") && (
              <>
                <div style={{ fontWeight: 600, fontSize: "20px" }}>
                  {" "}
                  {formatString(langValue.title, ["triggers"])}
                </div>
                <div className={classes.EmptyDescription}>
                  {
                    lang.containers.triggers.subCategories.triggers
                      .createEditModal.notFound
                  }
                </div>
              </>
            )}
            {location.pathname.includes("/workflows") &&
              !location.pathname.includes("/triggers") &&
              !location.pathname.includes("/jobs") &&
              !location.pathname.includes("/activities") && (
                <>
                  <div style={{ fontWeight: 600, fontSize: "20px" }}>
                    {" "}
                    {formatString(langValue.title, ["workflows"])}
                  </div>
                  <div className={classes.EmptyDescription}>
                    {
                      lang.containers.workflows.subCategories.workflowReports
                        .createEditModal.workflowsTable.customMessage
                        .noWorkflowsFoundDescription
                    }
                  </div>
                </>
              )}
          </>
        );
      }
      if (location.pathname.match(".*/workflows/(.+)")) {
        return (
          <div>
            <span
              className="material-icons-outlined"
              style={{ fontSize: "66px" }}
            >
              assignment
            </span>

            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {langValue.workflows.submissions.title}
            </div>

            <div
              style={{
                marginTop: "5px",
                fontSize: "16px",
                fontWeight: 500,
                whiteSpace: "pre-line",
              }}
            >
              {langValue.workflows.submissions.description}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <Box
      sx={{
        color: "#6c6c6c",
        margin: "64px",
      }}
    >
      {buildEmptyData()}
    </Box>
  );
};

export default EmptyTable;
