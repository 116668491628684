import React, { useState } from "react";

import Box from "@mui/material/Box";

import ToggleSwitch from "components/Switch/ToggleSwitch";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputLabel from "../InputLabel/InputLabel";

export interface IInputBooleanProps extends IInputBaseLayout {
  name: string;
  error?: string;
  lang?: {
    title?: string;
    tooltip?: string;
  };
  defaultValue?: boolean;
  onChange: (value: boolean, attrName: string) => void;
  isUndetermined?: boolean;
  viewMode?: TViewMode;
  required?: boolean;
  disabled?: boolean;
  viewStacked?: boolean;
}

export type TInputBoolean = React.FunctionComponent<IInputBooleanProps>;

export const InputBoolean: TInputBoolean = ({
  name,
  error,
  defaultValue,
  isUndetermined,
  lang,
  onChange,
  viewMode = "CREATE",
  required = false,
  viewStacked = false,
  disabled,
  highlightContent = false,

  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: any) => {
    const value = event.target.checked;
    setValue(value);
    onChange(value, name);
  };

  if (viewMode === "CREATE") {
    return (
      <Box
        display={"flex"}
        padding="8px 0px 24px 0px" // same as in InputBaseLayout
        columnGap={required || lang?.tooltip ? "50px" : "24px"}
        alignItems="center"
        data-testid={rest.dataTestId}
      >
        <InputLabel
          title={lang?.title}
          tooltip={viewMode === "CREATE" ? lang?.tooltip : undefined}
          required={required}
          viewMode={viewMode}
          error={error}
        />
        <Box sx={{ opacity: disabled ? `0.5 !important` : undefined }}>
          <ToggleSwitch
            name={name}
            defaultChecked={isUndetermined ? false : value}
            disabled={disabled}
            onChange={handleChange}
          />
        </Box>
      </Box>
    );
  }

  return (
    <InputBaseLayout
      disabled={disabled}
      {...rest}
      highlightContent={highlightContent}
      tooltip={lang?.tooltip}
      label={lang?.title}
      required={required}
      viewMode={viewMode}
      error={error}
      viewStacked={viewStacked}
    >
      <ToggleSwitch
        name={name}
        defaultChecked={isUndetermined ? false : value}
        onChange={handleChange}
        disabled={disabled ?? viewMode === "VIEW"}
      />
    </InputBaseLayout>
  );
};

export default InputBoolean;
