import { IOption } from "model/application/components";

export type TColumnType = {
  name: string;
  type?: TABLE_COLUMN_TYPE;
  options?: IOption[]; // Can be useful to known possible options for filters on columns with type SINGLE_CHOICE, MULTIPLE_CHOICE, etc.
  label: string;
  aggregator?: TABLE_AGGREGATOR;
  hidden?: boolean;
  sticky?: boolean;
  unsortable?: boolean; //by default the column is unsortable = false
};

export type TRowType = {
  [columnName: string]: any;
};

export type TCellType = {
  value?: any;
  type?: TABLE_COLUMN_TYPE;
  sticky?: boolean;
};

export type TCategoryType = IOption<string>;

export type TDataFormatted = {
  [something: string]: any;
};

export enum TABLE_AGGREGATOR {
  SUM = "SUM",
  MEAN = "MEAN",
  MIN = "MIN",
  MAX = "MAX",
  NONE = "NONE",
  BOOLEAN = "BOOLEAN",
}

export enum TABLE_COLUMN_TYPE {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  SINGLE_CHOICE_ON_LIST = "SINGLE_CHOICE_ON_LIST",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  MULTIPLE_CHOICE_ON_LIST = "MULTIPLE_CHOICE_ON_LIST",
  MULTIPLE_INPUT_TEXT = "MULTIPLE_INPUT_TEXT",
  MULTIPLE_INPUT_INTEGER = "MULTIPLE_INPUT_INTEGER",
  MULTIPLE_INPUT_DECIMAL = "MULTIPLE_INPUT_DECIMAL",
  MATRIX_ON_LIST = "MATRIX_ON_LIST",
  RATING = "RATING",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE_TIME",
  DATE = "DATE",
  TIME = "TIME",
  GPS = "GPS",
  PICTURE = "PICTURE",
  SIGNATURE = "SIGNATURE",
  SWITCH = "SWITCH",
  NONE = "NONE",
  LINK = "LINK",
  PERCENTAGE = "PERCENTAGE",
  PROGRESS = "PROGRESS",
  STATUS = "STATUS",
  STEPS = "STEPS",
  UPLOAD = "UPLOAD",
  BOOLEAN = "BOOLEAN",
  SKU = "SKU",
  USER = "USER",
  ACTION = "ACTION",
  CUSTOM = "CUSTOM",
}

export type TRowAction = (i: string) => void;

export type TTableLayout = "auto" | "fixed";
