import dateFormat from "dateformat";
import { TERRITORY_SCOPE } from "fieldpro-tools";
import { FeatureCollection } from "geojson";
import _ from "lodash";
import { Dispatch } from "redux";
import tokml from "tokml";

import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import { NOTIFICATION_LEVEL_SUCCESS } from "containers/notifications/actionLevels";
import { NOTIFICATION_SUCCESS } from "containers/notifications/actionTypes";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import {
  CUSTOM_FIELD_TYPE,
  IList,
  IListSchema,
  LIST_SCOPE,
} from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import ITeam from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";
import getFullName from "utils/formatting/getFullName";
import { isEmptyValue } from "utils/isEmptyValue";

import { formatAttribute } from "./utils/exports/formatAttribute";

export const mustShowArchivedItems = (query?: { [key: string]: any }) => {
  return (
    _.isArray(query?.["_archived"]) && _.includes(query?.["_archived"], true)
  );
};

export const mustShowActiveItems = (query?: { [key: string]: any }) => {
  return (
    _.isArray(query?.["_archived"]) && _.includes(query?.["_archived"], false)
  );
};

export interface IQuery {
  [key: string]: any;
}

// TODO: what is the difference with formatBackendQuery / getBackendQuery ?
const formatFilterForBackend = (filter: IQuery, schema: IListSchema[]) => {
  const result: IQuery = {};

  // Check archived filter
  if (mustShowArchivedItems(filter)) {
    result["_archived"] = filter["_archived"];
  }

  _.keys(filter).forEach((att) => {
    if (
      att === "_displayed_name" ||
      att === "_owners" ||
      _.includes(["_last_visit_date", "_created_at", "_update_at"], att)
    ) {
      result[att] = filter[att];
      return;
    }

    if (att === "item_id" || att === "_id") {
      result["_id"] = filter[att];
      return;
    }

    if (att === "startCreationDate") {
      _.set(result, "_created_at.start", (filter[att] as Date).toISOString());
      return;
    }

    if (att === "endCreationDate") {
      _.set(result, "_created_at.end", (filter[att] as Date).toISOString());
      return;
    }

    if (att === "startUpdatedDate") {
      _.set(result, "_updated_at.start", (filter[att] as Date).toISOString());
      return;
    }

    if (att === "endUpdatedDate") {
      _.set(result, "_updated_at.end", (filter[att] as Date).toISOString());
      return;
    }

    const schemaAtt = schema.find((s) => s.column_tag === att);
    if (schemaAtt) {
      switch (schemaAtt.type) {
        case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE: {
          result[att] = filter[att].map((o: IOption) => ({ key: o.key }));
          break;
        }
        default: {
          result[att] = filter[att];
        }
      }
    }
  });
  return result;
};

type TItemRawData = string[][];

interface IExcelHeader {
  columnTagRow: string[];
  columnNameRow: string[];
  columnRequiredRow: string[];
  columnTypeRow: string[];
  columnOptionRow: string[];
  columnValidationRow: string[];
  columnValidationTextRow: string[];
  columnIsHiddenRow: string[];
}

const getNonCustomFields = (
  listId: string,
  scope?: LIST_SCOPE,
  lang?: TLang
) => {
  const isCustomerList = listId === "customer";
  const showTeamName = _.includes(
    [
      LIST_SCOPE.TEAM,
      LIST_SCOPE.SINGLE_TEAM,
      LIST_SCOPE.MOBILE_USER,
      LIST_SCOPE.SINGLE_MOBILE_USER,
    ],
    scope
  );

  const fields = [
    {
      column_tag: "_active",
      column_name: "Active",
    },
    {
      column_name: "Owners Ids",
      column_tag: "_owners_ids",
    },
    {
      column_name: "Owners Names",
      column_tag: "_owners_names",
    },
    ...(isCustomerList && showTeamName
      ? [
          {
            column_tag: "_team_names",
            column_name:
              lang?.containers.lists.subCategories.items.bulkDownload.column[
                "_team_names"
              ], //"Team names",
          },
        ]
      : []),
    {
      column_tag: "_created_at",
      column_name: "Created at",
    },
    {
      column_tag: "_updated_at",
      column_name: "Updated at",
    },
    {
      column_tag: "_created_source",
      column_name: "Created source",
    },
    {
      column_tag: "_updated_source",
      column_name: "Updated source",
    },
    {
      column_tag: "_created_by",
      column_name: "Created by",
    },
    {
      column_tag: "_updated_by",
      column_name: "Updated by",
    },
  ];
  if (isCustomerList) {
    fields.push(
      ...[
        {
          column_tag: "_last_visit_date",
          column_name: "Last visit date",
        },
        {
          column_tag: "_last_visit_user_id",
          column_name: "Last visit user id",
        },
        {
          column_tag: "_last_visit_user_name",
          column_name: "Last visit user name",
        },
      ]
    );
  }
  return fields;
};

//Columns
export function getSchemaTableColumns(): IExcelHeader {
  const columnTagRow = ["_id", "shortname"];
  const columnNameRow = ["", "name"];
  const columnRequiredRow = ["", "required"];
  const columnTypeRow = ["", "type"];
  const columnOptionRow = ["", "option"];
  const columnValidationRow = ["", "validation"];
  const columnValidationTextRow = ["", "validation_text"];
  const columnIsHiddenRow = ["", "is_hidden"];

  return {
    columnTagRow,
    columnNameRow,
    columnRequiredRow,
    columnTypeRow,
    columnOptionRow,
    columnValidationRow,
    columnValidationTextRow,
    columnIsHiddenRow,
  };
}

export const getItemsTableColumns = (
  fullSchema: IListSchema[],
  listId: string,
  lang: TLang,
  scope?: LIST_SCOPE
) => {
  const columnTags = _.concat(
    [
      {
        column_name: "ID",
        column_tag: "_id",
      },
    ],
    _.map(fullSchema, (full) => {
      return {
        column_tag: full.column_tag,
        column_name: full.column_name,
      };
    }),

    _.map(getNonCustomFields(listId, scope, lang), (full) => {
      return {
        column_tag: full.column_tag,
        column_name: full.column_name,
      };
    })
  );

  return {
    tags: _.map(columnTags, (c) => c.column_tag),
    names: _.map(columnTags, (c) => c.column_name),
  };
};

//Utils
export function addNColumns(
  rows: string[],
  listScope: LIST_SCOPE,
  additionnal?: number
) {
  let addN = 0;
  if (listScope === LIST_SCOPE.GLOBAL) addN = 2;
  else addN = 3;

  if (additionnal) {
    addN += additionnal;
  }

  function addEmptyStrings(n: number) {
    return _.fill(Array(n), "");
  }

  return [...rows, ...addEmptyStrings(addN)];
}

export function secureContent(content: string) {
  return '"' + content + '"';
}

export function getTextValue(text: string) {
  return _.includes(text, ",") ? secureContent(text) : text;
}

//Items calcul
export function getColumnsTagAndNamesWithListScope(
  columnsTags: string[],
  columnNames: string[],
  scope: LIST_SCOPE
) {
  const newColumnsTag = [...columnsTags, "_active"];
  const newColumnsName = [...columnNames, "Active"];

  switch (scope) {
    case LIST_SCOPE.TEAM:
    case LIST_SCOPE.SINGLE_TEAM:
      {
        newColumnsTag.push("_team_ids");
        newColumnsTag.push("_team_names");
        newColumnsName.push("Team ids");
        newColumnsName.push("Team names");
      }
      break;

    case LIST_SCOPE.MOBILE_USER:
    case LIST_SCOPE.SINGLE_MOBILE_USER:
      {
        newColumnsTag.push("_mobile_users_ids");
        newColumnsTag.push("_mobile_users_names");
        newColumnsName.push("Mobile user ids");
        newColumnsName.push("Mobile user names");
      }
      break;

    default:
      break;
  }

  return { firstRow: newColumnsTag, secondRow: newColumnsName };
}

export const getLocationValue = (item: IListItem, tag: string) => {
  const lng = item[tag] ? item[tag]["lng"] : "";
  const lat = item[tag] ? item[tag]["lat"] : "";
  const acc = item[tag] ? item[tag]["acc"] : "";
  const filterEmptyValue = _.filter(
    _.split(`${lat},${lng},${acc}`, ","),
    (p) => !_.isEmpty(_.trim(p))
  );
  return !_.isEmpty(filterEmptyValue) ? _.join(filterEmptyValue, ",") : "";
};

export function getOptionValueByAttributesType(
  type: CUSTOM_FIELD_TYPE,
  options?: IOption[]
) {
  const condition =
    _.includes(
      [CUSTOM_FIELD_TYPE.SINGLE_CHOICE, CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE],
      type
    ) && options;

  if (!condition) {
    return "";
  }

  if (!options) {
    return "";
  }

  let optDefinition = "";
  _.forEach(options, (opt: IOption, i: number) => {
    if (options && i === options.length - 1) {
      optDefinition = optDefinition + opt.key + ":" + opt.label;
    } else {
      optDefinition = optDefinition + opt.key + ":" + opt.label + ",";
    }
  });
  return optDefinition;
}

const getOwnerNames = (teams?: ITeam[], teamsOwnerIds?: string[]) => {
  const ownersFound = _.filter(teams, (team) =>
    _.includes(teamsOwnerIds, team.id)
  );
  const owners = _.map(ownersFound, (owner) => owner.name).join(", ");
  return owners;
};

const getTeamNamesByItemOwners = (
  teams: ITeam[],
  item: IListItem,
  scope: LIST_SCOPE
) => {
  if (_.includes([LIST_SCOPE.TEAM, LIST_SCOPE.SINGLE_TEAM], scope)) {
    return getOwnerNames(teams, item["_owners"]);
  }

  if (
    _.includes([LIST_SCOPE.MOBILE_USER, LIST_SCOPE.SINGLE_MOBILE_USER], scope)
  ) {
    const owners = _.isArray(item["_owners"])
      ? item["_owners"]
      : _.compact([item["_owners"]]);

    const teamsofMobileUsers = _.filter(teams, (team) => {
      const intersection = _.intersection(team.mobile_users, owners);

      return !_.isEmpty(intersection);
    });

    return _.map(teamsofMobileUsers, "name").join(", ");
  }

  return "";
};

export function getOwnerNameByListScope(
  scope: LIST_SCOPE,
  item: IListItem,
  additionnalParams: {
    teams?: ITeam[];
    mobileUsers?: IMobileUser[];
  }
) {
  const { teams, mobileUsers } = additionnalParams || {};
  const condition = item.hasOwnProperty("_owners") && item._owners;

  if (!condition) {
    return "";
  }

  if (_.includes([LIST_SCOPE.TEAM, LIST_SCOPE.SINGLE_TEAM], scope)) {
    return getOwnerNames(teams, item._owners);
  }

  if (
    _.includes([LIST_SCOPE.MOBILE_USER, LIST_SCOPE.SINGLE_MOBILE_USER], scope)
  ) {
    const ownersFound =
      _.filter(mobileUsers, (user) => _.includes(item._owners, user.id)) || [];
    const owners =
      _.map(ownersFound, (owner) =>
        getFullName({
          first_name: owner?.first_name,
          last_name: owner?.last_name,
        })
      ).join(", ") || "";
    return owners;
  }

  return "";
}

export function getValueWhenTagIsNotInColumnTags(
  item: IListItem,
  attr: string
) {
  if (_.isObject(item) && _.has(item, attr)) {
    return _.toString(item[attr]);
  }
  return "";
}

export function getUserNameWithSource(
  item: IListItem,
  tag: string,
  additionnalParams: {
    mobileUsers?: IMobileUser[];
    webUsers?: IWebUser[];
    teams?: ITeam[];
  }
) {
  const { mobileUsers, webUsers } = additionnalParams || {};

  if (item[tag] === "sa@optimetriks.com") {
    return "Super admin";
  }

  if (_.includes(item[tag], "optimetriks.com")) {
    return item[tag];
  }

  const source = _.split(tag, "_by")[0] + "_source";
  if (!item[source]) return "";

  if (item[source] === "WEB") {
    const user = _.find(webUsers, { id: item[tag] });

    return getFullName({
      first_name: user?.first_name,
      last_name: user?.last_name,
      middle_name: user?.middle_name,
    });
  }

  if (item[source] === "MOBILE") {
    const user = _.find(mobileUsers, { id: item[tag] });
    return getFullName({
      first_name: user?.first_name,
      last_name: user?.last_name,
      middle_name: user?.middle_name,
    });
  }

  return _.get(item, tag, "");
}

export function getCreatedUpdatedActiveValue(
  item: IListItem,
  tag: string,
  additionnalParams: {
    mobileUsers?: IMobileUser[];
    webUsers?: IWebUser[];
    teams?: ITeam[];
  }
) {
  if (_.endsWith(tag, "_at")) {
    return dateFormat(new Date(item[tag]), "dd/mm/yyyy");
  }

  if (_.endsWith(tag, "_by")) {
    return getUserNameWithSource(item, tag, additionnalParams);
  }

  return _.get(item, tag, "");
}

export function getCell(
  item: IListItem,
  schemas: IListSchema[],
  attr: string,
  additionnalParams: {
    mobileUsers?: IMobileUser[];
    webUsers?: IWebUser[];
    teams?: ITeam[];
  },
  listId: string
) {
  if (isEmptyValue(item[attr])) {
    return "";
  }

  if (
    _.includes(
      _.map(getNonCustomFields(listId), (c) => c.column_tag),
      attr
    )
  ) {
    return getCreatedUpdatedActiveValue(item, attr, additionnalParams);
  }

  const schemaFound = _.find(schemas, { column_tag: attr });
  if (!schemaFound) {
    return getValueWhenTagIsNotInColumnTags(item, attr);
  }

  return formatAttribute(item, attr, schemaFound) || "";
}

//schema & items tables
export function getItemsRowsToExcelFile(
  items: IListItem[],
  teams: ITeam[],
  mobileUsers: IMobileUser[],
  webUsers: IWebUser[],
  columnTags: string[],
  columnLabels: string[],
  schemas: IListSchema[],
  scope: LIST_SCOPE,
  listId: string
): TItemRawData {
  const rows: string[][] = [];

  _.forEach(items, (item: IListItem) => {
    const itemRow: string[] = [];

    _.forEach(columnTags, (column: string) => {
      let cell;
      if (column === "_owners_names") {
        cell = getOwnerNameByListScope(scope, item, {
          teams,
          mobileUsers,
        });
      } else if (column === "_owners_ids") {
        cell = _.isArray(item["_owners"])
          ? _.join(item["_owners"], ",")
          : item[column] ?? "";
      } else if (column === "_team_names") {
        cell = getTeamNamesByItemOwners(teams, item, scope);
      } else {
        cell = getCell(
          item,
          schemas,
          column,
          {
            teams,
            mobileUsers,
            webUsers,
          },
          listId
        );
      }

      itemRow.push(cell);
    });

    rows.push(itemRow);
  });

  return [_.map(columnLabels, (label) => _.capitalize(label))].concat(rows);
}

export function getSchemaRowsToExcelFile(
  schemas: IListSchema[],
  list: IList,
  columns: IExcelHeader
): {
  schemaTable: TItemRawData;
  columnTags: string[];
  columnNames: string[];
} {
  const rows: TItemRawData = [];
  const {
    columnTagRow,
    columnNameRow,
    columnRequiredRow,
    columnTypeRow,
    columnOptionRow,
    columnValidationRow,
    columnValidationTextRow,
    columnIsHiddenRow,
  } = columns;

  //Add schemas
  _.forEach(schemas, (schema: IListSchema) => {
    columnNameRow.push(getTextValue(schema.column_name));
    columnTagRow.push(schema.column_tag);
    columnRequiredRow.push(schema.required ? "yes" : "no");
    columnTypeRow.push(schema.type);
    columnOptionRow.push(
      getOptionValueByAttributesType(schema.type, schema.options)
    );
    columnValidationRow.push(schema.validation ? schema.validation : "");
    columnValidationTextRow.push(
      schema.validation_text ? schema.validation_text : ""
    );
    columnIsHiddenRow.push(schema.hasOwnProperty("is_hidden") ? "no" : "yes");
  });

  //Additionnal column
  const { firstRow, secondRow } = getColumnsTagAndNamesWithListScope(
    columnTagRow,
    columnNameRow,
    list.scope
  );
  rows.push(firstRow);
  rows.push(secondRow);
  rows.push(addNColumns(columnRequiredRow, list.scope));
  rows.push(addNColumns(columnTypeRow, list.scope));
  rows.push(addNColumns(columnOptionRow, list.scope));
  rows.push(addNColumns(columnValidationRow, list.scope));
  rows.push(addNColumns(columnValidationTextRow, list.scope));
  rows.push(addNColumns(columnIsHiddenRow, list.scope));

  return {
    columnTags: firstRow,
    columnNames: secondRow,
    schemaTable: _.clone(rows),
  };
}

//Excel sheet
export const getExcelSheetWithList = (
  list: IList,
  teams: ITeam[],
  mobileUsers: IMobileUser[],
  webUsers: IWebUser[],
  bulk: boolean,
  lang: TLang
) => {
  const listSchema = bulk
    ? list.schema
    : list.schema.filter((sch) => list.important?.includes(sch.column_tag));

  //schemas
  const fullSchema = [
    {
      column_name: "Displayed name",
      column_tag: "_displayed_name",
      type: CUSTOM_FIELD_TYPE.TEXT,
    } as IListSchema,
  ].concat(_.sortBy(listSchema, (schema) => schema.index));

  //columns
  const excelColumns = getSchemaTableColumns();

  //schema Table
  const { schemaTable } = getSchemaRowsToExcelFile(
    fullSchema,
    list,
    excelColumns
  );

  const { tags, names } = getItemsTableColumns(
    fullSchema,
    list.id,
    lang,
    list.scope
  );
  const itemsTable = getItemsRowsToExcelFile(
    list.items,
    teams,
    mobileUsers,
    webUsers,
    tags,
    names,
    fullSchema,
    list.scope,
    list.id
  );

  return [schemaTable, itemsTable];
};

const getAllowedOwnerOptions = (
  scope: LIST_SCOPE | TERRITORY_SCOPE | undefined,
  mobileUsers: IMobileUser[],
  teams: ITeam[]
) => {
  if (!scope) {
    return [];
  }

  const listScope = scope || LIST_SCOPE.GLOBAL;

  if (
    listScope === LIST_SCOPE.TEAM ||
    listScope === LIST_SCOPE.SINGLE_TEAM ||
    listScope === TERRITORY_SCOPE.TEAM
  ) {
    return teams
      .filter((t) => t.active)
      .map((t) => {
        return { key: t.id, label: t.name };
      });
  }

  if (
    listScope === LIST_SCOPE.MOBILE_USER ||
    listScope === LIST_SCOPE.SINGLE_MOBILE_USER ||
    listScope === TERRITORY_SCOPE.MOBILE_USER
  ) {
    return mobileUsers
      .filter((u) => u.licensed)
      .map((u) => {
        return { key: u.id, label: getFullName(u) };
      });
  }

  return [];
};

const combineGeodelimitations = (
  items: IListItem[],
  schema: IListSchema[],
  lang: TLang,
  dispatch: Dispatch
) => {
  const geoDelimitationTags: any = [];
  _.map(schema, (att: IListSchema) => {
    if (att.type === CUSTOM_FIELD_TYPE.GEO_DELIMITATION) {
      geoDelimitationTags.push(att.column_tag);
    }
  });
  const combinedFeatureCollection: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };
  const updateFeatureProperties = (
    item: IListItem,
    featureCollection: FeatureCollection
  ) => {
    _.forEach(featureCollection.features, (feature) => {
      feature.properties = {
        ...feature.properties,
        name: item["_displayed_name"] ?? "",
        description: `id: ${item["_id"]}`,
      };
    });
  };
  _.forEach(items, (item) => {
    _.forEach(geoDelimitationTags, (tag) => {
      if (_.isEmpty(item[tag])) {
        return;
      }
      const featureCollection = item[tag] as FeatureCollection;
      updateFeatureProperties(item, featureCollection);
      combinedFeatureCollection.features.push(...featureCollection.features);
    });
  });

  if (combinedFeatureCollection.features.length === 0) {
    dispatch(
      showNotificationActionCreator(
        NOTIFICATION_SUCCESS,
        NOTIFICATION_LEVEL_SUCCESS,
        lang.containers.lists.subCategories.items.customNotifications
          .downloadKmlFile
      )
    );
    return;
  }

  return combinedFeatureCollection;
};

const downloadKmlFile = (
  items: IListItem[],
  schema: IListSchema[],
  lang: TLang,
  dispatch: Dispatch
) => {
  const featureCollection = combineGeodelimitations(
    items,
    schema,
    lang,
    dispatch
  );
  if (_.isEmpty(featureCollection)) {
    return;
  }
  const kmlString = tokml(featureCollection);
  const blob = new Blob([kmlString], {
    type: "application/vnd.google-earth.kml+xml",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "geoDelimitations.kml");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const downloadGeojsonFile = (
  items: IListItem[],
  schema: IListSchema[],
  lang: TLang,
  dispatch: Dispatch
) => {
  const featureCollection = combineGeodelimitations(
    items,
    schema,
    lang,
    dispatch
  );

  if (_.isEmpty(featureCollection)) {
    return;
  }
  const dataStr = JSON.stringify(featureCollection);
  const blob = new Blob([dataStr], { type: "application/json" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "geoDelimitations.json");
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export {
  downloadGeojsonFile,
  downloadKmlFile,
  formatFilterForBackend,
  getAllowedOwnerOptions,
};
