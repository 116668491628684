import { CalendarEventStatus } from "fieldpro-tools";
import _ from "lodash";

import { Black, White } from "assets/colors";
import FILTER_TYPE, { FILTER_TAG } from "components/Filter/TypeFilter";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { IMobileUser } from "model/entities/User";
import getFullName from "utils/formatting/getFullName";

enum CALENDAR_FILTERS_ENUM {
  STATUS = "STATUS",
}

export const CALENDAR_FILTERS = {
  ...CALENDAR_FILTERS_ENUM,
  ...FILTER_TAG,
};

export type CALENDAR_FILTERS = typeof CALENDAR_FILTERS_ENUM;

interface IPrepareFilters {
  lang: TLang;
  mobileUsers: IMobileUser[];
  filterQuery: Record<string, any>;
  displayFieldUserFilter?: boolean;
}

export function prepareFilters({
  mobileUsers,
  lang,
  filterQuery,
  displayFieldUserFilter,
}: IPrepareFilters): IFilter<any>[] {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const mobileUsersAsOptions = _.map(mobileUsers, (user) => {
    return {
      key: user.id,
      label: user["name"] || getFullName(user),
    };
  });

  const fieldUserFilter: IFilter = {
    label: langKey.createEditModal.inputFieldUser.title,
    tag: CALENDAR_FILTERS.USERS,
    type: FILTER_TYPE.SINGLE_CHOICE,
    value: filterQuery[CALENDAR_FILTERS.USERS] || [],
    options: mobileUsersAsOptions,
    isSecondaryFilter: false,
    styles: {
      showArrow: true,
    },
  };

  const statusFilter: IFilter<CalendarEventStatus> = {
    label:
      lang.containers.calendar.subCategories.calendar.createEditModal
        .inputStatus.title,
    tag: CALENDAR_FILTERS.STATUS,
    type: FILTER_TYPE.SINGLE_CHOICE,
    value: filterQuery[CALENDAR_FILTERS.STATUS] || [],
    isSecondaryFilter: false,
    options: getEventStatusOptions({ lang }),
    styles: {
      showArrow: true,
      bgColor: White,
      textColor: Black,
      dropdownStyles: {
        "&:hover": {
          backgroundColor: White,
        },
        "&:focus": {
          backgroundColor: White,
        },
      },
    },
  };

  const allFilters: IFilter<any>[] = [];

  if (displayFieldUserFilter) {
    allFilters.push(fieldUserFilter);
  }

  allFilters.push(statusFilter);

  return allFilters;
}

interface IGetEventStatusOptions {
  lang: TLang;
}

export function getEventStatusOptions({
  lang,
}: IGetEventStatusOptions): IOption<CalendarEventStatus>[] {
  const langKey =
    lang.containers.calendar.subCategories.calendar.createEditModal.inputStatus
      .options;

  return _.map(
    [
      CalendarEventStatus.COMPLETED,
      CalendarEventStatus.ONGOING,
      CalendarEventStatus.OUTDATED,
    ],
    (key) => {
      return {
        key,
        label: langKey[key],
      };
    }
  );
}
