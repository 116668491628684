import { Component } from "react";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import InputText from "components/Input/InputText/InputText";
import TLang from "model/application/Lang";

export interface IWebRole {
  business_role: string;
}

interface IInputWebRoleProps {
  onChange: (e: IWebRole) => void;
  element: IWebRole;
  lang: TLang;
}

export const getErrorMessageForWebRole: TValidateFunction<IWebRole> = ({
  attributes,
  lang,
}) => {
  const { business_role } = attributes;
  const result: any = {};
  if (!business_role || business_role.length === 0)
    result["business_role"] = lang.components.inputErrors.empty;
  if (Object.keys(result).length === 0) return {};
  return result;
};

export const validateWebRole = (business_role: string) => {
  business_role = business_role || "";
  return {
    business_role: business_role.length === 0,
  };
};

export class WebUserRoleForm extends Component<IInputWebRoleProps, IWebRole> {
  constructor(props: IInputWebRoleProps) {
    super(props);
    const { element } = props;
    this.state = element;
  }

  handleInputChange = (value: any, name: string) => {
    const newState = {
      ...this.state,
      [name]: value,
    };
    this.setState(newState);
    this.props.onChange(newState);
  };

  render() {
    const { element, lang } = this.props;

    const labelToDisplay = lang.modal.createEditOption;

    return (
      <div>
        <InputText
          name="business_role"
          lang={labelToDisplay.inputKey}
          defaultValue={element.business_role}
          onChange={this.handleInputChange}
          required={true}
        />
      </div>
    );
  }
}

export default WebUserRoleForm;
