import { useEffect, useState } from "react";

import { fetchItemsForListAction } from "containers/lists/redux/actions";
import useActions from "hooks/useActions";
import ICustomer from "model/entities/Customer";

interface ICustomerLoader {
  dataTestid?: string;
  customerId: string;
  render: ({
    loading,
    customer,
  }: {
    loading: boolean;
    customer?: ICustomer;
  }) => JSX.Element | null;
}

const CustomerLoader = ({ customerId, render }: ICustomerLoader) => {
  const [customer, setCustomer] = useState<ICustomer>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchItemsForList = useActions(fetchItemsForListAction);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchItemsForList("customer", {
        query: { _id: customerId },
      });

      const customer = response?.items?.[0] as ICustomer;

      if (customer) {
        setCustomer(customer);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return render({ loading, customer });
};

export default CustomerLoader;
