import { memo, useEffect, useMemo, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import InterestsIcon from "@mui/icons-material/Interests";
import _ from "lodash";

import { GreyLight } from "assets/colors";
import { getItemCategory } from "components/Input/InputMatrix/utils/getItemCategory";
import { getItemsInRows } from "components/Input/InputMatrix/utils/getItemsInRows";
import { getCategoryTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import InputSearch from "components/Input/InputSearch";
import CustomSpinner from "components/Progress/CustomSpinner";
import { Tab, Tabs } from "components/Tab";
import TabPlaceHolder from "components/Tab/TabPlaceHolder";
import { TCategoryType, TRowType } from "components/Table/model";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { useActions } from "hooks";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import useTranslations from "hooks/useTranslations";
import { IList, IListSchema } from "model/entities/List";
import { IQuestion } from "model/entities/Workflow";

import ItemGallery, { IItemGalleryItem } from "./ItemGallery";

const useStyles = makeStyles({
  tabs: {
    "& > *": {
      fontWeight: "500 !important",
    },
  },
});

export interface IMatrixEditorBodyProps {
  list: IList;
  categories: TCategoryType[];
  rows: TRowType[];
  onClickItem: (item: IItemGalleryItem) => void;
  onRemoveItem: (item: IItemGalleryItem) => void;
  customFieldMatrix: IQuestion | IListSchema;
}
function MatrixEditorBody({
  categories,
  list,
  rows,
  onClickItem,
  onRemoveItem,
  customFieldMatrix,
}: IMatrixEditorBodyProps) {
  const fetchItemsForList = useActions(fetchItemsForListAction);
  const [loadingItems, setLoadingItems] = useState(true);
  const classes = useStyles();
  const lang = useTranslations();
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState(list.items);

  const categoryKey = getCategoryTagKey(customFieldMatrix);

  function handleSearchFunction(value: any) {
    setSearchTerm(value);
  }
  const handleSearch = useDebouncedCallback(handleSearchFunction, 100, []);

  useEffect(() => {
    setItems(list.items);
  }, [list.items]);

  const langKey = lang.components.inputMatrixEditor;

  const allItems = useMemo(
    () => _.without(items, ...getItemsInRows(rows, list)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, list, rows]
  );

  const itemsPerCategory = useMemo(() => {
    const categoryMap = new Map();
    _.forEach(categories, function (c) {
      const itemsInCategory = _.without(
        _.filter(items, function (item) {
          const itemCategory = getItemCategory(
            item._id,
            list,
            customFieldMatrix[categoryKey]
          );
          return itemCategory === c.key;
        }),
        ...getItemsInRows(rows, list)
      );
      categoryMap.set(c.key, itemsInCategory);
    });
    return categoryMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, items, list, customFieldMatrix[categoryKey]]);

  const generateTabs = () => {
    const tabs = [];

    tabs.push(
      <Tab
        id="all"
        key="all"
        label={langKey.createEditModal.section?.all ?? ""}
      >
        <Box paddingTop={"12px"}>
          <ItemGallery
            items={allItems}
            onClickItem={onClickItem}
            searchTerm={searchTerm}
            listId={list.id}
          />
        </Box>
      </Tab>
    );

    _.forEach(categories, function (c) {
      tabs.push(
        <Tab id={c.key} key={c.key} label={c.label}>
          <Box paddingTop={"12px"}>
            {_.isEmpty(itemsPerCategory.get(c.key)) ? (
              <TabPlaceHolder
                description={
                  langKey.customErrorMessages.noItemsInCategoryDescription
                }
                title={langKey.customErrorMessages.noItemsInCategoryTitle}
                icon={<InterestsIcon />}
                style={{
                  gridColumn: "none",
                }}
                iconSize={"90px"}
                iconColor={GreyLight}
              />
            ) : (
              <>
                <ItemGallery
                  items={itemsPerCategory.get(c.key)}
                  onClickItem={onClickItem}
                  searchTerm={searchTerm}
                  listId={list.id}
                />
              </>
            )}
          </Box>
        </Tab>
      );
    });

    tabs.push(
      <Tab
        id="filled-items"
        key="filled-items"
        label={langKey.createEditModal.section?.filledItems ?? ""}
        badgeContent={rows.length.toString()}
      >
        <Box paddingTop={"12px"}>
          <ItemGallery
            items={getItemsInRows(rows, list)}
            onClickItem={onClickItem}
            onRemoveItem={onRemoveItem}
            searchTerm={searchTerm}
            listId={list.id}
          />
        </Box>
      </Tab>
    );

    return tabs;
  };

  useEffect(() => {
    fetchItemsForList(list.id, {
      limit: 2000,
    })?.finally(() => {
      setLoadingItems(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box paddingTop={"12px"} data-testid="MatrixEditorBody">
      <Box paddingBottom={"5px"}>
        <InputSearch fullWidth onChange={handleSearch} />
      </Box>
      {loadingItems && (
        <Box
          style={{
            transform: "translate(-50%, -50%)",
          }}
          position={"absolute"}
          left={"50%"}
          top={"50%"}
          data-testid="loading-spinner"
        >
          <CustomSpinner size={110} />
        </Box>
      )}

      {!loadingItems && <Tabs className={classes.tabs}>{generateTabs()}</Tabs>}
    </Box>
  );
}

export default memo(MatrixEditorBody);
