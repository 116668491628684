import { Component, createContext, memo } from "react";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { bindActionCreators, Dispatch } from "redux";

import Dashboard from "components/Dashboard/Dashboard";
import { getQuery } from "components/Filter/Filter.utils";
import FilterContainer from "components/Filter/FilterContainer";
import ContentLoader from "components/Progress/ContentLoader";
import {
  getLevelViewOptions,
  getSelectedClient,
} from "containers/clients/redux/selectors";
import * as dashboardActions from "containers/dashboards/redux/actions";
import * as listActions from "containers/lists/redux/actions";
import { customerComposedSelector } from "containers/lists/redux/selectors";
import { IOption } from "model/application/components";
import { DEFAULT_DATE_FILTER, IClient } from "model/entities/Client";
import ICustomer from "model/entities/Customer";
import { IDashboard, IKPI } from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { ITableOptimization } from "model/entities/TableOptimization";
import { ITeamSelector } from "model/entities/Team";
import { IMobileUser } from "model/entities/User";
import IRootState from "redux/store/model";

import { IFilter } from "../../../model/application/Filter";
import { getPdfData } from "./OptimetriksDashboardScreenUtils";
import { prepareFilters } from "./prepareFilters";
import styles from "./styles";

interface IOptimetriksDashboardScreenProps {
  dashboard: IDashboard;
  isLoading: boolean;
  onRunQuery: (
    dashboard: IDashboard,
    query: any,
    callback?: any,
    degradedModeKpis?: string[]
  ) => Promise<any>;
  users: IMobileUser[];
  workflows: { id: string; name: string }[];
  teams: ITeamSelector[];
  lists: IList[];
  selectedClient: IClient;
  allTableOptimizations?: ITableOptimization[];
  customerList?: IList<ICustomer>;
  classes: any;
  viewOptions: IOption[];
  dashboardActions: any;
  routerActions: any;
  listActions: any;
  filterButtonsOnly?: boolean;
  mobileUserRoles?: string[];
}

interface IOptimetriksDashboardScreenState {
  selectedUser?: string;
}

export interface IDashboardFilterContext {
  filters: IFilter[];
}
export const DashboardFilterContext =
  createContext<IDashboardFilterContext | null>(null);

export class OptimetriksDashboardScreen extends Component<
  IOptimetriksDashboardScreenProps,
  IOptimetriksDashboardScreenState
> {
  constructor(props: IOptimetriksDashboardScreenProps) {
    super(props);

    // first run when mounting the component
    const filters: IFilter[] = prepareFilters(
      props.dashboard,
      props.selectedClient,
      props.workflows,
      props.lists,
      props.teams,
      props.users,
      props.mobileUserRoles ?? [],
      props.selectedClient.default_date_filter
        ? props.selectedClient.default_date_filter
        : DEFAULT_DATE_FILTER.YESTERDAY,
      props.allTableOptimizations || []
    );
    const query = getQuery(filters, props.dashboard);
    this.handleChangeFilter(query);
    this.state = {};
  }

  handleChangeFilter = (query: any) => {
    const { onRunQuery, dashboard } = this.props;
    onRunQuery(dashboard, query);
  };

  sortDashboards = (kpis: IKPI[]) => {
    return kpis.sort((a, b) => a.index - b.index);
  };

  /* goBackToDashboardsView = () => {
    const { dashboard, dashboardActions, routerActions } = this.props;
    const folderName = dashboard.folder || "default";

    routerActions.replace(
      `/dashboards/${folderName.toLowerCase().replaceAll(" ", "_")}`
    );
  }; */

  render() {
    const {
      dashboard,
      users,
      workflows,
      teams,
      isLoading,
      lists,
      selectedClient,
      allTableOptimizations,
      classes,
      filterButtonsOnly = false,
      mobileUserRoles,
    } = this.props;

    const displayDownloadButton = dashboard.id !== "_gps_tracking";

    if (isLoading) {
      return <ContentLoader />;
    }

    const filters: IFilter[] = prepareFilters(
      dashboard,
      selectedClient,
      workflows,
      lists,
      teams,
      users,
      mobileUserRoles ?? [],
      selectedClient.default_date_filter
        ? selectedClient.default_date_filter
        : DEFAULT_DATE_FILTER.YESTERDAY,
      allTableOptimizations || []
    );

    return (
      <div className={classes.OptimetriksDashboardScreen}>
        <FilterContainer
          filters={filters}
          allTeamsObject={teams}
          metaHierarchy={selectedClient.meta_hierarchy_dependencies}
          hierarchy={selectedClient.hierarchy_dependencies}
          onChangeFilters={this.handleChangeFilter}
          title={!filterButtonsOnly ? dashboard.title : undefined}
          client={selectedClient}
          getPdfData={displayDownloadButton ? getPdfData.bind(this) : undefined}
          dashboard={dashboard}
          openOnMount={false}
          fixedPrimaryFilters={true}
        />

        <DashboardFilterContext.Provider
          value={{
            filters,
          }}
        >
          {this.buildDashboard(filters)}
        </DashboardFilterContext.Provider>
      </div>
    );
  }

  changeSelectedUser = (idUser?: string) => {
    this.setState({ selectedUser: idUser });
  };

  buildDashboard = (filters: IFilter[]) => {
    const { dashboard, classes, onRunQuery } = this.props;

    const { selectedUser } = this.state;

    return (
      <Dashboard
        filters={filters}
        dashboard={dashboard}
        classes={classes}
        onRunQuery={onRunQuery}
        // TODO move this state down
        selectedUser={selectedUser}
        changeSelectedUser={this.changeSelectedUser}
      />
    );
  };
}

const mapStateToProps = (state: IRootState) => {
  return {
    customerList: customerComposedSelector(state),
    selectedClient: getSelectedClient(state),
    viewOptions: getLevelViewOptions(state),
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions as any, dispatch),
    routerActions: bindActionCreators(routerActions as any, dispatch),
    listActions: bindActionCreators(listActions as any, dispatch),
  };
}

export const getDashboardUrlFolder = (folderName: string = "default") => {
  return folderName.toLowerCase().replaceAll(" ", "_");
};

export default withStyles(styles as any)(
  connect(mapStateToProps, mapDispatchToProps)(memo(OptimetriksDashboardScreen))
);
