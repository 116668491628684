import { FormEvent, useState } from "react";

import { Button, makeStyles } from "@material-ui/core";
import _ from "lodash";

import PopoverWithArrow from "components/Popover/PopoverWithArrow";
import { useTranslations } from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";

import SearchDropdown from "./FilterModals/components/SearchDropdown";

interface IAddFilterButton {
  filter: IFilter;
  onChangeFilter: (name: string, value: any) => void;
}

const styles = (theme: any) => ({
  AddFilterButton: {
    flexShrink: 0,
    color: theme.palette.primary.main,
    padding: "2px 8px",
  },
});

const useStyles = makeStyles(styles);

const AddFilterButton = ({ filter, onChangeFilter }: IAddFilterButton) => {
  const lang = useTranslations();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onAddFilter = (event: FormEvent<HTMLFormElement>, option: IOption) => {
    event.preventDefault();
    onChangeFilter(filter.tag, [...filter.value, option.key]);
    onClose();
  };

  if (_.isEmpty(filter.options)) {
    return null;
  }

  return (
    <>
      <Button onClick={onOpen} className={classes.AddFilterButton}>
        <FilterIcon />
        {lang.components.filters.addFilter}
      </Button>

      <PopoverWithArrow
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <SearchDropdown options={filter.options} onSelectOption={onAddFilter} />
      </PopoverWithArrow>
    </>
  );
};

export const FilterIcon = () => (
  <span className="material-icons-outlined" style={{ marginRight: "4px" }}>
    filter_alt
  </span>
);

export default AddFilterButton;
