import { PLAN_ID } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";

import { BILLING_TYPE, CLIENT_STATUS, IClient } from "model/entities/Client";

/**
 * CLIENT BILLING AND PLAN
 */

export const getClientPlan = (client?: IClient) => {
  return client?.billing?.license_plan;
};

/**
 * Gets the client's active plan id ("active" as opposed to "canceled", includes all others statuses trialing, like past_due, incomplete_expired, ...)
 *
 * IMPORTANT:
 * - returns "starter" if the plan is pro in status canceled, or is undefined
 * - returns null if the client's billing type is CUSTOM
 */
export const getActivePlanId = (client?: IClient) => {
  if (!isClientBillingType(client, BILLING_TYPE.AUTOMATED)) {
    return null;
  }

  // IMPORTANT: fallback to starter if pro plan is canceled
  const plan = getClientPlan(client);
  if (plan?.status === "canceled") {
    return "starter";
  }

  // fallback to starter if no plan defined
  return getClientPlan(client)?.id || "starter";
};

export const isClientBillingPlan = (client?: IClient, plan?: PLAN_ID) => {
  return getActivePlanId(client) === plan;
};

export const isClientBillingType = (client?: IClient, type?: BILLING_TYPE) => {
  return getBillingType(client) === type;
};

export const hasDefaultPaymentMethod = (client?: IClient) => {
  return !!getClientPlan(client)?.stripe_default_payment_method_id;
};

export const getStripeCustomerId = (client?: IClient) => {
  return client?.stripe_customer_id || client?.billing?.stripe_customer_id;
};

export const getBillingType = (client?: IClient) => {
  return client?.billing_type || client?.billing?.type;
};

/**
 * CLIENT STATUS
 */

export const isClientStatusExpired = (client?: IClient) => {
  return client?.status === CLIENT_STATUS.EXPIRED;
};

export const isClientStatusLive = (client?: IClient) => {
  return client?.status === CLIENT_STATUS.LIVE;
};

export const isClientStatus = (
  client?: IClient,
  status?: CLIENT_STATUS | CLIENT_STATUS[]
) => {
  if (_.isArray(status)) {
    return _.includes(status, client?.status);
  }
  return client?.status === status;
};

export const getExpiredReason = (client?: IClient) => {
  if (!client || !(client.status === CLIENT_STATUS.EXPIRED)) {
    return null;
  }

  // TODO: check this, and eventually use stripe's subscription trial_end to check it is less than 30 days
  // Otherwise, consider expired reason is inactivity
  if (
    !client.billing?.license_plan ||
    _.includes(
      ["past_due", "incomplete"],
      client?.billing?.license_plan?.status
    )
  ) {
    return "TRIAL_ENDED";
  }

  return "SUBSCRIPTION_ENDED";
};

export const isClientPaymentOverdue = (client: IClient) => {
  if (!client || _.isUndefined(client.billing?.license_plan?.status)) {
    return false;
  }
  return _.includes(
    ["unpaid", "past_due"],
    client.billing?.license_plan?.status
  );
};

/**
 * Client has a subscription in Stripe, with status "canceled"
 */
export const isLicensePlanStatusCanceled = (client?: IClient) => {
  return client?.billing?.license_plan?.status === "canceled";
};

export const isLicensePlanSetToCancel = (client?: IClient) => {
  return !_.isEmpty(client?.billing?.license_plan?.canceled_at);
};

export const isLicensePlanCanceledOrSetToCancel = (client: IClient) => {
  return (
    isLicensePlanStatusCanceled(client) || isLicensePlanSetToCancel(client)
  );
};

export const isAboutToCancelSubscription = (client: IClient) => {
  const cancelsAt = client?.billing?.license_plan?.cancels_at;
  if (!cancelsAt) {
    return false;
  }
  return moment().isBefore(moment(cancelsAt));
};
