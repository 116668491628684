import {
  IList as IBEList,
  IListSchema as IBEListSchema,
  IMatrix,
} from "fieldpro-tools/dist/src/types";

import { IOption, ITableRowContent } from "model/application/components";

import { IListItem } from "./ListItem";
export type { ITaskDescriptiveText } from "fieldpro-tools/dist/src/types";
export {
  CUSTOM_FIELD_TYPE,
  LIST_ACCESS_RIGHT_FOR_MOBILE_USER,
  LIST_ACCESS_RIGHT_FOR_WEB_USER,
  LIST_SCOPE,
  LIST_TYPE,
  TASK_TYPE,
} from "fieldpro-tools/dist/src/types";

export interface IAttributeMatrix extends Omit<IMatrix, "typed_questions"> {
  typed_questions: Array<IListSchema>;
}

export interface IListSchema extends Omit<IBEListSchema, "options" | "matrix"> {
  options?: IOption<string>[];
  matrix?: IAttributeMatrix;
  index: number;
}

export interface IList<T extends IListItem = IListItem>
  extends Omit<
    IBEList,
    "active" | "items" | "client_id" | "updated_at" | "created_at" | "schema"
  > {
  active: boolean;
  items: T[];
  options?: IOption<string>[];
  table_items_count?: number;
  table_items?: T[];
  client_id?: string;
  updated_at?: Date;
  created_at?: Date;
  schema: IListSchema[];
  partial_data: boolean;
  big_list: boolean;
  linked_workflows?: { id: string; name: string }[];
  item_count: number;
  query: any; // additionnal frontend property to store the query running on the list
  show_ids: boolean;
  web_user_roles?: string[];
}

export interface IListInTable extends ITableRowContent {
  id?: string;
  active: boolean;
  list_id: string;
  name: string;
  item_count: number;
}
