import { Component, Suspense } from "react";

// Required for react-map-gl v7 maps (otherwise Markers, NavigationControl, Mapbox logo and links, ... will not show properly)
// See https://visgl.github.io/react-map-gl/docs/get-started#styling
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { Crisp } from "crisp-sdk-web";
import FieldProLogger from "log/FieldProLogger";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { routerActions } from "react-router-redux";
import { bindActionCreators, Dispatch } from "redux";

import ContentLoader from "components/Progress/ContentLoader";
import { WarningModalContext } from "containers/app/AppProviders";
import * as authActions from "containers/authentication/redux/actions";
import {
  getLang,
  getSignedInUser,
  tokenSelector,
} from "containers/authentication/redux/selector";
import { fetchAllClientsAction } from "containers/clients/redux/actions";
import {
  getIsFetchingAllClients,
  getSelectedClient,
} from "containers/clients/redux/selectors";
import * as lang from "lang";
import { HOME_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from "model/constants/routes";
import { IClient, TLightClient } from "model/entities/Client";
import { ISignedInUser } from "model/entities/User";
import * as ajaxActions from "redux/actions/ajaxActions";
import * as appActions from "redux/actions/appActions";
import IRootState, { IDispatchAndGetState } from "redux/store/model";

import AppErrorBoundary from "./AppErrorBoundary";
import { AppConsumer, AppProviders } from "./AppProviders";
import { AppTheme } from "./AppTheme";
import AuthenticatedApp from "./AuthenticatedApp";
import { LoginContainer, TrialContainer } from "./pages";
import setCrispSessionData from "./utils/setCrispSessionData";
import setHeapSessionData from "./utils/setHeapSessionData";

interface IAppBaseProps {
  client?: IClient;
  user?: ISignedInUser;
  locale: string;
  token: string | null;
  fetchAllClients: (selectId: string) => IDispatchAndGetState<TLightClient[]>;
  isFetchingClients: boolean;
  appActions: typeof appActions;
  ajaxActions: typeof ajaxActions;
  redirectActions: typeof routerActions;
  authActions: any; // TODO
  cookies: any; // TODO;
}

type TAppProps = IAppBaseProps & RouteComponentProps;

/**
 * Application container component. This is the Root Component of the application.
 * */
class App extends Component<TAppProps> {
  componentDidMount() {
    const { client, user } = this.props;

    if (this.isCrispTokenDefined()) {
      Crisp.configure(`${process.env.REACT_APP_CRISP_API_TOKEN}`, {
        autoload: true,
      });

      setCrispSessionData({ client, user, crispClient: Crisp });
    } else {
      console.warn("Crisp token not set, not session data will be sent");
    }

    if (this.isHeapConfigured()) {
      setHeapSessionData({ client, user, heapClient: heap });
    }
  }

  componentDidUpdate(prevProps: IAppBaseProps) {
    const { client: prevClient, user: prevUser } = prevProps;
    const { client, user } = this.props;

    if (prevClient?.id !== client?.id || prevUser?.id !== user?.id) {
      if (this.isCrispTokenDefined()) {
        setCrispSessionData({ client, user, crispClient: Crisp });
      }

      if (this.isHeapConfigured()) {
        setHeapSessionData({ client, user, heapClient: heap });
      }
    }
  }

  isHeapConfigured = () => {
    return !!heap?.appid;
  };

  isCrispTokenDefined = () => {
    return !!process.env.REACT_APP_CRISP_API_TOKEN;
  };

  render() {
    const { client, user, cookies, locale } = this.props;

    const logger = FieldProLogger({ client, user });

    return (
      <AppErrorBoundary client={client} user={user}>
        <MuiThemeProvider theme={AppTheme}>
          <CssBaseline />
          <AppProviders
            user={user}
            logger={logger}
            lang={lang[locale]}
            warningModal={{
              openWarningModal: this.props.appActions.openWarningModal,
              closeWarningModal: this.props.appActions.closeWarningModal,
            }}
          >
            <AppConsumer>
              {() => (
                <Switch>
                  <Route path={LOGIN_ROUTE}>
                    {user?.isAuthenticated ? (
                      <Redirect to={HOME_ROUTE} />
                    ) : (
                      <Suspense fallback={<ContentLoader />}>
                        <LoginContainer />
                      </Suspense>
                    )}
                  </Route>

                  <Route path={SIGNUP_ROUTE}>
                    {user?.isAuthenticated ? (
                      <Redirect to={HOME_ROUTE} />
                    ) : (
                      <Suspense fallback={<ContentLoader />}>
                        <TrialContainer />
                      </Suspense>
                    )}
                  </Route>

                  <Route
                    render={() => {
                      if (!user?.isAuthenticated) {
                        return <Redirect to={LOGIN_ROUTE} />;
                      }

                      return (
                        <AuthenticatedApp
                          // DO NOT UNCOMMENT KEY: we don't want to refetch all clients on client switch,
                          // and there is already a key on AuthenticatedApp
                          // key={client?.id}
                          client={client}
                          cookies={cookies}
                          logger={logger}
                        />
                      );
                    }}
                  />
                </Switch>
              )}
            </AppConsumer>
          </AppProviders>
        </MuiThemeProvider>
      </AppErrorBoundary>
    );
  }
}

/**
 * Maps the state of the Redux Store to This components props
 * @param {Object} state The Redux Store state
 * @param {Object} ownProps App's own props
 * @returns {Object} Returns an object which is the redux store mapping to this Application props
 * */
function mapStateToProps(state: IRootState) {
  return {
    token: tokenSelector(state),
    client: getSelectedClient(state) as IClient | undefined, // can be undefined when not logged in
    user: getSignedInUser(state) as ISignedInUser | undefined, // can be undefined when not logged in
    isFetchingClients: getIsFetchingAllClients(state),
    // router: state.routing,
    locale: getLang(state),
  };
}

/**
 * Map dispatch actions to the redux tore and bind these actions to be dispatched by a middleware
 * In this case, we are using redux thunk for dispatching these actions
 * @param {Function} dispatch Dispatch actions from Redux Thunk middleware
 * @returns {Object} */
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    ajaxActions: bindActionCreators(ajaxActions, dispatch),
    redirectActions: bindActionCreators(routerActions, dispatch),
    fetchAllClients: bindActionCreators(fetchAllClientsAction, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
  };
}

App.contextType = WarningModalContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(App))
);
