import { Dispatch } from "redux";

import { getBackendQuery } from "components/Filter/Filter.utils";
import { getLang } from "containers/authentication/redux/selector";
import { IQuery } from "containers/lists/utils";
import * as lang from "lang";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import IRootState from "redux/store/model";

import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";
import { changeSubcategorySuccessActionCreator } from "./actionCreator";
import { fetchCustomersLayerApiCall } from "./api";
/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "map" or "list"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export function changeSubcategoryAction(subcategory: any): any {
  return (dispatch: Dispatch) => {
    return dispatch(changeSubcategorySuccessActionCreator(subcategory));
  };
}

export type TFetchCustomersLayerAction = (
  query?: IQuery
) => Promise<{ customers: ICustomerLocation[] } | undefined>;

export const fetchCustomersLayerAction = (query?: IQuery) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    try {
      const backendQuery = getBackendQuery(query);
      const response = await fetchCustomersLayerApiCall(backendQuery);
      const data = extractDataAndCheckErrorStatus<{
        customers: ICustomerLocation[];
      }>(response);
      return data;
    } catch (e) {
      treatErrorNotification(
        dispatch,
        "FetchCustomersLayerError",
        e,
        null,
        currLang
      );
    }
  };
};
