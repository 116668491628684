import { IHttpRequest as IBEHttpRequest } from "fieldpro-tools/dist/src";

import { IHttpRequest } from "model/entities/Job";
import { clone } from "utils/utils";

const prepareHttpRequestForBackend = (
  originalHttpRequest: IHttpRequest
): IBEHttpRequest => {
  const http_request = JSON.parse(JSON.stringify(originalHttpRequest));
  Object.keys(http_request).forEach((att) => {
    if (http_request[att] === "") delete http_request[att];
  });
  if (http_request.body) {
    http_request.body = JSON.parse(JSON.stringify(http_request.body));
  }
  if (http_request.query) {
    http_request.query = http_request.query.map((q: any) => {
      delete q.index;
      return q;
    });
  }
  if (http_request.headers) {
    http_request.headers = http_request.headers.map((q: any) => {
      delete q.index;
      return q;
    });
  }

  delete http_request.id;
  delete (http_request as any).job_type;
  return http_request;
};
const prepareHttpRequestsForFrontend = (
  originalHttpRequests: IBEHttpRequest[]
): IHttpRequest[] => {
  const http_requests = clone(originalHttpRequests);
  const frontHttpRequests: IHttpRequest[] = [];
  http_requests.forEach((d: IBEHttpRequest) => {
    const notification = HttpRequestBackToFront(d);
    frontHttpRequests.push(notification);
  });
  return frontHttpRequests;
};
const HttpRequestBackToFront = (
  originalHttpRequest: IBEHttpRequest
): IHttpRequest => {
  if (originalHttpRequest.body) {
    originalHttpRequest.body = JSON.stringify(
      JSON.parse(originalHttpRequest.body)
    );
  }
  if (originalHttpRequest.headers && originalHttpRequest.headers.length != 0) {
    originalHttpRequest.headers = originalHttpRequest.headers.map(
      (header, index) => ({ ...header, index })
    );
  }
  if (originalHttpRequest.query && originalHttpRequest.query.length != 0) {
    originalHttpRequest.query = originalHttpRequest.query.map((q, index) => ({
      ...q,
      index,
    }));
  }
  const req = {
    ...originalHttpRequest,
  };
  return req;
};

export { prepareHttpRequestForBackend, prepareHttpRequestsForFrontend };
