import { useEffect, useRef } from "react";

import { Box, Chip, Fade, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";

import { GreyState, Mustard90 } from "assets/colors";
import CustomSpinner from "components/Progress/CustomSpinner";
import { allListsSelector } from "containers/lists/redux/selectors";
import getItemName from "containers/lists/utils/getItemName";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IListItem } from "model/entities/ListItem";

import { IImageDetectionDataFE } from "../types";

const useStyles = makeStyles({
  product: {
    color: GreyState,
    fontSize: "16px",
    transition: "0.2s",
    height: "48px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Mustard90,
    },
  },
  productImage: {
    width: "36px",
    height: "36px",
    marginRight: "16px", // espace entre l'image et le texte
    borderRadius: "4px", // coins arrondis
  },
  productSelected: {
    backgroundColor: Mustard90,
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "12px",
    paddingLeft: "24px",
  },
  categoryTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "24px",
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  productText: {
    paddingLeft: "24px",
  },
  chipItem: {
    color: "#353347",
    background: "#eeebf3",
  },
  chipCategory: {
    color: "#fafbff",
    background: "#164c62",
  },
  productContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});

export interface IScanResultsTabProps {
  detections: IImageDetectionDataFE[];
  onClickScanResult?: (itemId: string, boxesIds?: string[] | undefined) => void;
  onHoverScanResult?: (itemId: string, boxesIds?: string[] | undefined) => void;
  onMount?: () => void; // used to enable detections when the tab is mounted
  onUnmount?: () => void; // used to disable detections when the tab is unmounted
  selectedBoxId?: string;
  isLoadingScanResults?: boolean;
}
function ScanResultsTab({
  isLoadingScanResults,
  selectedBoxId,
  onHoverScanResult,
  onClickScanResult,
  detections,
  onMount,
  onUnmount,
}: IScanResultsTabProps) {
  const lang = useTranslations();
  const allLists = useSelector(allListsSelector);
  detections = detections.map((det) => {
    return {
      ...det,
      boxes: det.boxes.map((box) => {
        const detectionList = allLists.find(
          (l) => l.id === box?.product?.list_id
        );
        const categoriesOptions = detectionList?.schema?.find(
          (s) => s.column_tag === "_category"
        );
        const label = categoriesOptions?.options?.find(
          (o) => o.key === box?.product?._category
        )?.label;
        return {
          ...box,
          product: {
            ...box.product,
            categoryLabel: label,
          } as IListItem & { categoryLabel?: string },
        };
      }),
    };
  });
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const classes = useStyles();
  const selectedBoxRef = useRef<HTMLDivElement>(null);

  const boxes = _.flatten(_.map(detections, "boxes"));

  const categories = _.groupBy(
    boxes,
    (box) =>
      _.get(box, "product._category") ??
      _.get(box, "product.category") ??
      langKey.scanResultsTab.customMessage.noCategory
  );

  function handleClickScanResult(id: string, ids: string[]) {
    if (onClickScanResult) {
      onClickScanResult(id, ids);
    }
  }

  // enable detections when the tab is mounted
  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll to selected box
  useEffect(() => {
    if (selectedBoxId && selectedBoxRef.current) {
      selectedBoxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedBoxId, selectedBoxRef]);

  return (
    <Box data-testid="scan-results">
      {!isLoadingScanResults && (
        <>
          <Box className={classes.title} data-testid="products-found">
            {formatString(
              detections.length !== 1
                ? langKey.scanResultsTab.customMessage.productsFound
                : langKey.scanResultsTab.customMessage.productFound,
              [detections.length]
            )}
          </Box>
          <Box>
            {_.map(categories, (categoryBoxes, categoryKey) => {
              const groupedBoxes = _.groupBy(
                categoryBoxes,
                (box) => getItemName(box.product) ?? box.sku_id
              );
              const categoryLabel =
                categoryBoxes[0]?.product?.categoryLabel ??
                langKey.scanResultsTab.customMessage.noCategory;
              return (
                <Box key={categoryKey}>
                  <Box className={classes.categoryTitle}>{categoryLabel}</Box>
                  {_.flatten(
                    _.map(groupedBoxes, (boxes, itemName) => {
                      const boxesIds = _.map(boxes, "id");
                      const firstItem = boxes[0];
                      const product = firstItem["product"];
                      const imageUrl = product?._image_url?.url;
                      const selected = selectedBoxId === firstItem.sku_id;
                      return (
                        <Fade in key={firstItem.sku_id}>
                          <div
                            className={classNames(
                              classes.product,
                              selected && classes.productSelected,
                              classes.productContainer
                            )}
                            onClick={() => {
                              handleClickScanResult(firstItem.sku_id, boxesIds);
                            }}
                            onMouseEnter={() => {
                              if (onHoverScanResult) {
                                onHoverScanResult(firstItem.sku_id, boxesIds);
                              }
                            }}
                            onMouseLeave={() => {
                              if (onHoverScanResult) {
                                onHoverScanResult("");
                              }
                            }}
                            ref={selected ? selectedBoxRef : undefined}
                            data-testid={`scan-result-${firstItem.sku_id}`}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt={`Image of ${itemName}`}
                                className={classes.productImage}
                              />
                            ) : (
                              <div className="material-icons-outlined">
                                <span>image</span>
                              </div>
                            )}
                            <Box
                              className={classes.productText}
                              title={firstItem.sku_id}
                              style={{ flexGrow: 1 }}
                            >
                              {itemName || firstItem.sku_id}
                            </Box>
                            <Chip
                              className={classes.chipItem}
                              key={firstItem.sku_id}
                              label={_.size(boxes)}
                              style={{ flexShrink: 0 }}
                            />
                          </div>
                        </Fade>
                      );
                    })
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      )}
      {isLoadingScanResults && (
        <Box className={classes.spinnerContainer}>
          <CustomSpinner size={"50px"} />
        </Box>
      )}
    </Box>
  );
}

export default ScanResultsTab;
