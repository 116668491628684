import React, { useState } from "react";

import { Box, BoxProps, makeStyles, Popover } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import {
  GreenDark,
  GreyDark,
  GreyLight,
  Mustard,
  OffWhiteLight,
  PaleYellowOrange,
  red,
  successColor,
  tangerineError,
} from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import DefaultChip from "components/Chip/DefaultChip";
import { allListsSelector } from "containers/lists/redux/selectors";
import { useTranslations } from "hooks";

import { getCustomerById } from "./utils/getCustomerNameById";

function getStatusColor(status?: CalendarEventStatus) {
  switch (status) {
    case CalendarEventStatus.COMPLETED:
      return successColor;
    case CalendarEventStatus.ONGOING:
      return GreyLight;
    case CalendarEventStatus.OUTDATED:
      return tangerineError;
    case CalendarEventStatus.CANCELLED:
    case CalendarEventStatus.DELETED:
      return red;
    default:
      return GreyDark;
  }
}

export interface IBoardItemProps {
  event: TCalendarEvent;
  onEditEvent: (event: TCalendarEvent) => void;
  onClickDelete: (event: TCalendarEvent) => void;
  boxProps?: BoxProps;
  active?: boolean;
}

interface IStyleProps {
  status: CalendarEventStatus;
  active?: boolean;
  small?: boolean;
}
const useStyles = makeStyles({
  container: ({ status, active, small }: IStyleProps) => ({
    backgroundColor: active ? PaleYellowOrange : "unset",
    display: "flex",
    flexDirection: small ? "row" : "column",
    alignContent: "left",
    justifyItems: "center",
    borderLeft: active
      ? `2px solid ${Mustard}`
      : `2px solid ${getStatusColor(status)}`,
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15);",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: active ? PaleYellowOrange : OffWhiteLight,
    },
    height: "100%",
    minWidth: "150px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "left",
    paddingLeft: "4px",
    width: "92%",
    columnGap: small ? "16px" : "0px",
  }),
  heading: ({ small }: IStyleProps) => ({
    display: "grid",
    gridTemplateColumns: small ? "auto" : "auto minmax(auto,25px)",
    flexShrink: 0,
  }),
  timeSlot: {
    fontWeight: 500,
    color: GreyDark,
    fontSize: "12px",
    textAlign: "left",
  },
  content: ({ small }: IStyleProps) => ({
    flexGrow: 2,
    alignItems: "start",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: small ? "12px" : "16px",
    textWrap: "nowrap",
    paddingRight: "8px",
    maxWidth: small ? "95px" : "unset",
  }),
});

function BoardItem({
  event,
  boxProps,
  onEditEvent,
  onClickDelete,
  active,
}: Readonly<IBoardItemProps>) {
  const lang = useTranslations();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const small =
    moment.utc(event.end_time).diff(event.start_time, "minutes") <= 30;

  const status = event.status || CalendarEventStatus.ONGOING;
  const allLists = useSelector(allListsSelector);
  const classes = useStyles({ status, active, small });

  const dateString = moment.utc(event.start_time).format("dddd, Do MMMM");
  const customerName = getCustomerById(allLists, event.customer_id)?._name;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleClickEdit() {
    onEditEvent(event);
    handleClose();
  }
  function handleClickDelete() {
    onClickDelete(event);
    handleClose();
  }

  const eventTimeSlotString = `${moment
    .utc(event.start_time)
    .format("hh:mm")}-${moment
    .utc(event.end_time)
    .format(`hh:mm ${small ? "" : "A"}`)}`;
  const id = open ? "simple-popover" : undefined;

  const statusColor = getStatusColor(status);
  return (
    <>
      <Box
        key={String(small)} // to force re-render specially for placeholder boarditems
        onClick={handleClick}
        className={classNames(
          classes.container,
          _.pick(boxProps, ["className"])
        )}
        {..._.omit(boxProps, ["className", "style"])}
        style={{
          alignItems: small ? "center" : "left",
          ...(boxProps?.style || {}),
        }}
      >
        <Box className={classes.heading} data-testid="heading">
          <Box className={classes.timeSlot}>{eventTimeSlotString}</Box>
          {status === CalendarEventStatus.COMPLETED && !small && (
            <CheckCircleIcon
              style={{
                fontSize: "18px",
              }}
              htmlColor={GreenDark}
            />
          )}
        </Box>
        <Box className={classes.content}>{event.title}</Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: `0px 6px 10px 0px ${GreyLight}`,
            paddingBottom: "6px",
          },
        }}
      >
        <Box width={"300px"}>
          <Box
            gridTemplateRows={"auto 1fr"}
            display={"grid"}
            padding={"6px 12px"}
          >
            <Box display={"flex"} justifyContent={"end"}>
              {status != CalendarEventStatus.COMPLETED && (
                <SmallIconButton onClick={handleClickEdit}>
                  <EditIcon htmlColor={GreyDark} fontSize="small" />
                </SmallIconButton>
              )}
              <SmallIconButton onClick={handleClickDelete}>
                <DeleteIcon htmlColor={red} fontSize="small" />
              </SmallIconButton>
              <SmallIconButton onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </SmallIconButton>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gridRowGap={"8px"}>
              {customerName && (
                <Box fontWeight={"500"} fontSize={"16px"}>
                  {customerName}
                </Box>
              )}
              <Box
                color={GreyDark}
              >{`${dateString} ${eventTimeSlotString}`}</Box>
              <Box color={GreyDark}>{event.description}</Box>
              <Box>
                <DefaultChip
                  label={_.upperFirst(
                    _.lowerCase(
                      _.get(
                        lang.containers.calendar.subCategories.calendar
                          .createEditModal.inputStatus.options,
                        status
                      )
                    )
                  )}
                  style={{
                    background: statusColor
                      ? makeColorTransparent(
                          statusColor,
                          status === CalendarEventStatus.ONGOING ? 0.5 : 0.1
                        )
                      : undefined,

                    color:
                      status === CalendarEventStatus.ONGOING
                        ? GreyDark
                        : statusColor,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default BoardItem;

function makeColorTransparent(hexColor: string, opacity: number): string {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!colorRegex.test(hexColor)) {
    return hexColor;
  }
  const color = hexColor.replace("#", "");
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgbaColor;
}
