import _ from "lodash";

const safeParse = (value?: unknown) => {
  if (_.isNil(value)) {
    return {};
  }

  try {
    return JSON.parse(value as string);
  } catch (e) {
    console.error(`Unable to parse value (type: ${typeof value}): '${value}'`);
    return {};
  }
};

export default safeParse;
