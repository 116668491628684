import _ from "lodash";

import CustomDialogForm from "components/Dialog/CustomDialogForm";
import { IInputOption } from "components/Input/BaseOptionsContainer";
import InputMultipleOptions from "components/Input/InputMultipleOptions";
import MobileUserRoleForm, {
  getErrorMessageForMobRole,
  IMobRole,
} from "containers/clients/components/ClientForm/Tabs/UsersTab/MobileUserRoleForm";
import useOptionsHandlers from "hooks/useOptionsHandlers";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

export type TOption = IInputOption<IMobRole>;
interface IInputMobileUserRoles {
  viewMode: TViewMode;
  roles: IMobRole[];
  onChangeMobileRoles: (options: TOption[]) => void;
}

const InputMobileUserRoles = ({
  viewMode,
  roles,
  onChangeMobileRoles,
}: IInputMobileUserRoles) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.clients.subCategories.clients.createEditModal
      .inputMobileRole;

  const onChangeOptions = (options: TOption[]) => {
    // const roles = _.map(options, "value");
    // onChangeMobileRoles(_.compact(roles));
    onChangeMobileRoles(options);
  };

  const options = getOptionsFromRoles(roles);

  const {
    optionToCreate,
    optionToEdit,
    onDeleteOption,
    onOpenAddOption,
    setOptionToEdit,
    setOptionToCreate,
    onChangeValueToCreate,
    onChangeValueToEdit,
    onConfirmOptionToCreate,
    onConfirmOptionToEdit,
  } = useOptionsHandlers<IMobRole>({
    options,
    onChangeOptions,
    defaultValue: { role: "" },
  });

  // Validation
  const createOptionErrors =
    optionToCreate &&
    getErrorMessageForMobRole({
      attributes: optionToCreate.value,
      additionnalProps: {},
      viewMode: "CREATE",
      lang,
    });

  const editOptionErrors =
    optionToEdit &&
    getErrorMessageForMobRole({
      attributes: optionToEdit.value,
      additionnalProps: {},
      viewMode: "EDIT",
      lang,
    });

  return (
    <>
      <InputMultipleOptions
        viewMode={viewMode}
        label={langKey.title}
        tooltip={langKey.tooltip}
        options={options}
        onChangeOptions={onChangeOptions}
        onOpenAddOption={onOpenAddOption}
        onClickOption={setOptionToEdit}
        onDeleteOption={onDeleteOption}
        draggable
      />

      {optionToCreate && (
        <CustomDialogForm
          isOpen
          title={langKey.createEditModal.createTitle}
          onClose={() => setOptionToCreate(null)}
          onConfirmAction={onConfirmOptionToCreate}
          confirmBtnText={lang.modal.add}
          isDisabled={!_.isEmpty(createOptionErrors)}
          lang={lang}
        >
          <MobileUserRoleForm
            onChange={onChangeValueToCreate}
            element={optionToCreate.value}
            lang={lang}
          />
        </CustomDialogForm>
      )}

      {optionToEdit && (
        <CustomDialogForm
          isOpen
          onClose={() => setOptionToEdit(null)}
          title={langKey.createEditModal.editTitle}
          onConfirmAction={onConfirmOptionToEdit}
          confirmBtnText={lang.modal.save}
          isDisabled={!_.isEmpty(editOptionErrors)}
          lang={lang}
        >
          <MobileUserRoleForm
            onChange={onChangeValueToEdit}
            element={optionToEdit.value}
            lang={lang}
          />
        </CustomDialogForm>
      )}
    </>
  );
};

const getOptionsFromRoles = (roles: IMobRole[]): TOption[] => {
  return _.map(roles, (role) => ({
    key: role.role,
    label: role.role,
    value: role,
  }));
};

export default InputMobileUserRoles;
