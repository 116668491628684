/**
 * NOTE: configureStore.prod and configureStore.staging are identical ?
 * and configureStore.dev should be called configureStore.local (it has support for redux-devtools)
 */
if (process.env.REACT_APP_REDUX_DEVTOOLS) {
  module.exports = require("./configureStore.dev");
} else if (process.env.REACT_APP_ENV === "production") {
  module.exports = require("./configureStore.prod");
} else if (process.env.REACT_APP_ENV === "staging") {
  module.exports = require("./configureStore.staging");
} else {
  module.exports = require("./configureStore.dev");
}
