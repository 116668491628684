import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";

import { isDisplayed } from "containers/lists/subcategories/prepareFilters";

import { IFilter } from "../../model/application/Filter";
import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "../../model/entities/HierarchyDependency";
import { ITeamSelector } from "../../model/entities/Team";
import AddFilterButton from "./AddFilterButton";
import { getNewFilters } from "./Filter.utils";
import FilterDropdown from "./FilterDropdown";
import FILTER_TYPE, { FILTER_TAG } from "./TypeFilter";

const styles = {
  FilterDropdownManager: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "8px",
    "&:not(.noBottomMargin)": {
      marginBottom: "20px",
    },
  },
};

const useStyles = makeStyles(styles as any);

export interface IFilterDropdownManagerProps {
  filters: IFilter[];
  allTeamsObject?: ITeamSelector[];
  hierarchy?: IHierarchyDependency[];
  metaHierarchy?: IMetaHierarchyDependency;
  onChangeFilters: (filters: IFilter[]) => void;
  noBottomMargin?: boolean;
  openOnMount?: boolean;
}

export const FilterDropdownManager: React.FunctionComponent<
  IFilterDropdownManagerProps
> = (props: IFilterDropdownManagerProps) => {
  const classes = useStyles();

  const {
    metaHierarchy,
    hierarchy,
    allTeamsObject,
    filters,
    onChangeFilters,
    noBottomMargin,
    openOnMount = true,
  } = props;

  const handleChangeFilter = (tag: string, value: any) => {
    // check scope to determine owners type (team or mobile user)
    const isTeamScope = !filters.some((f) => f.tag === FILTER_TAG.TEAMS);

    const newFilters = getNewFilters({
      filters,
      tag,
      value,
      isTeamScope,
      metaHierarchy,
      hierarchy,
      allTeamsObject,
    });

    onChangeFilters(newFilters);
  };

  const moreFilter = _.find(filters, (f) => f.tag === FILTER_TAG.MORE_FILTER);

  return (
    <div
      className={classNames(
        classes.FilterDropdownManager,
        noBottomMargin && "noBottomMargin"
      )}
    >
      {moreFilter && (
        <AddFilterButton
          filter={moreFilter}
          onChangeFilter={handleChangeFilter}
        />
      )}

      {_.filter(filters, (filter) => isDisplayed(filter.tag, moreFilter)).map(
        (filter) => {
          const onDelete =
            moreFilter && (() => handleChangeFilter(filter.tag, null));

          return (
            <FilterDropdown
              key={filter.tag}
              filter={filter}
              onChangeFilter={handleChangeFilter}
              withoutArrow={!hasArrow(filter)}
              onDelete={onDelete}
              openOnMount={openOnMount && !!moreFilter}
            />
          );
        }
      )}
    </div>
  );
};

const hasArrow = (filter: IFilter) => {
  if (filter.type === FILTER_TYPE.DATE && !filter.singleSelection) {
    return false;
  }
  return true;
};

export default FilterDropdownManager;
