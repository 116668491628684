import { useEffect, useRef, useState } from "react";

import { Box, Fade, makeStyles } from "@material-ui/core";
import {
  CalendarEventStatus,
  CalendarEventType,
  computeEventsStatus,
  EventChangeScope,
  IDSource,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import CustomDialogBulkOperation from "components/Dialog/CustomDialogBulkOperation";
import { getQuery } from "components/Filter/Filter.utils";
import PrimaryfiltersDropdownManager from "components/Filter/PrimaryFiltersDropdownManager";
import { RequiredByKey } from "components/MetaExpressionWrapper";
import {
  getSignedInUser,
  mobileUserTeamMatesComposedSelector,
} from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useActions, useCalendarRange, useTranslations } from "hooks";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import CalendarContainerHeader from "./CalendarContainerHeader";
import AddVisitDialog from "./components/AddVisitDialog";
import Calendar from "./components/Calendar/Calendar";
import { getCalendarWorkingHours } from "./components/Calendar/utils/getTimesOfDay";
import { isRepeatingEvent } from "./components/Calendar/utils/reapeatingEventsUtils";
import { updateSelectedFieldUserAction } from "./redux/actionCreators";
import {
  createEventsActionFunction,
  deleteEventsActionFunction,
  fetchEventsActionFunction,
  updateEventsActionFunction,
} from "./redux/actions";
import {
  fetchItemsReferencesApiCall,
  TFetchItemsReferencesPayload,
} from "./redux/api";
import {
  getEvents,
  getIsFetchingEvents,
  getIsUserAManager,
  getSelectedFieldUser,
} from "./redux/selectors";
import { TNewEventPayload } from "./redux/types";
import RepeatingEventConfirmationDialog from "./RepeatingEventConfirmationDialog";
import { styles } from "./styles";
import SubcategoryManager from "./SubcategoryManager";
import { TCalendarFilterObject, TInitEvent } from "./types";
import { computeNewEvent } from "./utils/computeNewEvent";
import { getCsvBodyTemplate, parseEventsCSV } from "./utils/csvTools";
import { eventIsWithinAllowedHours } from "./utils/eventIsWithinAllowedHours";
import { getCurrentFieldUser } from "./utils/getCurrentFieldUser";
import { getEventPlaceHolders } from "./utils/getEventPlaceHolders";
import { getEventsToDisplay } from "./utils/getEventsToDisplay";
import { CALENDAR_FILTERS, prepareFilters } from "./utils/prepareFilters";

const useStyles = makeStyles(styles as any);

function CalendarContainer() {
  const client = useSelector(getSelectedClient);
  const scrollAnchor = useRef<HTMLDivElement>(null);
  const draggable = true;
  const classes = useStyles();
  const lang = useTranslations();
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const { date } = useCalendarRange();
  const startDate = moment.utc(date).utc().toDate();
  const dispatch = useDispatch();
  /* ------------------------------ REDUX ACTIONS ----------------------------- */
  const { minEventStartHour, maxEventStartHour } =
    getCalendarWorkingHours(client);
  const [
    createEventsAction,
    updateEventsAction,
    fetchEventsAction,
    deleteEventsAction,
  ] = useActions([
    createEventsActionFunction,
    updateEventsActionFunction,
    fetchEventsActionFunction,
    deleteEventsActionFunction,
  ]);

  /* -------------------------------- SELECTORS ------------------------------- */
  const isFetchingEvents = useSelector(getIsFetchingEvents);
  const eventsStored = useSelector(getEvents);
  const isManager = useSelector(getIsUserAManager);
  const signedInUser = useSelector(getSignedInUser);
  const allMobileTeamMates = useSelector(mobileUserTeamMatesComposedSelector);
  const selectedFieldUser = useSelector(getSelectedFieldUser);
  const linkedMobileUser = _.find(allMobileTeamMates, {
    email: signedInUser.email,
  });

  /* ------------------------------ LOCAL STATE ------------------------------ */
  const [newEventObject, setNewEventObject] = useState<
    Partial<TCalendarEvent> | undefined
  >(undefined);
  const [repeatDialogAction, setRepeatDialogAction] = useState<
    "DELETE" | "EDIT" | undefined
  >();
  const [openRepeatDialog, setOpenRepeatDialog] = useState(false);
  const [customerOptions, setCustomerOptions] = useState<IOption<string>[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogViewMode, setDialogViewmode] = useState<TViewMode>("CREATE");
  const [events, setEvents] = useState<TCalendarEvent[]>(eventsStored ?? []);
  const [currentDate, setCurrentDate] = useState(moment(date));
  const [filterQuery, setFilterQuery] = useState<TCalendarFilterObject>({
    [CALENDAR_FILTERS.USERS]: selectedFieldUser ? [selectedFieldUser] : [],
  });
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const currentFieldUser = getCurrentFieldUser(
    linkedMobileUser,
    isManager,
    filterQuery
  );
  const [selectedEvent, setSelectedEvent] = useState<
    TCalendarEvent | undefined
  >(undefined);
  const [initEvent, setInitEvent] = useState<TInitEvent>({
    assigned_to: currentFieldUser,
    start_time: moment.utc().toDate(),
    status: CalendarEventStatus.ONGOING,
  });
  const filtersPrepared = prepareFilters({
    mobileUsers: allMobileTeamMates,
    lang,
    filterQuery,
    displayFieldUserFilter: isManager,
  });

  const allowCreation = !_.isEmpty(currentFieldUser);
  const allowDisplayingEvents =
    !isFetchingEvents && !_.isUndefined(currentFieldUser);

  async function handlefetchEvents() {
    if (!currentFieldUser) return;
    const result = await fetchEventsAction({
      start_time: moment(currentDate).startOf("year").toDate(),
      end_time: moment(currentDate).endOf("year").toDate(),
      mobile_user_id: currentFieldUser,
    });
    if (!result) setEvents([]);
  }

  async function onReorGanizeEvents(reorganizedEvents: TCalendarEvent[]) {
    const changedEvents = _.filter(reorganizedEvents, (event) => {
      const currentEvent = _.find(events, { id: event.id });
      return !_.isEqual(event, currentEvent);
    });
    setEvents(reorganizedEvents);
    await updateEventsAction(changedEvents, undefined, true);
  }

  function onClickTimeSlot(dateTime: string) {
    setInitEvent({
      ...(initEvent || {}),
      assigned_to: currentFieldUser,
      start_time: moment.utc(dateTime).toDate(),
      id: IDSource(),
    });
    setDialogViewmode("CREATE");
    setOpenDialog(true);
  }

  function onAddVisit(value: TCalendarEvent, _name: string) {
    setEvents([value, ...events]);
  }

  async function onChangeFilters(filters: IFilter<any>[]) {
    setFilterQuery(getQuery(filters));
  }

  async function onUpdateFieldUser() {
    if (!currentFieldUser) return;
    setInitEvent({
      ...initEvent,
      start_time: initEvent?.start_time || moment.utc().toDate(),
      assigned_to: currentFieldUser,
    });
    const query: TFetchItemsReferencesPayload = {
      list_id: "customer",
      owner_ids: [currentFieldUser],
      limit: 1000,
      offset: 0,
    };
    dispatch(updateSelectedFieldUserAction(currentFieldUser));
    fetchItemsReferencesApiCall(query)
      .then((response) => {
        const { data } = response.data;
        setCustomerOptions(
          _.map(data, (reference) => ({
            key: reference.id,
            label: reference.name,
          }))
        );
      })
      .catch(() => {
        setCustomerOptions([]);
      });
  }

  async function onSaveVisit(event: IVisitEvent) {
    const newEvent = {
      ...event,
      type: CalendarEventType.VISIT,
    };
    const computedEvent = computeNewEvent(newEvent);
    onAddVisit(computedEvent, "");
    setInitEvent(undefined);
    await createEventsAction([computedEvent as TNewEventPayload]);
  }

  async function handleEditEvent(
    editedEvent: TCalendarEvent,
    scope?: EventChangeScope
  ) {
    const oldEvent = _.find(events, { id: editedEvent.id });
    const oldEvents = events;
    const newVisits = _.concat(
      _.compact(_.without(events, oldEvent)),
      editedEvent
    );
    setEvents(newVisits);
    try {
      await updateEventsAction([editedEvent], scope, true);
    } catch {
      setEvents(oldEvents);
    }
  }

  async function handleDeleteEvent(
    eventToDelete: TCalendarEvent,
    scope?: EventChangeScope
  ) {
    await deleteEventsAction(_.compact([eventToDelete.id]), scope);
  }

  function onClickAddSingleVisit() {
    setOpenDialog(true);
    setDialogViewmode("CREATE");
  }
  function onClickAddMultipleVisits() {
    setIsBulkModalOpen(true);
    setDialogViewmode("CREATE");
  }
  function onChangeDateRange(date: moment.Moment) {
    if (date.year() !== currentDate.year()) {
      setCurrentDate(date);
    }
  }
  function onCloseVisitDialog() {
    setInitEvent({
      assigned_to: currentFieldUser,
      start_time: moment.utc().toDate(),
      status: CalendarEventStatus.ONGOING,
    });
    setOpenDialog(false);
  }
  function onClickEditEvent(e: TCalendarEvent) {
    setInitEvent(e as RequiredByKey<TCalendarEvent, "assigned_to">);
    setOpenDialog(true);
    setDialogViewmode("EDIT");
  }

  function handleConfirmDialog(scope: EventChangeScope) {
    switch (repeatDialogAction) {
      case "DELETE":
        handleDeleteEvent(selectedEvent as TCalendarEvent, scope);
        break;
      case "EDIT":
        handleEditEvent(selectedEvent as TCalendarEvent, scope);
        break;
      default:
        break;
    }
    setSelectedEvent(undefined);
    setOpenRepeatDialog(false);
    setOpenDialog(false);
  }

  /* ------------------------------- USE EFFECTS ------------------------------ */
  useEffect(() => {
    if (isManager || currentFieldUser) {
      handlefetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, currentFieldUser]);

  useEffect(() => {
    onUpdateFieldUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterQuery[CALENDAR_FILTERS.USERS]), currentFieldUser]);

  useEffect(() => {
    setEvents(eventsStored ?? []);
  }, [eventsStored]);
  return (
    <SubcategoryManager>
      <Box className={classes.root}>
        <CalendarContainerHeader
          onChangeDateRange={onChangeDateRange}
          onClickAddMultipleVisits={onClickAddMultipleVisits}
          onClickAddSingleVisit={onClickAddSingleVisit}
          currentFieldUser={currentFieldUser}
          isManager={isManager}
        />
        <Box data-testid="field-user-filter">
          <PrimaryfiltersDropdownManager
            filters={filtersPrepared}
            onChangeFilters={onChangeFilters}
          />
        </Box>

        <Box position={"relative"} overflow={"hidden"}>
          <Fade in={allowCreation && openDialog}>
            <Box
              className={classes.addVisitDialogWrapper}
              height={calendarContainerRef.current?.clientHeight}
            >
              <AddVisitDialog
                key={initEvent?.id}
                onAddVisit={onSaveVisit}
                onEditEvent={(e) => {
                  if (isRepeatingEvent(e)) {
                    setSelectedEvent(e);
                    setRepeatDialogAction("EDIT");
                    setOpenRepeatDialog(true);
                  } else {
                    handleEditEvent(e);
                  }
                }}
                handleClose={onCloseVisitDialog}
                events={events}
                initEvent={initEvent as TNewEventPayload}
                customerOptions={customerOptions}
                viewMode={dialogViewMode}
                onChange={(event) => setNewEventObject(event)}
              />
            </Box>
          </Fade>

          <div ref={calendarContainerRef}>
            <Calendar
              scrollAnchor={scrollAnchor}
              draggable={draggable}
              defaultEvents={_.filter(
                computeEventsStatus(
                  getEventsToDisplay({
                    allowDisplayingEvents,
                    events,
                    filterQuery,
                  })
                ),
                (event) => {
                  return eventIsWithinAllowedHours({
                    event,
                    maxEventHour: maxEventStartHour,
                    minEventHour: minEventStartHour,
                  });
                }
              )}
              startDate={startDate}
              width="100%"
              minWidth="600px"
              onChange={onReorGanizeEvents}
              onClickTimeSlot={onClickTimeSlot}
              height={`calc(100vh - 280px)`}
              onEditEvent={onClickEditEvent}
              onClickDeleteEvent={(e) => {
                if (isRepeatingEvent(e)) {
                  setSelectedEvent(e);
                  setRepeatDialogAction("DELETE");
                  setOpenRepeatDialog(true);
                } else {
                  handleDeleteEvent(e);
                }
              }}
              eventPlaceHolders={getEventPlaceHolders({
                newEventObject,
                dialogViewMode,
                allowCreation,
                openDialog,
              })}
              activeBoardItems={
                dialogViewMode === "EDIT" && initEvent?.id ? [initEvent.id] : []
              }
            />
          </div>
        </Box>
      </Box>

      <CustomDialogBulkOperation
        lang={lang}
        csvBodyTemplate={getCsvBodyTemplate}
        modalNameInLang="modalNameInLang"
        onClose={() => {
          setIsBulkModalOpen(false);
        }}
        onAction={async (e) => {
          await createEventsAction(e as TNewEventPayload[], {
            runInBackground: _.size(e) > 100,
          });
        }}
        operationType={LANG_ACTIONS.UPLOAD}
        parseCSV={(lines) => {
          return parseEventsCSV(lines, lang);
        }}
        subCategoryInLang={SUB_CATEGORIES.CALENDAR_EVENT}
        isOpen={isBulkModalOpen}
      />
      <RepeatingEventConfirmationDialog
        open={openRepeatDialog}
        onClose={() => {
          setOpenRepeatDialog(false);
        }}
        onConfirm={handleConfirmDialog}
        viewMode={repeatDialogAction}
      />
    </SubcategoryManager>
  );
}

export default CalendarContainer;
