import { useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import {
  BaseTextFieldProps,
  Button,
  createTheme,
  makeStyles,
  ThemeOptions,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import classNames from "classnames";
import { MOMENT_TIMEZONES } from "fieldpro-tools";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";

import * as colors from "assets/colors";
import { appThemeOptions } from "containers/app/AppTheme";
import { TViewMode } from "model/application/modal/CreateEditModal";

import InputBaseLayout from "../InputBaseLayout";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import InputViewValue from "../InputViewValue";

export interface IInputTimePropsBase
  extends Omit<BaseTextFieldProps, "lang" | "error"> {
  viewMode?: TViewMode;
  disabled?: boolean;
  name: string;
  error?: string;
  required?: boolean;
  clearable?: boolean;
  isUndetermined?: boolean;
  lang: {
    title?: string;
    tooltip?: string;
  };
  viewStacked?: boolean;
  inLocalTime?: boolean;
  defaultValue?: Date;
  onChange: (value: Date | null, name: string) => void;
  timezone?: MOMENT_TIMEZONES;
}
export type TInputTimeProps = IInputTimePropsBase &
  Omit<IInputBaseLayout, keyof IInputTimePropsBase>;

const MuiPickersThemeOptions = {
  ...appThemeOptions,
  overrides: {
    ...(appThemeOptions.overrides || {}),
    MuiButton: {
      textPrimary: {
        padding: "0",
      },
    },
  },
} as ThemeOptions;

const MuiPickersTheme = createTheme(MuiPickersThemeOptions);

const useStyles = makeStyles({
  TimePicker: {
    margin: 0,
  },
  dateText: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  undeterminedInput: {
    color: colors.secondaryColor,
  },
});

const InputTime = ({
  viewMode = "CREATE",
  disabled = false,
  name,
  defaultValue,
  error,
  required,
  clearable = false,
  isUndetermined: isUndeterminedProps = false,
  onChange,
  lang,
  viewStacked = false,
  inLocalTime = false,
  timezone,
  ...rest
}: TInputTimeProps) => {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState<Date | null | undefined>(
    defaultValue ? defaultValue : null
  );

  useEffect(() => {
    setSelectedTime(defaultValue);
  }, [defaultValue]);

  const [isUndetermined, setIsUndetermined] = useState(isUndeterminedProps);

  const handleTimeChange = (momentDate: Moment | null) => {
    const date = momentDate?.toDate();
    setSelectedTime(date);
    setIsUndetermined(false);
    onChange(date as any, name);
  };

  useEffect(() => {
    return () => {
      moment.tz.setDefault(moment.tz.guess());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  moment.tz.setDefault(timezone || moment.tz.guess());
  const DateTimePicker =
    viewMode === "VIEW"
      ? () => null // small optim in VIEW mode
      : () => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
              className={classNames(classes.TimePicker, `viewMode-${viewMode}`)}
              autoOk
              fullWidth
              // variant={viewMode === "CREATE" ? "inline" : "dialog"}
              variant="dialog" // NOTE: inline mode does not work well...
              inputVariant="outlined"
              disabled={disabled}
              margin="dense"
              required={required}
              invalidLabel={"Several values..."}
              error={!_.isEmpty(error)}
              value={selectedTime}
              onChange={(date) => handleTimeChange(date as Moment | null)}
              clearable={clearable}
              clearLabel={
                <Button color="default" disableElevation>
                  <span style={{ color: colors.GreyDark }}>Clear</span>
                </Button>
              }
              cancelLabel={
                <Button color="default" disableElevation>
                  <span style={{ color: colors.GreyDark }}>Cancel</span>
                </Button>
              }
              okLabel={
                <Button color="secondary" variant="contained" disableElevation>
                  Apply
                </Button>
              }
              InputProps={{
                className: isUndetermined
                  ? classes.undeterminedInput
                  : undefined,
              }}
            />
          </MuiPickersUtilsProvider>
        );

  function getTime() {
    if (inLocalTime) {
      return moment(selectedTime).format("hh:mm A");
    } else {
      //parse zone wont overwrite existing tz info
      return moment.parseZone(selectedTime).format("hh:mm A");
    }
  }

  return (
    <ThemeProvider theme={MuiPickersTheme}>
      <InputBaseLayout
        {...rest}
        label={lang?.title}
        tooltip={lang?.tooltip}
        required={required}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
      >
        {viewMode === "VIEW" ? (
          <InputViewValue value={selectedTime ? getTime() : ""} />
        ) : (
          <DateTimePicker />
        )}
      </InputBaseLayout>
    </ThemeProvider>
  );
};

export default InputTime;
