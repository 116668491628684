import { getLang } from "containers/authentication/redux/selector";
import { showNotificationActionCreator } from "containers/notifications/actionCreator";
import * as levels from "containers/notifications/actionLevels";
import * as notificationTypes from "containers/notifications/actionTypes";
import * as lang from "lang";
import { getSuccessNotificationMessage } from "lang/utils";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IIntegration } from "model/entities/Job";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import {
  createIntegrationBeginActionCreator,
  createIntegrationFailureActionCreator,
  createIntegrationSuccessActionCreator,
  deleteIntegrationBeginActionCreator,
  deleteIntegrationFailureActionCreator,
  deleteIntegrationSuccessActionCreator,
  fetchAllIntegrationsBeginActionCreator,
  fetchAllIntegrationsFailureActionCreator,
  fetchAllIntegrationsSuccessActionCreator,
  updateIntegrationBeginActionCreator,
  updateIntegrationFailureActionCreator,
  updateIntegrationSuccessActionCreator,
} from "./actionCreators";
import {
  createIntegrationApiCall,
  deleteIntegrationApiCall,
  fetchIntegrationsApiCall,
  updateIntegrationApiCall,
} from "./api";

/**
 * Create Integration action which creates an integration for embedding
 * id: string;
 * title: string;
 * type:string;
 * url:string;
 * api_key:string
 * fields_mapping: {origin_variable:string,destination_variable:string} [];
 * webhooks: string [];
 *  @param {String} id primary_key the integration in the tables
 * @param {String} body title of the integration
 * @param {String} type type of the integration
 * @param {String} url url of the integration
 * @param {String} api_key key of the integration
 * @param {Object []} fields_mapping fields of fieldpro that should match match integration fields.
 * @param {String []} webhooks webhooks attached to the integrations
 * @returns {Function}
 */
export const createIntegrationAction: ICreateIntegrationActionFunc = (
  actionName: string,
  integration: IIntegration
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createIntegrationBeginActionCreator());

    return createIntegrationApiCall(actionName, integration)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const integrationWithId = {
          ...integration,
          id: data.integration.id,
        };
        dispatch(ajaxSuccessAction());
        dispatch(createIntegrationSuccessActionCreator(integrationWithId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.INTEGRATION
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateIntegration",
          error,
          createIntegrationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Update Integration action which creates an Integration for embedding
 * @param {Object} integration Integration object to edit
 * @returns {Function}
 */
export const updateIntegrationAction: TUpdateIntegrationActionFunc = (
  actionName,
  integration
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateIntegrationBeginActionCreator());
    return updateIntegrationApiCall(actionName, { ...integration })
      .then(() => {
        dispatch(ajaxSuccessAction());
        dispatch(updateIntegrationSuccessActionCreator(integration));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.INTEGRATION,
              integration.title
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateIntegration",
          error,
          updateIntegrationFailureActionCreator,
          currLang
        );
      });
  };
};
/**
 * Action to fetch allIntegrations
 * @returns {Function}
 */
export const fetchAllIntegrationsAction: TFetchAllIntegrationsActionFunc =
  () => {
    return (dispatch, getState) => {
      const currLang = (lang as any as TLang)[getLang(getState())];

      dispatch(ajaxRequestAction());
      dispatch(fetchAllIntegrationsBeginActionCreator());
      return fetchIntegrationsApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { integrations } = data;
          dispatch(ajaxSuccessAction());
          dispatch(fetchAllIntegrationsSuccessActionCreator(integrations));
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllIntegrationsError",
            error,
            fetchAllIntegrationsFailureActionCreator,
            currLang
          );
        });
    };
  };
/**
 * Delete Integration Action dispatches action creators to redux store and makes api calls to delete a integration by id
 * @param {String} integrationId Integration id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteIntegrationAction: TDeleteIntegrationActionFunc = (
  actionName,
  integrationId
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteIntegrationBeginActionCreator());
    return deleteIntegrationApiCall(actionName, integrationId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteIntegrationSuccessActionCreator(integrationId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.INTEGRATION
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteIntegrationError",
          error,
          deleteIntegrationFailureActionCreator,
          currLang
        );
      });
  };
};
export type ICreateIntegrationActionFunc = (
  actionName: string,
  integrationId: IIntegration
) => IDispatchAndGetState<void>;
export type TUpdateIntegrationActionFunc = (
  actionName: string,
  integrationId: IIntegration
) => IDispatchAndGetState<any>;
export type TFetchAllIntegrationsActionFunc = () => IDispatchAndGetState<void>;
export type TDeleteIntegrationActionFunc = (
  actionName: string,
  integrationId: string
) => IDispatchAndGetState<void>;
export type IRunIntegrationJobActionFunc = (
  actionName: string,
  integration: IIntegration
) => IDispatchAndGetState<void>;
export interface IIntegrationActions {
  createIntegrationAction: ICreateIntegrationActionFunc;
  updateIntegrationAction: TUpdateIntegrationActionFunc;
  fetchAllIntegrationsAction: TFetchAllIntegrationsActionFunc;
  deleteIntegrationAction: TDeleteIntegrationActionFunc;
}

const actions: IIntegrationActions = {
  createIntegrationAction: createIntegrationAction,
  updateIntegrationAction: updateIntegrationAction,
  fetchAllIntegrationsAction: fetchAllIntegrationsAction,
  deleteIntegrationAction: deleteIntegrationAction,
};

export default actions;
