


export default (theme: any) => ({
  ClearFilterButton: {
    width: "154px",
    display: "flex",
    marginTop: "20px",
    alignItems: "center",
    background: theme.palette.secondary.main,
    borderRadius: "4px",
    border: "0px solid !important",
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: ".5",
    },
    "box-shadow": "none",
  },

  EmptyDescription: {
    fontSize: "16px",
    fontWeight: 500,
    width: "467px",
    whiteSpace: "normal",
  },
});
