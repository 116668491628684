import { useEffect, useState } from "react";

import { Box, Divider, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Black, BlueChalk } from "assets/colors";
import CircularLoaderComponent from "components/Progress/CustomCircularProgress";
import { listsComposedSelector } from "containers/lists/redux/selectors";
import { fetchAugmentedActivityReportsWithoutStoring } from "containers/workflows/redux/actions";
import { allActivitiesSelector } from "containers/workflows/redux/selectors";
import { useActions } from "hooks/useActions";
import { IFormState } from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
  IWorkflowStep,
} from "model/entities/Workflow";

import ActivityReportForm from "../ActivityReportForm";
import { getActivityColumnTypes } from "../utils/activityReports";
import { formatAugmentedActivityReport } from "../utils/activityReports/formatAugmentedActivityReport";
import { flattenAugmentedActivityReport } from "../utils/flattenActivityReport";

const useStyle = makeStyles({
  ReviewedInnerBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  LeftBox: {
    width: "66%",
  },

  RightBox: {
    display: "flex",
    columnGap: "10px",
    marginLeft: "20px",
    width: "34%",
  },

  RightTitle: {
    color: Black,
    fontWeight: 600,
    fontSize: "15px",
  },

  RightDivider: {
    background: BlueChalk,
  },
});

interface IActivityReportReviewedDialogInnerContent {
  currentActivity: IActivity;
  currentReport: IActivityReportInTable;
  formState: IFormState<IActivityReportInTable>;
  viewMode: TViewMode;
  workflow: IWorkflow;
  stepReviewed?: IWorkflowStep;
}

const ActivityReportReviewedDialogInnerContent = ({
  currentActivity,
  currentReport,
  formState,
  viewMode,
  workflow,
  stepReviewed,
}: IActivityReportReviewedDialogInnerContent) => {
  const classes = useStyle();
  const lang = useTranslations();

  const lists = useSelector(listsComposedSelector);

  const [loading, setLoading] = useState(true);
  const [reviewedReport, setReviewedReport] = useState<
    IActivityReportInTable | undefined
  >(undefined);

  const activities = useSelector(allActivitiesSelector);

  const fetchActivityReports = useActions(
    fetchAugmentedActivityReportsWithoutStoring
  );

  const activityToReviewId = stepReviewed?.schema_id;

  useEffect(() => {
    (async () => {
      if (!activityToReviewId) {
        return;
      }

      setLoading(true);

      const response = await fetchActivityReports(
        activityToReviewId,
        // NOTE: with these filters, we are expecting at most 1 report
        {
          _workflow_report_id: currentReport?._workflow_report_id,
          _step_id: stepReviewed?.id,
          // These 2 are optional ? _workflow_report_id x _step_id is should be unique already
          _workflow_id: workflow.id,
          _activity_id: stepReviewed?.schema_id,
        }
      );

      const { reports } = response ?? {};

      const flatReport = flattenAugmentedActivityReport(reports?.[0]);

      if (!flatReport) {
        setLoading(false);
        return;
      }

      const reviewedReportFormatted = formatAugmentedActivityReport(
        flatReport,
        false,
        getActivityColumnTypes(currentActivity, lang),
        currentActivity?.questionnaire.questions,
        lists,
        lang
      );

      setReviewedReport(reviewedReportFormatted);

      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivity]);

  const reviewedActivity = _.find(activities, { id: activityToReviewId });

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      {!loading && reviewedReport && reviewedActivity ? (
        <Box className={classes.ReviewedInnerBox}>
          <Box className={classes.LeftBox}>
            <ActivityReportForm
              activity={reviewedActivity}
              report={reviewedReport}
              formState={formState}
              lists={lists}
              viewMode={"VIEW"}
            />
          </Box>
          <Box className={classes.RightBox}>
            <Divider
              flexItem
              orientation="vertical"
              className={classes.RightDivider}
            />
            <Box style={{ width: "100%" }}>
              <ReviewFormHeader />
              <Box>
                <ActivityReportForm
                  activity={currentActivity}
                  report={currentReport}
                  formState={formState}
                  lists={lists}
                  viewMode={viewMode}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            flexGrow: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularLoaderComponent />
        </Box>
      )}
    </Box>
  );
};

const ReviewFormHeader = () => {
  const classes = useStyle();
  return <h2 className={classes.RightTitle}>Review</h2>;
};

export default ActivityReportReviewedDialogInnerContent;
