import {
  AuthenticationBusinessError,
  CLIENT_STATUS,
  IBillingOwner,
  IClientBilling,
  IClientLicensePlan,
  IClientSupportPack,
  TPaymentMethod,
} from "fieldpro-tools";
import { AnyAction, Dispatch } from "redux";

import { changeLanguageSuccessAction } from "containers/authentication/redux/actionCreator";
import { getDefaultProfiles } from "model/application/ActionCode";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IClient, TLightClient } from "model/entities/Client";
import ITeam from "model/entities/Team";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../redux/actions/appActions";
import { getLang } from "../../authentication/redux/selector";
import { clearDashboardDataAction } from "../../dashboards/redux/actionCreators";
import { clearListDataAction } from "../../lists/redux/actionCreators";
import { showNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationLevels from "../../notifications/actionLevels";
import * as notificationTypes from "../../notifications/actionTypes";
import { clearTeamDataAction } from "../../teams/redux/actionCreators";
import { fetchTeamsForClientApiCall } from "../../teams/redux/api";
import { clearMobileUserDataAction } from "../../users/redux/actionCreator/mobileUserActionCreator";
import { clearWebUserDataActionCreator } from "../../users/redux/actionCreator/webUserActionCreator";
import { clearWorkflowDataAction } from "../../workflows/redux/actionCreators";
import {
  archiveClientBeginActionCreator,
  archiveClientFailureActionCreator,
  archiveClientSuccessActionCreator,
  changeSelectedClientActionCreator,
  createClientBeginActionCreator,
  createClientFailureActionCreator,
  createClientSuccessActionCreator,
  deleteClientBeginActionCreator,
  deleteClientFailureActionCreator,
  deleteClientSuccessActionCreator,
  editClientBillingInfoSuccessActionCreator,
  editClientPackSuccessActionCreator,
  editClientPlanSuccessActionCreator,
  fetchAllClientsBeginActionCreator,
  fetchAllClientsFailureActionCreator,
  fetchAllClientsSuccessActionCreator,
  fetchClientBeginActionCreator,
  fetchClientFailureActionCreator,
  fetchClientSuccessActionCreator,
  fetchStripeInvoicesFailureActionCreator,
  fetchStripeInvoicesSuccessActionCreator,
  fetchStripeSessionLinkBeginActionCreator,
  fetchStripeSessionLinkFailureActionCreator,
  fetchStripeSessionLinkSuccessActionCreator,
  fetchTeamsByClientBeginActionCreator,
  fetchTeamsByClientFailureActionCreator,
  fetchTeamsByClientSuccessActionCreator,
  replicateClientBeginActionCreator,
  replicateClientFailureActionCreator,
  replicateClientSuccessActionCreator,
  restoreClientBeginActionCreator,
  restoreClientFailureActionCreator,
  restoreClientSuccessActionCreator,
  setClientLiveBeginActionCreator,
  setClientLiveFailureActionCreator,
  setClientLiveSuccessActionCreator,
  setForceMaintenanceBeginActionCreator,
  setForceMaintenanceFailureActionCreator,
  setForceMaintenanceSuccessActionCreator,
  setSelectedClientActionCreator,
  updateClientBeginActionCreator,
  updateClientFailureActionCreator,
  updateClientSuccessActionCreator,
  uploadFileBeginActionCreator,
  uploadFileFailureActionCreator,
  uploadFileSuccessActionCreator,
} from "./actionCreators";
import {
  archiveClientApiCall,
  cancelSubscriptionApiCall,
  createCheckoutSessionApiCall,
  createClientApiCall,
  createSubscriptionApiCall,
  deleteClientApiCall,
  downgradeToStarterApiCall,
  editClientBillingInfoApiCall,
  editClientPackApiCall,
  editClientPlanApiCall,
  fetchAllClientsApiCall,
  fetchClientApiCall,
  getBillingPlansApiCall,
  getClientAdditionalInfosApiCall,
  getDBAccessCall,
  getStripeInvoicesApiCall,
  getStripeSessionLinkApiCall,
  IBillingPlans,
  ISubscriptionDetails,
  replicateClientApiCall,
  restoreClientApiCall,
  resumeSubscriptionApiCall,
  setClientLiveApiCall,
  setForceMaintenanceApiCall,
  TCreateClientResponse,
  TFetchAllClientsResponse,
  TFetchClientResponse,
  TPackReference,
  TPlanReference,
  TReplicateClientResponse,
  TUploadFileResponse,
  updateClientApiCall,
  uploadFileApiCall,
} from "./api";
import { clientsByIdSelector, getSelectedClient } from "./selectors";
import { prepareClientForFrontend } from "./utils";

/**
 * Create Client Action dispatches action creators to redux store and makes api calls to create a license
 * @param {String} name Name of Client
 * @param {String} description Description of client
 * @param {String} logo Logo image filename
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function createClientAction(
  actionName: string,
  newClient: IClient
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());
    dispatch(createClientBeginActionCreator());

    return new Promise(function (resolve) {
      createClientApiCall(actionName, newClient)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TCreateClientResponse>(
              serverResponse
            );
          const { id, dbname } = data;

          const client = {
            ...newClient,
            id,
            dbname,
            // TODO: overriding this here is error prone
            meta_hierarchy_dependencies: {},
            active: true,
          };

          dispatch(ajaxSuccessAction());
          dispatch(createClientSuccessActionCreator(client));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.CLIENT,
                newClient.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateClientError",
            error,
            createClientFailureActionCreator,
            currLang
          );

          resolve();
        });
    });
  };
}

/**
 * Fetch a single Client Action. This dispatches action creators to redux store and makes api calls to
 * fetch a single license by id
 * @param {String} id Id of the Client to fetch
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchClientAction(id: string): IDispatchAndGetState<any> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchClientBeginActionCreator());
    return new Promise((resolve) => {
      fetchClientApiCall(id)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TFetchClientResponse>(
              serverResponse
            );
          const { client } = data;
          const formattedClient = prepareClientForFrontend(client);
          dispatch(ajaxSuccessAction());
          dispatch(fetchClientSuccessActionCreator(formattedClient));
          resolve(formattedClient);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchClientError",
            error,
            fetchClientFailureActionCreator,
            currLang
          );

          resolve(undefined);
        });
    });
  };
}

/**
 * Fetch all Clients Action dispatches action creators to redux store and makes api calls to fetch all
 * client accounts
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function fetchAllClientsAction(): IDispatchAndGetState<TLightClient[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchAllClientsBeginActionCreator());
    // clear all the persistant data from the store
    dispatch(clearListDataAction());
    dispatch(clearDashboardDataAction());
    dispatch(clearTeamDataAction());
    dispatch(clearMobileUserDataAction());
    dispatch(clearWebUserDataActionCreator());
    dispatch(clearWorkflowDataAction());

    return new Promise(function (resolve, reject) {
      fetchAllClientsApiCall()
        .then((serverResponse) => {
          const { clients } =
            extractDataAndCheckErrorStatus<TFetchAllClientsResponse>(
              serverResponse
            ) || {};

          if (clients.length === 0) {
            // a bit dirty message, needs to be reworked in the future if need be. But at least it won't spam like before with this message.
            alert(
              "Something went wrong, you seem not having access to any client... Please contact your administrator."
            );
            throw new AuthenticationBusinessError(
              `Something went wrong, you seem not having access to any client... Please contact your administrator.`
            );
          }

          dispatch(ajaxSuccessAction());
          dispatch(fetchAllClientsSuccessActionCreator(clients));
          resolve(clients);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchAllClientsError",
            error,
            fetchAllClientsFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Replicate Client Action dispatches action creators to redux store and makes api call to duplicate client
 * @param {String} id Id of Client to be replicated
 * @param {String} name Name of client to be created
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function replicateClientAction(
  actionName: string,
  oldClient: IClient,
  newClientName: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(replicateClientBeginActionCreator());

    return new Promise(function (resolve, reject) {
      replicateClientApiCall(actionName, oldClient.id, newClientName)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TReplicateClientResponse>(
              serverResponse
            );
          const { id, dbname } = data;
          const client = {
            ...oldClient,
            id,
            dbname,
            name: newClientName,
            use_dedicated_db: true,
            access_right_profiles:
              (oldClient as any).profiles &&
              (oldClient as any).profiles.length !== 0
                ? (oldClient as any).profiles
                : getDefaultProfiles(),
            active: true,
          };
          dispatch(ajaxSuccessAction());
          dispatch(replicateClientSuccessActionCreator(client));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.REPLICATE,
                SUB_CATEGORIES.CLIENT,
                oldClient.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ReplicateClientError",
            error,
            replicateClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}
export type IFetchStripeSessionLinkActionFunc = (
  clientId: string
) => IDispatchAndGetState<string>;
/**
 * Action to fetch stripe customer session link
 * @returns {Function}
 */
export const fetchStripeSessionLinkAction: IFetchStripeSessionLinkActionFunc = (
  clientId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchStripeSessionLinkBeginActionCreator());
    return new Promise((res) => {
      getStripeSessionLinkApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchStripeSessionLinkSuccessActionCreator(data.session_link)
          );
          res(data.session_link);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchStripeSessionLinkError",
            error,
            fetchStripeSessionLinkFailureActionCreator,
            currLang
          );
        });
    });
  };
};

/**
 * Action to fetch stripe customer session link
 * @returns {Function}
 */
export const fetchStripeInvoicesForClient = (parameters?: {
  client_id: string;
  starting_after?: string;
}) => {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    return new Promise((resolve, reject) => {
      getStripeInvoicesApiCall(parameters)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const { invoices, hasMore } = data;
          dispatch(ajaxSuccessAction());
          dispatch(
            fetchStripeInvoicesSuccessActionCreator(
              invoices,
              hasMore || false,
              parameters
            )
          );
          resolve({ invoices, hasMore });
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchStripeInvoicesError",
            error,
            fetchStripeInvoicesFailureActionCreator,
            currLang
          );

          reject(error);
        });
    });
  };
};

// FIXME: a refacto has to be made here to be able to fetch teams of a client
export function fetchTeamsByClient(): IDispatchAndGetState<ITeam[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTeamsByClientBeginActionCreator());

    return new Promise(function (resolve, reject) {
      fetchTeamsForClientApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus<any>(serverResponse);
          const { teams } = data;

          dispatch(ajaxSuccessAction());
          dispatch(fetchTeamsByClientSuccessActionCreator(teams));
          resolve(teams);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "FetchTeamsByClientError",
            error,
            fetchTeamsByClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Updates a given Client Action dispatches action creators to redux store and makes api calls to fetch all license
 * @param {String} id Id of client to update
 * @param {Object} updatedClient details of the updated client
 * */
export function updateClientAction(
  actionName: string,
  id: string,
  updatedClient: IClient
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateClientBeginActionCreator());

    const client = clientsByIdSelector(getState())[id];
    const selectedClientId = getSelectedClient(getState())?.id;

    return new Promise(function (resolve, reject) {
      updateClientApiCall(actionName, id, updatedClient)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);
          const clientForReducer = {
            ...client,
            ...updatedClient,
          };

          dispatch(ajaxSuccessAction());

          dispatch(updateClientSuccessActionCreator(clientForReducer));

          if (id === selectedClientId && updatedClient.language) {
            dispatch(changeLanguageSuccessAction(updatedClient.language));
          }

          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT,
                clientForReducer.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UpdateClientError",
            error,
            updateClientFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Start the force maintenance action (restart the default workflows)
 * @param {String} id Id of client to update
 * */
export function forceMaintenance(
  actionName: string,
  id: string,
  runAll?: boolean
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(setForceMaintenanceBeginActionCreator());

    const client = clientsByIdSelector(getState())[id];

    return new Promise(function (resolve, reject) {
      setForceMaintenanceApiCall(actionName, id, runAll)
        .then((serverResponse) => {
          extractDataAndCheckErrorStatus(serverResponse);

          dispatch(ajaxSuccessAction());
          dispatch(setForceMaintenanceSuccessActionCreator(client));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT,
                client.name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "SetForceMaintenanceError",
            error,
            setForceMaintenanceFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Updates a given Client Action dispatches action creators to redux store and makes api calls to fetch all license
 * @param {String} id Id of client to update
 * */
export function setClientLiveAction(id: string): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(setClientLiveBeginActionCreator());

    const client = clientsByIdSelector(getState())[id];

    return new Promise(function (resolve, reject) {
      setClientLiveApiCall(id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          const status: "live" | "pilot" | undefined = "live";
          const clientForReducer = {
            ...client,
            dbname: data.dbname,
            status: status as CLIENT_STATUS,
          };

          dispatch(ajaxSuccessAction());
          dispatch(setClientLiveSuccessActionCreator(clientForReducer));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT,
                (clientForReducer as any).name
              )
            )
          );
          resolve();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "SetClientLiveError",
            error,
            setClientLiveFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Delete Client Action dispatches action creators to redux store and makes api calls to delete a client by id
 * @param {String} id Client id to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export function deleteClientAction(
  actionName: string,
  id: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteClientBeginActionCreator());

    return deleteClientApiCall(actionName, id)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteClientSuccessActionCreator(id));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteClientError",
          error,
          deleteClientFailureActionCreator,
          currLang
        );
      });
  };
}

/**
 * Archive client action
 * @param {String} clientId
 * @returns {Function}
 */
export function archiveClientAction(
  actionName: string,
  clientId: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveClientBeginActionCreator());
    return archiveClientApiCall(actionName, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveClientSuccessActionCreator(clientId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveClientError",
          error,
          archiveClientFailureActionCreator,
          currLang
        );
      });
  };
}

/**
 * Restore client action
 * @param {String} clientId
 * @returns {Function}
 */
export function restoreClientAction(
  actionName: string,
  clientId: string,
  name: string
): IDispatchAndGetState<void> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreClientBeginActionCreator());
    return restoreClientApiCall(actionName, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreClientSuccessActionCreator(clientId));
        dispatch(
          showNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.CLIENT,
              name
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreClientError",
          error,
          restoreClientFailureActionCreator,
          currLang
        );
      });
  };
}

export function uploadFileAction(
  actionName: string,
  file: any,
  fileName: string
): IDispatchAndGetState<{ filename: string; url: string }[]> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(uploadFileBeginActionCreator());

    return new Promise((resolve, reject) => {
      uploadFileApiCall(actionName, file, fileName)
        .then((serverResponse) => {
          const data =
            extractDataAndCheckErrorStatus<TUploadFileResponse>(serverResponse);
          const { urls } = data;
          dispatch(ajaxSuccessAction());
          dispatch(uploadFileSuccessActionCreator(data as any));
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.UPLOAD,
                SUB_CATEGORIES.CLIENT
              )
            )
          );
          resolve(urls);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "UploadFileError",
            error,
            uploadFileFailureActionCreator,
            currLang
          );
          reject(error);
        });
    });
  };
}

/**
 * Get the username and password for each databases
 * @return {Function} Return a function that has a dispatch function and an optional param getState()
 * */
export function getDBAccessAction(id: string): IDispatchAndGetState<any> {
  return (dispatch) => {
    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      getDBAccessCall(id)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

/**
 * Get extra info for the client like possible owners
 */
export function getClientAdditionalInfosAction(
  clientId: string
): IDispatchAndGetState<any> {
  return (dispatch) => {
    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      getClientAdditionalInfosApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.owners);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

/**
 * Select client action for dispatching action creator to select a current client from Clients
 * Table. This is used to fetch Licenses (Mobile and Web) for the given client
 * @param {String} clientId
 * @returns {Function} Redux Thunk function with dispatch and getState
 */
export function setSelectedClientAction(clientId: string): any {
  return (dispatch: Dispatch, getState: any) => {
    const client = clientsByIdSelector(getState())[clientId];
    dispatch(setSelectedClientActionCreator(client));
  };
}

export function changeSelectedClientAction(clientId: string): any {
  return (dispatch: Dispatch, getState: any) => {
    const client = clientsByIdSelector(getState())[clientId];
    dispatch(changeSelectedClientActionCreator(client));
  };
}

/**********************************************************************
 *
 *  Billing
 *
 **********************************************************************/

export function getBillingPlansAction(): IDispatchAndGetState<
  IBillingPlans["plans"]
> {
  return (dispatch) => {
    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      getBillingPlansApiCall()
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.plans);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

interface ICreateCheckoutSession {
  clientId: string;
  withTrial?: boolean;
  // numberOfLicenses: number;
  // priceId: string;
}

export function createCheckoutSessionAction({
  clientId,
  withTrial,
}: // numberOfLicenses,
// priceId,
ICreateCheckoutSession): IDispatchAndGetState<string> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      createCheckoutSessionApiCall({
        clientId,
        withTrial,
        // numberOfLicenses,
        // priceId,
      })
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.url);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateCheckoutSession",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}

export function createSubscriptionAction(
  clientId: string,
  subscriptionDetails: ISubscriptionDetails
): IDispatchAndGetState<IClientBilling> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      createSubscriptionApiCall(clientId, subscriptionDetails)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);

          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CREATE,
                SUB_CATEGORIES.CLIENT_SUBSCRIPTION
              )
            )
          );
          resolve(data.billing);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CreateClientSubscription",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}

interface IDowngradeToStarterAction {
  clientId: string;
}

export function downgradeToStarterAction({
  clientId,
}: IDowngradeToStarterAction): IDispatchAndGetState<boolean> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve) {
      downgradeToStarterApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.success);
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.DOWNGRADE,
                SUB_CATEGORIES.CLIENT_SUBSCRIPTION
              )
            )
          );
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "DowngradeToStarter",
            error,
            null,
            currLang
          );
          resolve(false);
        });
    });
  };
}

type TCallBack = () => void;
export interface IUpdateSubscriptionAction {
  clientId: string;
  atPeriodEnd?: boolean;
  onSuccess?: TCallBack | undefined;
  onFail?: TCallBack | undefined;
  onFinally?: TCallBack | undefined;
}

export function cancelSubscriptionAction({
  clientId,
  atPeriodEnd,
  onFail,
  onFinally,
  onSuccess,
}: IUpdateSubscriptionAction): IDispatchAndGetState<boolean> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      cancelSubscriptionApiCall(clientId, atPeriodEnd)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.success);
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.CANCEL,
                SUB_CATEGORIES.CLIENT_SUBSCRIPTION
              )
            )
          );
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "CancelClientSubscription",
            error,
            null,
            currLang
          );

          if (onFail) {
            onFail();
          }
          reject(false);
        })
        .finally(() => {
          if (onFinally) {
            onFinally();
          }
        });
    });
  };
}

export function resumeSubscriptionAction({
  clientId,
  onFail,
  onFinally,
  onSuccess,
}: IUpdateSubscriptionAction): IDispatchAndGetState<boolean> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      resumeSubscriptionApiCall(clientId)
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          resolve(data.success);
          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.RESUME,
                SUB_CATEGORIES.CLIENT_SUBSCRIPTION
              )
            )
          );
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ResumeClientSubscription",
            error,
            null,
            currLang
          );

          if (onFail) {
            onFail();
          }
          reject(false);
        })
        .finally(() => {
          if (onFinally) {
            onFinally();
          }
        });
    });
  };
}

export function editClientBillingInfoAction(
  clientId: string,
  owner?: IBillingOwner,
  paymentMethod?: TPaymentMethod
): IDispatchAndGetState<IClientBilling> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      editClientBillingInfoApiCall(clientId, owner, paymentMethod)
        .then((serverResponse) => {
          const { billing } =
            extractDataAndCheckErrorStatus(serverResponse) || {};

          dispatch(ajaxSuccessAction());
          dispatch(
            editClientBillingInfoSuccessActionCreator(clientId, billing)
          );

          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT_BILLING
              )
            )
          );

          resolve(billing);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "editClientBillingInfoAction",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}

export function editClientPlanAction(
  clientId: string,
  plan: TPlanReference
): IDispatchAndGetState<IClientLicensePlan> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      editClientPlanApiCall(clientId, plan)
        .then((serverResponse) => {
          const { license_plan } =
            extractDataAndCheckErrorStatus(serverResponse) || {};

          dispatch(ajaxSuccessAction());
          dispatch(editClientPlanSuccessActionCreator(clientId, license_plan));

          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT_PLAN
              )
            )
          );

          resolve(license_plan);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "editClientPlanAction",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}

export function editClientPackAction(
  clientId: string,
  packReference: TPackReference
): IDispatchAndGetState<IClientSupportPack> {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];

    dispatch(ajaxRequestAction());

    return new Promise(function (resolve, reject) {
      editClientPackApiCall(clientId, packReference)
        .then((serverResponse) => {
          const { support_pack } =
            extractDataAndCheckErrorStatus(serverResponse) || {};

          dispatch(ajaxSuccessAction());
          dispatch(editClientPackSuccessActionCreator(clientId, support_pack));

          dispatch(
            showNotificationActionCreator(
              notificationTypes.NOTIFICATION_SUCCESS,
              notificationLevels.NOTIFICATION_LEVEL_SUCCESS,
              getSuccessNotificationMessage(
                currLang,
                LANG_ACTIONS.EDIT,
                SUB_CATEGORIES.CLIENT_PACK
              )
            )
          );

          resolve(support_pack);
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "editClientPackAction",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}

export function refreshClientStateAction(
  clientId: string,
  callback?: (client: IClient) => void
): IDispatchAndGetState<IClient> {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const currLang = lang[getLang(getState())];
      dispatch(updateClientBeginActionCreator());

      fetchClientApiCall(clientId)
        .then((response) => {
          const freshClient = response.data.data.client;
          const selectedClient = getSelectedClient(getState());
          const client = {
            ...selectedClient,
            ...freshClient,
          };
          dispatch(updateClientSuccessActionCreator(client));

          resolve(client);

          if (callback) {
            callback(client);
          }
          dispatch(ajaxSuccessAction());
        })
        .catch((error) => {
          dispatch(updateClientFailureActionCreator(error));
          treatErrorNotification(
            dispatch,
            "refreshClientStateAction",
            error,
            null,
            currLang
          );
          reject(error);
        });
    });
  };
}
