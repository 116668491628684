import { Box, Divider, makeStyles } from "@material-ui/core";
import { TIMEZONE, TIMEZONE_LABEL } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import { darkgray, GreyDark } from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allMobileUsersComposedSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allWebUsersComposedSelector } from "containers/users/redux/selectors/webUsersSelector";
import useTranslations from "hooks/useTranslations";
import ICustomer from "model/entities/Customer";

import { BlueChalk } from "../../../../assets/colors";

const useStyles = makeStyles({
  container: {
    borderRadius: "1px",
    minHeight: "16px",
  },
  wrapper: {
    display: "grid",
    gridRowGap: "4px",
  },
  divider: {
    background: BlueChalk,
    height: "2px",
  },
});
interface ICustomerMetaData {
  customer: ICustomer;
}
function CustomerMetaData({ customer }: ICustomerMetaData) {
  const lang = useTranslations();
  const classes = useStyles();
  const client = useSelector(getSelectedClient);
  const timeZone = client.timezone ?? TIMEZONE.GMT;
  const timeZoneOffset = TIMEZONE_LABEL[timeZone].split("\t")[0];
  const mobileUsers = useSelector(allMobileUsersComposedSelector);
  const webUsers = useSelector(allWebUsersComposedSelector);

  const columns =
    lang.containers.lists.subCategories.lists.createEditModal.customersItem
      .options;

  function getUserName(id: string | undefined) {
    const mobileUser = _.find(mobileUsers, { id });
    const webUser = _.find(
      webUsers,
      (user) => user?.id === id || user?.email === id
    );
    if (mobileUser) return mobileUser?.first_name + " " + mobileUser?.last_name;
    if (webUser) return webUser?.first_name + " " + webUser?.last_name;
    return id ?? <NotFound />;
  }

  function formatDate(date: string | undefined) {
    if (!date) return <NotFound />;
    return moment(date).utcOffset(timeZoneOffset).format("D MMMM YYYY - HH:mm");
  }

  return (
    <Box paddingBottom={"50px"}>
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr auto 1fr"}
        gridColumnGap={"32px"}
        paddingBottom={"24px"}
        alignItems={"center"}
      >
        <Divider className={classes.divider} />
        <Box fontWeight={400} fontSize={"16px"} color={GreyDark}>
          {lang.genericTerms.metadata.toUpperCase()}
        </Box>
        <Divider className={classes.divider} />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        fontWeight={"500"}
        fontSize={12}
        color={darkgray}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.container}>
            {columns.lastvisitdate} : {formatDate(customer?._last_visit_date)}
          </Box>
          <Box className={classes.container}>
            {columns.lastvisitedby} :{" "}
            {customer?._last_visit_user_name ?? <NotFound />}
          </Box>
          <Box className={classes.container}>
            {columns.lasteditdate} : {formatDate(customer?._updated_at)}
          </Box>
          <Box className={classes.container}>
            {columns.lasteditedby} : {getUserName(customer?._updated_by)}
          </Box>
          <Box className={classes.container}>
            {columns.lasteditedfrom} : {getUserName(customer?._updated_source)}
          </Box>
          <Box className={classes.container}>
            {columns.createdby} : {getUserName(customer?._created_by)}
          </Box>
          <Box className={classes.container}>
            {columns.createdfrom} : {customer?._created_source ?? <NotFound />}
          </Box>
          <Box className={classes.container}>
            {columns.creationdate} : {formatDate(customer?._created_at)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomerMetaData;

export function NotFound() {
  return (
    <span
      style={{
        fontWeight: "lighter",
      }}
      data-testid="not-found"
    >
      N/A
    </span>
  );
}
