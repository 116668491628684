import React, { useContext } from "react";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { TLogger } from "log/FieldProLogger";

import CustomButton, {
  BUTTON_TYPES_OUTSIDE_TABLE,
} from "components/Buttons/CustomButton";
import { IPdfClient } from "components/Dashboard/Pdf/PdfDashboard";
import { LoggerContext } from "containers/app/AppProviders";
import { useTranslations } from "hooks";
import { IOption } from "model/application/components";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { IClient } from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";

import { IFilter } from "../../model/application/Filter";
import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "../../model/entities/HierarchyDependency";
import { ITeamSelector } from "../../model/entities/Team";
import { getQuery } from "./Filter.utils";
import FilterDropdownManager from "./FilterDropdownManager";
import PrimaryfiltersDropdownManager from "./PrimaryFiltersDropdownManager";
import styles from "./styles";
import { FILTER_TAG } from "./TypeFilter";

const useStyles = makeStyles(styles as any);

interface IFilterContainerProps {
  filters: IFilter[];
  allTeamsObject?: ITeamSelector[];
  metaHierarchy?: IMetaHierarchyDependency;
  hierarchy?: IHierarchyDependency[];
  onChangeFilters?: (filters: any) => void;
  title?: string;
  client?: IClient;
  getPdfData?: (
    dashboardTitle: string,
    clientInfos: IPdfClient | undefined,
    startDate: number | Date,
    endDate: number | Date,
    teamFilter: IOption[] | undefined,
    userFilter: IOption[] | undefined,
    logger: TLogger
  ) => Promise<void>;
  customActionComponent?: React.ReactElement;
  onClickBackButton?: () => void;
  dashboard?: IDashboard;
  openOnMount?: boolean;
  fixedPrimaryFilters?: boolean;
}

/**
 * Needs refactor: filters have been moved inside tables,
 * => we should split the title and PDF buttons into 2 separate components
 */
export const FilterContainer: React.FunctionComponent<IFilterContainerProps> = (
  props: IFilterContainerProps
) => {
  const lang = useTranslations();
  const classes = useStyles();
  const logger = useContext(LoggerContext);

  const {
    metaHierarchy,
    hierarchy,
    allTeamsObject,
    title,
    client,
    filters,
    onChangeFilters,
    onClickBackButton,
    getPdfData,
    customActionComponent,
    dashboard,
    openOnMount,
    fixedPrimaryFilters,
  } = props;

  /**
   * This function will format the filters into 2 different way:
   * - the actual query, a map object with {[filterTag]:value}
   * - the raw query object, a map object with {[filterTag]:value}, including meta informations such as hierarchy or more filter
   * @param filters Filters to pass to the container (either for a backend call or for a frontend filter / download)
   */
  const handleChangeFilters = (filters: IFilter[]) => {
    const query = getQuery(filters, dashboard);

    if (onChangeFilters) {
      onChangeFilters(query);
    }
  };

  const onClickDownloadPdf = () => {
    if (!getPdfData) {
      return;
    }

    const teamFilterOptions = _.map(teamFilter?.value, (e) =>
      _.find(teamFilter?.options, { key: e })
    );

    const userFilterOptions = _.map(userFilter?.value, (e) =>
      _.find(userFilter?.options, { key: e })
    );

    return getPdfData(
      title ?? "",
      clientPdfInfos,
      dateFilter?.value?.startDate,
      dateFilter?.value?.endDate,
      _.compact(teamFilterOptions),
      _.compact(userFilterOptions),
      logger
    );
  };

  const pdfButton = getPdfData ? (
    <CustomButton
      onClick={onClickDownloadPdf}
      aria-label={"PDF"}
      text={lang.actions.downloadPDF}
      aria-hidden="true"
      type={ACTION_TYPE.DOWNLOAD}
    />
  ) : null;

  const buildBackButton = (onClickBackButton: () => void, classes: any) => {
    return (
      <CustomButton
        type={BUTTON_TYPES_OUTSIDE_TABLE.BACK}
        onClick={onClickBackButton}
        buttonContainer={classes.BackButtonContainer}
      />
    );
  };

  const dateFilter = filters.find((f) => f.tag === FILTER_TAG.DATE);
  const teamFilter = filters.find((f) => f.tag === FILTER_TAG.TEAMS);
  const userFilter = filters.find((f) => f.tag === "users");
  const clientPdfInfos = client
    ? { name: client.name, logo: client.logo }
    : undefined;
  const buttons = [pdfButton, customActionComponent].filter(Boolean);

  try {
    return (
      <Grid container wrap="wrap" style={{ margin: "10px 0" }}>
        <Grid item xs={12} sm={getPdfData || customActionComponent ? 9 : 12}>
          {onClickBackButton
            ? buildBackButton(onClickBackButton, classes)
            : null}

          {title && (
            <Typography variant="h6" className={classes.Title}>
              {title}
            </Typography>
          )}

          {!_.isEmpty(filters) &&
            (fixedPrimaryFilters ? (
              <PrimaryfiltersDropdownManager
                filters={filters}
                allTeamsObject={allTeamsObject}
                hierarchy={hierarchy}
                metaHierarchy={metaHierarchy}
                onChangeFilters={handleChangeFilters}
                openOnMount={openOnMount}
              />
            ) : (
              <FilterDropdownManager
                filters={filters}
                allTeamsObject={allTeamsObject}
                hierarchy={hierarchy}
                metaHierarchy={metaHierarchy}
                onChangeFilters={handleChangeFilters}
                openOnMount={openOnMount}
              />
            ))}
        </Grid>

        {pdfButton || customActionComponent ? (
          <Grid item xs={12} sm={3}>
            <div
              id="CustomActionButtonId"
              className={classes.CustomActionButton}
              style={{ display: buttons.length > 1 ? "flex" : "block" }}
            >
              {pdfButton} {customActionComponent}
            </div>
          </Grid>
        ) : null}
      </Grid>
    );
  } catch (e) {
    return <div>{(e as any).message}</div>;
  }
};

export default FilterContainer;
