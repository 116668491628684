import { WorkflowsState } from "./reducer";

const initialState: WorkflowsState = {
  isAttaching: false,
  isChangingTag: false,
  isFetchingAll: false,
  isDeleting: false,
  isCreating: false,
  isUpdating: false,
  isArchiving: false,
  isRestoring: false,
  isDeletingWorkflowReport: false,
  isFetchingActivities: false,
  isDeletingActivity: false,
  isCreatingActivity: false,
  isUpdatingActivity: false,
  isCreatingActivityReport: false,
  isDeletingActivityReport: false,
  isUpdatingActivityReport: false,
  isCreatingWorkflowReport: false,
  isUpdatingWorkflowReport: false,
  isDownloadingReportRawData: false,
  isFetchingAugmentedActivityReports: false,
  isUploadingFile: false,
  allActivities: [],
  allWorkflows: [],
  allActivityReports: [],
  allAugmentedActivityReports: [],
  totalAugmentedReportCount: 0,
  activityReportSorting: { orderBy: "completed_at", order: "desc" },
  byId: {},
  allIds: [],
  errors: [],
  lastUpdated: new Date(),
  isFetchingWorkflowReports: false,
  isFetchingJobLogs: false,
  allWorkflowReports: [],
  stepsOverview: [],
  totalReportCount: 0,
};

export default initialState;
