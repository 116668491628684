import _ from "lodash";

import {
  getMatrixFieldsSchema,
  getTagKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { CUSTOM_FIELD_TYPE, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IQuestion } from "model/entities/Workflow";

interface IConvertItem {
  schemas: IListSchema[];
  item: IListItem;
}

const convertItem = ({ schemas, item }: IConvertItem) => {
  const convertedItem: IListItem = { ...item };

  _.each(schemas, (schema: IListSchema) => {
    convertedItem[schema.column_tag] = convertItemBySchema({
      customField: schema,
      value: item[schema.column_tag],
    });
  });

  return convertedItem;
};

interface IConvertCustomItem {
  customField: IListSchema;
  value: any;
}

const convertItemBySchema = ({ customField, value }: IConvertCustomItem) => {
  let convertedAnswer = null;

  switch (customField.type) {
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
      convertedAnswer = value
        ? convertMatrixValue({
            value,
            customField,
            subElementsCallback: convertItemBySchema,
          })
        : undefined;
      break;

    default:
      convertedAnswer = value;
      break;
  }

  return convertedAnswer;
};

interface IConvertMatrixValue {
  customField: IListSchema | IQuestion;
  value: any;
  subElementsCallback?: ({
    customField,
    value,
  }: {
    customField: any;
    value: any;
  }) => any;
  simpleMatrixFormat?: boolean;
}

export const convertMatrixValue = ({
  customField,
  value,
  subElementsCallback,
  simpleMatrixFormat,
}: IConvertMatrixValue) => {
  if (simpleMatrixFormat && value?.rows) {
    return value?.rows.reduce((acc: any, item: any) => {
      const { _item_id, ...rest } = item;
      acc[_item_id] = rest;
      return acc;
    }, {} as Record<string, any>);
  }

  const subCustomFields = getMatrixFieldsSchema(customField);
  const tagKey = getTagKey(
    subCustomFields.length > 0 ? subCustomFields[0] : customField
  );
  const matrix_questions_tags = _.map(subCustomFields, (m) => m[tagKey]);

  if (!value?.rows) {
    return {
      headers: [],
    };
  }

  const headers = _.map(value?.rows, (row: any) => {
    return {
      tag: row._item_id,
      typed_answers: _.filter(Object.keys(row), (key) =>
        _.includes(matrix_questions_tags, key)
      ).map((tag: string) => {
        const q = _.find(subCustomFields, (tp) => tp[tagKey] === tag);
        if (!subElementsCallback) return undefined;
        return {
          key: tag,
          value: subElementsCallback({
            customField: q as IListSchema | IQuestion,
            value: row[q?.[tagKey]],
          }),
        };
      }),
    };
  });

  return { headers };
};

export default convertItem;
