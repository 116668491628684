import { convertMatrixValue } from "containers/lists/subcategories/items/utils/convertItem";
import { IListSchema } from "model/entities/List";
import { CUSTOM_FIELD_TYPE, IQuestion } from "model/entities/Workflow";

interface IPrepareCustomFieldDataForBackend {
  question?: IListSchema | IQuestion;
  answer: any;
  simpleMatrixFormat?: boolean;
  skipMatrix?: boolean;
}

const prepareCustomFieldDataForBackend = ({
  question,
  answer,
  simpleMatrixFormat,
  skipMatrix,
}: IPrepareCustomFieldDataForBackend) => {
  // use null to decide whether or not to perform update at the end
  // (an undefined answer will be written in the report, but null will be skipped)
  let convertedAnswer = null;

  switch (question?.type) {
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST: {
      // expected input format: {key: "A", label: "a"}
      convertedAnswer = answer?.key ?? answer;
      break;
    }
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      // expected input format: [{key: "A", label: "a"}, ... ]
      convertedAnswer = Array.isArray(answer)
        ? answer.map((k: any) => k.key ?? k)
        : answer;
      break;
    }
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST: {
      if (skipMatrix) return answer;
      convertedAnswer = convertMatrixValue({
        customField: question,
        value: answer,
        subElementsCallback: ({ customField, value }) =>
          prepareCustomFieldDataForBackend({
            answer: value,
            question: customField,
            simpleMatrixFormat,
          }),
        simpleMatrixFormat,
      });
      break;
    }
    default: {
      convertedAnswer = answer;
      break;
    }
  }
  return convertedAnswer;
};

export default prepareCustomFieldDataForBackend;
