import { Box, makeStyles, Tooltip } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import _ from "lodash";

import MenuButton from "components/Buttons/MenuButton";
import { useTranslations } from "hooks";

import CalendarDateRange from "./components/Calendar/CalendarDateRange";
import { styles } from "./styles";
import { getAddVisitToolTip } from "./utils/getAddVisitToolTip";
const useStyles = makeStyles(styles as any);
interface ICalendarContainerHeaderProps {
  isManager?: boolean;
  currentFieldUser?: any;
  onChangeDateRange: (dateRange: any) => void;
  onClickAddSingleVisit: () => void;
  onClickAddMultipleVisits: () => void;
}
export function CalendarContainerHeader({
  currentFieldUser,
  isManager,
  onChangeDateRange,
  onClickAddMultipleVisits,
  onClickAddSingleVisit,
}: ICalendarContainerHeaderProps) {
  const classes = useStyles();
  const lang = useTranslations();
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;

  const menuButtonOptions = [
    {
      label: inputVisitLang.customMessage.singleVisits,
      onClick: onClickAddSingleVisit,
      icon: <Add className={classes.icon} />,
    },
    {
      label: inputVisitLang.customMessage.multipleVisits,
      onClick: onClickAddMultipleVisits,
      icon: <AddToPhotosIcon className={classes.icon} />,
    },
  ];

  return (
    <Box className={classes.header}>
      <Box className={classes.title}>
        {inputVisitLang.customMessage.schedule}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gridColumnGap={"16px"}
        justifyContent={"end"}
      >
        <CalendarDateRange onDateChange={onChangeDateRange} />
        <Tooltip
          title={getAddVisitToolTip({
            isManager: Boolean(isManager),
            isUserSelected: !_.isUndefined(currentFieldUser),
            lang,
          })}
        >
          <span style={{ flexShrink: 0 }}>
            <MenuButton
              color="secondary"
              label={inputVisitLang.title}
              disabled={_.isUndefined(currentFieldUser)}
              options={menuButtonOptions}
            >
              {inputVisitLang.title}
            </MenuButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default CalendarContainerHeader;
