import { getOverlappingEvent, IVisitEvent } from "fieldpro-tools";
import _ from "lodash";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import { isValidDate } from "utils/dateUtils";

import { IAddVisitDialogProps } from "../components/AddVisitDialog";
import { TNewEventPayload } from "../redux/types";
import { eventIsWithinAllowedHours } from "./eventIsWithinAllowedHours";

export const getErrorMessagesForVisitDialog: TValidateFunction<
  TNewEventPayload,
  Pick<IAddVisitDialogProps, "events"> & {
    minEventStartHour: number;
    maxEventStartHour: number;
  }
> = ({
  attributes,
  lang,
  additionnalProps: { events, minEventStartHour, maxEventStartHour },
}) => {
  const langKey = lang.containers.calendar.subCategories.calendar;
  const inputVisitLang = langKey.createEditModal.inputVisit;
  const errors = {};
  const requiredKeys: Array<keyof IVisitEvent> = [
    "start_time",
    "end_time",
    "customer_id",
    "assigned_to",
  ];

  requiredKeys.forEach((key) => {
    if (isEmpty(attributes[key])) {
      errors[key] = lang.components.inputErrors.empty;
    }
  });

  const { start_time, end_time } = attributes;
  if (
    start_time &&
    end_time &&
    getOverlappingEvent(
      events.filter((e) => e.id !== attributes.id),
      attributes
    )
  ) {
    errors["select_time"] = inputVisitLang.customErrorMessages.usedSlot;
  }

  if (start_time && end_time) {
    if (
      !eventIsWithinAllowedHours({
        event: attributes,
        minEventHour: minEventStartHour,
        maxEventHour: maxEventStartHour,
      })
    ) {
      errors["select_time"] = inputVisitLang.customErrorMessages.outsideHours;
    }
  }

  return errors;
};

function isEmpty(value: any) {
  if (value instanceof Date) {
    return !isValidDate(value);
  }

  return _.isUndefined(value) || _.isEmpty(value) || _.isNull(value);
}
