import { Component } from "react";

import { Box } from "@material-ui/core";
import { ACTION_CODE, getActionCodesForCategory } from "fieldpro-tools";
import _ from "lodash";

import { Black } from "assets/colors";
import { getErrorFromAttribute } from "components/Input/InputMultipleCreate/InputMultipleCreate";
import ToggleSwitch from "components/Switch/ToggleSwitch";
import {
  convertAccessRightProfileArrayToBoolean,
  convertAccessRightProfilesToOption,
} from "containers/clients/components/ClientForm/Tabs/UsersTab/utils/convertAccessRightProfilToOption";
import { ACTION_TYPE, OBJECT_CATEGORY } from "model/application/ActionCode";
import TLang from "model/application/Lang";
import { isOptimetriksAdminRole } from "model/constants/profiles";
import { IAccessRightProfile } from "model/entities/Client";

import InputText from "../../../../../../components/Input/InputText/InputText";
import DisplayProfileActions from "../../../modals/DisplayProfileActions";

export interface IUserProfileFormModalProps {
  onChange: (e: IAccessRightProfile) => void;
  element: IAccessRightProfile;
  lang: TLang;
  role: string;
}

class UserProfileFormModal extends Component<
  IUserProfileFormModalProps,
  IAccessRightProfile
> {
  constructor(props: IUserProfileFormModalProps) {
    super(props);
    const { element } = props;
    this.state = {
      ...element,
      actions: convertAccessRightProfileArrayToBoolean(element.actions), // Make sure that all actions are boolean
    };
  }

  /**
   * Handles input changes
   * @param {Object} e Event Object
   */
  handleInputChange = (value: any, name: string) => {
    const { onChange } = this.props;
    this.setState((oldState) => {
      let newState = oldState;
      if (name === "name") {
        newState = { ...oldState, name: value as string };
      }
      if (name === "access_all_resources") {
        newState = { ...oldState, access_all_resources: value as boolean };
      }
      if (name === "FETCH_CLIENT_SETTINGS") {
        const oldActions = oldState.actions;
        const newActions = {
          ...oldActions,
          [ACTION_CODE.FETCH_CLIENT_SETTINGS]: value as boolean,
        };
        newState = { ...oldState, actions: newActions };
      }
      onChange(newState);
      return newState;
    });
  };

  /**
   * Handles the switch control component
   * @param {Boolean} isOn Boolean value indicating whether the switch is on or off
   */
  handleSwitchChanged = (value: any, name: string) => {
    const { actions } = this.state;
    const actionsClone = { ...actions }; //Clone actions

    if (_.isEmpty(value)) {
      actionsClone[name] = false;
      actionsClone[`${name}S`] = false;
    } else {
      actionsClone[name] = true;
      actionsClone[`${name}S`] = true;
    }

    //Unchecked all access right profiles actions if the FETCH action is false
    if (_.startsWith(name, ACTION_TYPE.FETCH) && _.isEmpty(value)) {
      // if turn off the fetch, turn off all the actions of this catagory
      const category = _.split(name, `${ACTION_TYPE.FETCH}_`)[1];
      for (const action in actionsClone) {
        if (action.includes(category)) {
          actionsClone[action] = false;
        }
      }
    }

    const newState = { ...this.state, actions: actionsClone };
    this.props.onChange(newState);
    this.setState(newState);
  };

  render() {
    const { name, access_all_resources } = this.state;
    const { lang, role } = this.props;

    const labelToDisplay =
      lang.containers.clients.subCategories.clients.createEditModal
        .inputProfile;

    const clientManagementLang =
      lang.containers.clients.subCategories.clients.createEditModal
        .clientManagement;

    return (
      <Box paddingBottom="24px">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: "100px",
            alignItems: "center",
          }}
        >
          {/* NAME  */}
          <InputText
            error={getErrorFromAttribute(this.state, "name")}
            name="name"
            lang={labelToDisplay.createEditModal.inputName}
            onChange={this.handleInputChange}
            defaultValue={name}
            required={true}
            debounceDuration={0}
          />

          {/* ACCES */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "7px",
            }}
          >
            <CustomToggleSwithForProfil
              name="access_all_resources"
              title={
                labelToDisplay.createEditModal.inputAccessAllResources.title
              }
              onChange={(e: any) =>
                this.handleInputChange(e.target.checked, "access_all_resources")
              }
              defaultChecked={access_all_resources}
            />
          </div>
        </div>
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.MOB_USER,
          clientManagementLang.options.mobileUserManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.WEB_USER,
          clientManagementLang.options.webUserManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.TEAM,
          clientManagementLang.options.teamManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.WORKFLOW,
          clientManagementLang.options.workflowManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.ACTIVITY_REPORT, // NOTE: ACTIVITY_REPORT is also used for other kinds of reports (Workflow reports, ...)
          clientManagementLang.options.reportsManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.LIST,
          clientManagementLang.options.listManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.ITEM,
          clientManagementLang.options.itemManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.DASHBOARD,
          clientManagementLang.options.dashboardManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.DOCUMENT,
          clientManagementLang.options.documentManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.TERRITORY,
          clientManagementLang.options.territoryManagement,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.CLIENT_SETTINGS,
          clientManagementLang.options.clientSettings,
          role
        )}
        {this.buildAccessRightsForCategory(
          OBJECT_CATEGORY.CALENDAR_EVENT,
          clientManagementLang.options.eventsManagement,
          role
        )}
      </Box>
    );
  }

  buildAccessRightsForCategory = (
    category: OBJECT_CATEGORY,
    categoryInDisplay: string,
    role: string
  ) => {
    const { actions } = this.state;
    let actionsCode = getActionCodesForCategory(category);

    if (!isOptimetriksAdminRole(role)) {
      actionsCode = _.without(
        actionsCode,
        ACTION_CODE.CREATE_DASHBOARD,
        ACTION_CODE.EDIT_DASHBOARD
      );
    }

    const actionsFormatted = {
      ...this.state.actions,
      ...convertAccessRightProfilesToOption(actions),
    };

    const getActionCodeRight = (actionCode: string) => {
      if (!_.startsWith(actionCode, ACTION_TYPE.FETCH)) {
        return !!actionsFormatted[actionCode];
      }

      const valueWithoutS = _.isArray(actionsFormatted[actionCode])
        ? actionsFormatted[actionCode]
        : undefined;
      const valueWithS = _.isArray(actionsFormatted[actionCode + "S"])
        ? actionsFormatted[actionCode + "S"]
        : undefined;

      let valueBoolean = false;

      if (_.isArray(valueWithoutS) && _.size(valueWithoutS)) {
        valueBoolean = true;
      } else {
        if (_.isArray(valueWithS) && _.size(valueWithS)) {
          valueBoolean = true;
        }
      }
      return valueBoolean;
    };
    const actionsCodeStatus = actionsFormatted as any;

    const categoryCode = `${ACTION_TYPE.FETCH}_${category}`;
    const fetchCategoryStatus = getActionCodeRight(categoryCode);
    const allActionsDifferentsToFetch = _.filter(
      actionsCode,
      (act) => !_.startsWith(act, ACTION_TYPE.FETCH)
    );
    const actionOnFetch = _.find(actionsCode, (act) =>
      _.startsWith(act, ACTION_TYPE.FETCH)
    );

    return (
      <DisplayProfileActions
        fatherOptionIsChecked={fetchCategoryStatus}
        childOptions={allActionsDifferentsToFetch}
        fatherOption={actionOnFetch ?? ""}
        defaultStatus={actionsCodeStatus}
        sectionTitle={categoryInDisplay}
        onChange={this.handleSwitchChanged}
      />
    );
  };
}

interface ICustomToggleSwithForProfil {
  title: string;
  name: string;
  onChange: (value: any, name: string) => void;
  defaultChecked?: boolean;
}
const CustomToggleSwithForProfil = (props: ICustomToggleSwithForProfil) => {
  const { defaultChecked, onChange, name, title } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "10px",
      }}
    >
      <span style={{ fontSize: "16px", color: Black, fontWeight: 500 }}>
        {title}
      </span>
      <ToggleSwitch
        name={name}
        onChange={(e) => onChange(e, name)}
        defaultChecked={defaultChecked ?? false}
        size="small"
      />
    </div>
  );
};

export default UserProfileFormModal;
