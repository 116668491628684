import moment from "moment";

interface IGetBoardSlotDateTimeString {
  day: string;
  time: string;
}
export function getBoardSlotDateTimeString({
  day,
  time,
}: IGetBoardSlotDateTimeString) {
  return moment
    .utc(`${day}T${moment.utc(time, "hA").format("HH:mm")}`)
    .format("YYYY-MM-DDTHH:mm:ss");
}
