import { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import { ResponsiveLine } from "@nivo/line";
import { object, string } from "prop-types";

import { lightgray } from "assets/colors";
import { KPI_TYPE } from "model/entities/Dashboard";

import Legend from "../Legend/Legend";
import Axes from "./Axes";
import Chart from "./Chart";
import { ChartDataUtils, NUMBER_SEPARATOR } from "./ChartDataUtils";
import { SliceTooltip } from "./SliceTooltip";
import styles from "./styles";
import { processOptimetriksLineChartData } from "./utils/processOptimetriksLineChartData";
/* istanbul ignore next */
const CustomSymbol = ({ size, borderColor }) => (
  <g>
    <circle
      style={{ boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.8)" }}
      fill="#fff"
      r={size}
      strokeWidth={2}
      strokeOpacity="0.3"
      stroke={lightgray}
    />
    <circle r={size / 2} strokeWidth={0} stroke={0} fill={borderColor} />
  </g>
);

export class OptimetriksLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chart: {},
      nivoConfiguration: {},
      legends: [],
    };
  }

  /* istanbul ignore next */
  componentDidMount() {
    setTimeout(() => {
      const lineChartUid = `lineChartContainer${this.props.uid}`;
      const result = this.updateLabels(lineChartUid);
      this.setState({
        legends: result,
      });
    }, 20);
  }

  /* istanbul ignore next */
  updateLabels(uid) {
    return ChartDataUtils.updateLabels(uid);
  }

  render() {
    const { uid, chart } = this.props;
    const nivoConfiguration = this.props.nivoConfiguration;
    const legends = this.state ? this.state.legends : [];
    const maxValue =
      chart.data.reduce((acc, curr) => {
        const tempMax = curr.data.reduce(
          (ac, cur) => (cur.y > ac ? cur.y : ac),
          -Infinity
        );
        return tempMax > acc ? tempMax : acc;
      }, -Infinity) * 1.2;
    const yScale = Axes.getScale(
      nivoConfiguration.axeYType,
      nivoConfiguration.minValue,
      maxValue
    );
    let axisBottom = Chart.defaultAxisBottom(nivoConfiguration.axeXType);
    axisBottom = nivoConfiguration.tickValues
      ? Object.assign(axisBottom, {
          tickValues: nivoConfiguration.tickValues,
        })
      : axisBottom;
    const enableYAxis = nivoConfiguration.enableYAxis;
    return (
      <>
        {legends && chart.type === KPI_TYPE.MULTIPLE_LINE_CHART && (
          <div id={`legendContainer${uid}`}>
            <Legend legends={legends}></Legend>
          </div>
        )}
        <span id={`lineChartContainer${uid}`}>
          <ResponsiveLine
            curve={nivoConfiguration.curve ? nivoConfiguration.curve : "linear"}
            colors={nivoConfiguration.colors}
            data={processOptimetriksLineChartData(chart.data)}
            margin={
              chart.type === "LINE_CHART"
                ? Chart.settings["LINE_CHART"].margin
                : Chart.settings["MULTIPLE_LINE_CHART"].margin
            }
            axisTop={null}
            axisRight={null}
            yScale={yScale}
            axisBottom={Object.assign(axisBottom, {
              format: (value) => {
                /* istanbul ignore next */
                return Axes.formatAxisValue(value, nivoConfiguration.axeXType);
              },
            })}
            axisLeft={enableYAxis ? Chart.defaultAxisLeft : null}
            pointLabel={function (e) {
              /* istanbul ignore next */
              const yFormatted = ChartDataUtils.formatValue(
                e.y,
                NUMBER_SEPARATOR
              );
              /* istanbul ignore next */
              return yFormatted;
            }}
            enablePointLabel={!enableYAxis}
            enablePoints={true}
            enableCrosshair={false}
            enableGridX={false}
            enableGridY={false}
            /* istanbul ignore next */
            pointSymbol={CustomSymbol}
            pointSize={10}
            lineWidth={4}
            legends={
              chart.type === "LINE_CHART" ? [] : Chart.multiLegendSettings
            }
            pointColor={{ from: "serieColor", modifiers: [] }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor", modifiers: [] }}
            enableArea={nivoConfiguration.enableArea ? true : false}
            areaOpacity={nivoConfiguration.enableArea ? 1 : 0}
            debugSlices={false}
            enableSlices="x"
            useMesh={true}
            sliceTooltip={({ slice }) => {
              /* istanbul ignore next */
              const points = slice.points.map((point) => {
                /* istanbul ignore next */
                return {
                  id: point.serieId,
                  indexValue: point.data.xFormatted,
                  value: point.data.yFormatted,
                  color: point.serieColor,
                };
              });
              /* istanbul ignore next */
              return <SliceTooltip slice={points}></SliceTooltip>;
            }}
          />
        </span>
      </>
    );
  }
}
OptimetriksLineChart.propTypes = {
  classes: object,
  chart: object,
  nivoConfiguration: object,
  uid: string,
};

export default withStyles(styles)(OptimetriksLineChart);
